
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import CanvaEmbed from "../../../components/CanvaEmbed";
import AlphabetCards from "../../../components/AlphabetCards";
import GAN_QUIZ from "!babel-loader!@mdx-js/loader!.//GAN_QUIZ.mdx";
import SVGArrow from  "../../../components/SVGArrow.js";
import MyCard from  "../../../components/MyCard.js";
import TimeEstimate from  "../../../components/TimeEstimate.js";
import GANComponentBeginner from  "../../../blocks/GANComponentBeginner.js";
import GAN_TimeLine from "!babel-loader!@mdx-js/loader!./GAN_TimeLine.mdx";
export const alphabetWords_orig = [{
  letter: "A",
  words: ["ant", "apple"],
  quiz: [{
    question: "Which is a fruit?",
    options: ["ant", "apple"],
    answer: "apple"
  }, {
    question: "Which is an insect?",
    options: ["ant", "apple"],
    answer: "ant"
  }]
}, {
  letter: "B",
  words: ["bat", "beaver", "bison", "boar"]
}, {
  letter: "C",
  words: ["camel", "cheetah", "chipmunk", "cougar"]
}, {
  letter: "D",
  words: ["deer", "dolphin", "duck", "dove"]
}, {
  letter: "E",
  words: ["eagle", "elephant", "elk", "emu"]
}, {
  letter: "F",
  words: ["falcon", "ferret", "flamingo", "fox"]
}, {
  letter: "G",
  words: ["giraffe", "goat", "goose", "gorilla"]
}, {
  letter: "H",
  words: ["hawk", "hedgehog", "hippo", "horse"]
}, {
  letter: "I",
  words: ["iguana", "impala"]
}, {
  letter: "J",
  words: ["jaguar", "jellyfish"]
}, {
  letter: "K",
  words: ["kangaroo", "koala"]
}, {
  letter: "L",
  words: ["leopard", "lion", "llama", "lobster"]
}, {
  letter: "M",
  words: ["monkey", "moose", "mouse", "mule"]
}, {
  letter: "N",
  words: ["newt", "nightingale"]
}, {
  letter: "O",
  words: ["octopus", "opossum", "otter", "owl"]
}, {
  letter: "P",
  words: ["panther", "parrot", "peacock", "penguin"]
}, {
  letter: "Q",
  words: ["quail"]
}, {
  letter: "R",
  words: ["rabbit", "raccoon", "rhino"]
}, {
  letter: "S",
  words: ["seal", "shark", "sheep", "snail"]
}, {
  letter: "T",
  words: ["tiger", "turtle", "toucan"]
}, {
  letter: "U",
  words: ["urchin"]
}, {
  letter: "V",
  words: ["vulture"]
}, {
  letter: "W",
  words: ["walrus", "weasel", "whale", "wolf"]
}, {
  letter: "X",
  words: ["x-ray fish"]
}, {
  letter: "Y",
  words: ["yak"]
}, {
  letter: "Z",
  words: ["zebra"]
}];
export const alphabetWords = [{
  letter: 'A',
  words: ['Applications of GANs'],
  quiz: [{
    question: 'What are some potential applications of GANs?',
    options: ['Generating realistic data', 'Solving complex mathematical problems', 'Internet browsing', 'Data storage'],
    answer: 'Generating realistic data'
  }]
}, {
  letter: 'C',
  words: ['Components of GANs'],
  quiz: [{
    question: 'What are the two main components of GANs?',
    options: ['Generator and Discriminator', 'Transformer and Predictor', 'Classifier and Regressor', 'Encoder and Decoder'],
    answer: 'Generator and Discriminator'
  }]
}, {
  letter: 'G',
  words: ['Generative Models'],
  quiz: [{
    question: 'What is the main goal of generative models?',
    options: ['To classify data', 'To generate new data similar to the input data', 'To perform regression analysis', 'To extract features from the data'],
    answer: 'To generate new data similar to the input data'
  }, {
    question: 'In the context of GANs, what does the generator network do?',
    options: ['Classifies whether an image is real or fake', 'Generates new data', 'Filters the input data', 'Compresses the input data'],
    answer: 'Generates new data'
  }, {
    question: 'What type of machine learning are generative models typically associated with?',
    options: ['Supervised learning', 'Reinforcement learning', 'Unsupervised learning', 'Semi-supervised learning'],
    answer: 'Unsupervised learning'
  }]
}, {
  letter: 'H',
  words: ['Hyperparameters in GANs'],
  quiz: [{
    question: 'What is a hyperparameter in the context of GANs?',
    options: ['A parameter that the model learns during training', 'A parameter that is set before training', 'A type of loss function', 'A type of optimization algorithm'],
    answer: 'A parameter that is set before training'
  }, {
    question: 'How can the learning rate be described?',
    options: ['A hyperparameter that controls how much to change the model in response to the estimated error each time the model weights are updated', 'A parameter that is learned during training', 'A type of loss function', 'A type of optimization algorithm'],
    answer: 'A hyperparameter that controls how much to change the model in response to the estimated error each time the model weights are updated'
  }]
}, {
  letter: 'L',
  words: ['Loss Function', 'Latent Space in GANs'],
  quiz: [{
    question: 'What does the loss function do in GANs?',
    options: ['Evaluates and guides improvements', 'Predicts the future', 'Decides the winner', 'None of the above'],
    answer: 'Evaluates and guides improvements'
  }, {
    question: 'What is the latent space in the context of GANs?',
    options: ['The space where the real data exists', 'The space where the fake data exists', 'The space where the generator creates new data', 'None of the above'],
    answer: 'The space where the generator creates new data'
  }, {
    question: 'What happens when we sample points from the latent space?',
    options: ['We can generate new data', 'We can train the discriminator', 'We can calculate the loss function', 'We can update the model weights'],
    answer: 'We can generate new data'
  }]
}, {
  letter: 'N',
  words: ['Noise Vector in GANs'],
  quiz: [{
    question: 'What does a noise vector do in GANs?',
    options: ['It is used as input to the generator to create new data', 'It is used to train the discriminator', 'It is used to calculate the loss function', 'It is used to update the model weights'],
    answer: 'It is used as input to the generator to create new data'
  }, {
    question: 'Why is randomness introduced in the generator through a noise vector?',
    options: ['To ensure diversity in the output', 'To speed up the training process', 'To reduce the complexity of the model', 'None of the above'],
    answer: 'To ensure diversity in the output'
  }]
}, {
  letter: 'O',
  words: ['Overfitting in GANs'],
  quiz: [{
    question: 'What is overfitting in the context of GANs?',
    options: ['When the GAN generalizes well to unseen data', 'When the GAN starts to memorize the training data instead of learning to generalize from it', 'When the GAN fails to learn from the training data', 'None of the above'],
    answer: 'When the GAN starts to memorize the training data instead of learning to generalize from it'
  }, {
    question: 'How can overfitting be prevented in GANs?',
    options: ['By increasing the complexity of the model', 'By decreasing the size of the dataset', 'By using techniques like dropout or regularization', 'None of the above'],
    answer: 'By using techniques like dropout or regularization'
  }]
}, {
  letter: 'P',
  words: ['Progressive Growing of GANs'],
  quiz: [{
    question: 'What is the advantage of using progressive growing in GANs?',
    options: ['It helps to speed up the training process', 'It helps to generate high-resolution images', 'Both A and B', 'Neither A nor B'],
    answer: 'Both A and B'
  }, {
    question: 'In progressive growing of GANs, layers are added:',
    options: ['At the beginning of the training process', 'At the end of the training process', 'Gradually during the training process', 'None of the above'],
    answer: 'Gradually during the training process'
  }]
}, {
  letter: 'R',
  words: ['Real vs Generated Data in GANs'],
  quiz: [{
    question: 'In the context of GANs, the discriminator is responsible for:',
    options: ['Generating new data', 'Differentiating between real and generated data', 'Both A and B', 'Neither A nor B'],
    answer: 'Differentiating between real and generated data'
  }, {
    question: 'The goal of the generator in GANs is to:',
    options: ['Generate data that is as close as possible to the real data', 'Generate data that is different from the real data', 'Both A and B', 'Neither A nor B'],
    answer: 'Generate data that is as close as possible to the real data'
  }]
}, {
  letter: 'T',
  words: ['Training Loop'],
  quiz: [{
    question: 'What happens in the training loop of a GAN?',
    options: ['Generator and Discriminator duel', 'Data is stored for future use', 'Data is processed and cleaned', 'GANs are tested for robustness'],
    answer: 'Generator and Discriminator duel'
  }]
}];
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const topics = [{
  title: 'What are GANs',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
           <br />
        <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide1.m4v" title="Introduction to GANs" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GAN.png" mdxType="VideoMediaCard" />
             <br />
        <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide2.m4v" title="Understanding GAN Models" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GenLoss_2.png" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Timeline',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <GAN_TimeLine mdxType="GAN_TimeLine" />
  </div>
}];
export const topics_bkp = [{
  title: 'What are GANs',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
           <br />
        <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide1.m4v" title="Introduction to GANs" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GAN.png" mdxType="VideoMediaCard" />
             <br />
        <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide2.m4v" title="Understanding GAN Models" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GenLoss_2.png" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Timeline',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <GAN_TimeLine mdxType="GAN_TimeLine" />
  </div>
}, {
  title: "Generator",
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
               <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide3.m4v" title="Generator Block" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_Generator.png" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Discriminator',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
                  <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide4.m4v" title="Discriminator Block" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_Disc.png" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Discriminator Loss ',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
                    <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide14.m4v" title="Discriminator Loss Criteria" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_Disc_Loss.png" mdxType="VideoMediaCard" />
                       <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide5.m4v" title="Binary Cross Entropy" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_0label.png" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Generator Loss ',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
                       <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide6.m4v" title="Measure of deception skillss" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GenLoss_2.png" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Training Loop',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
          <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide7.m4v" title="Discrminator Training" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_train_desc.png" mdxType="VideoMediaCard" />
            <br />
          <Header mdxType="Header">Generator Training </Header>
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide8.m4v" title="Generator Training" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GAN.png" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'PyTorch Code walkthrough',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
             <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide9.m4v" title="Data Loading and preparations" description="" thumbnail="" mdxType="VideoMediaCard" />
              <br />
               <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide10.m4v" title="Generator and Discriminator Pytorch classes" description="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GAN.png" mdxType="VideoMediaCard" />         <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide11.m4v" title="Training initialization" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GAN.png" mdxType="VideoMediaCard" />
               <br />
          <Header mdxType="Header">Discrminator and Generator Training code </Header>
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide12.m4v" title="Discrminator and Generator Training code" description="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GAN.png" mdxType="VideoMediaCard" />
  </div>
}];
export const topics_code = [{
  title: 'PyTorch Code walkthrough',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
             <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide9.m4v" title="Data Loading and preparations" description="" thumbnail="" mdxType="VideoMediaCard" />
              <br />
               <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide10.m4v" title="Generator and Discriminator Pytorch classes" description="" mdxType="VideoMediaCard" />         <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide11.m4v" title="Training initialization" description="" link="" mdxType="VideoMediaCard" />
               <br />
          <Header mdxType="Header">Discrminator and Generator Training code </Header>
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide12.m4v" title="Discrminator and Generator Training code" description="" mdxType="VideoMediaCard" />
  </div>
}];
export const alphabetWords2 = [{
  letter: 'C',
  words: ['Components of GANs'],
  quiz: [{
    question: 'What are the two main components of GANs?',
    options: ['Generator and Discriminator', 'Transformer and Predictor', 'Classifier and Regressor', 'Encoder and Decoder'],
    answer: 'Generator and Discriminator'
  }]
}, {
  letter: 'G',
  words: ['Generative Models'],
  quiz: [{
    question: 'What is the main goal of generative models?',
    options: ['To classify data', 'To generate new data similar to the input data', 'To perform regression analysis', 'To extract features from the data'],
    answer: 'To generate new data similar to the input data'
  }, {
    question: 'In the context of GANs, what does the generator network do?',
    options: ['Classifies whether an image is real or fake', 'Generates new data', 'Filters the input data', 'Compresses the input data'],
    answer: 'Generates new data'
  }, {
    question: 'What type of machine learning are generative models typically associated with?',
    options: ['Supervised learning', 'Reinforcement learning', 'Unsupervised learning', 'Semi-supervised learning'],
    answer: 'Unsupervised learning'
  }]
}, {
  letter: 'H',
  words: ['Hyperparameters in GANs'],
  quiz: [{
    question: 'What is a hyperparameter in the context of GANs?',
    options: ['A parameter that the model learns during training', 'A parameter that is set before training', 'A type of loss function', 'A type of optimization algorithm'],
    answer: 'A parameter that is set before training'
  }, {
    question: 'How can the learning rate be described?',
    options: ['A hyperparameter that controls how much to change the model in response to the estimated error each time the model weights are updated', 'A parameter that is learned during training', 'A type of loss function', 'A type of optimization algorithm'],
    answer: 'A hyperparameter that controls how much to change the model in response to the estimated error each time the model weights are updated'
  }]
}, {
  letter: 'L',
  words: ['Loss Function', 'Latent Space in GANs'],
  quiz: [{
    question: 'What does the loss function do in GANs?',
    options: ['Evaluates and guides improvements', 'Predicts the future', 'Decides the winner', 'None of the above'],
    answer: 'Evaluates and guides improvements'
  }, {
    question: 'What is the latent space in the context of GANs?',
    options: ['The space where the real data exists', 'The space where the fake data exists', 'The space where the generator creates new data', 'None of the above'],
    answer: 'The space where the generator creates new data'
  }, {
    question: 'What happens when we sample points from the latent space?',
    options: ['We can generate new data', 'We can train the discriminator', 'We can calculate the loss function', 'We can update the model weights'],
    answer: 'We can generate new data'
  }]
}, {
  letter: 'O',
  words: ['Overfitting in GANs'],
  quiz: [{
    question: 'What is overfitting in the context of GANs?',
    options: ['When the GAN generalizes well to unseen data', 'When the GAN starts to memorize the training data instead of learning to generalize from it', 'When the GAN fails to learn from the training data', 'None of the above'],
    answer: 'When the GAN starts to memorize the training data instead of learning to generalize from it'
  }, {
    question: 'How can overfitting be prevented in GANs?',
    options: ['By increasing the complexity of the model', 'By decreasing the size of the dataset', 'By using techniques like dropout or regularization', 'None of the above'],
    answer: 'By using techniques like dropout or regularization'
  }]
}, {
  letter: 'R',
  words: ['Real vs Generated Data in GANs'],
  quiz: [{
    question: 'In the context of GANs, the discriminator is responsible for:',
    options: ['Generating new data', 'Differentiating between real and generated data', 'Both A and B', 'Neither A nor B'],
    answer: 'Differentiating between real and generated data'
  }, {
    question: 'The goal of the generator in GANs is to:',
    options: ['Generate data that is as close as possible to the real data', 'Generate data that is different from the real data', 'Both A and B', 'Neither A nor B'],
    answer: 'Generate data that is as close as possible to the real data'
  }]
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImgMediaCard = makeShortcode("ImgMediaCard");
const Header = makeShortcode("Header");
const Marker = makeShortcode("Marker");
const HowlerRead = makeShortcode("HowlerRead");
const VideoMediaCard = makeShortcode("VideoMediaCard");
const SomeComponent = makeShortcode("SomeComponent");
const layoutProps = {
  alphabetWords_orig,
alphabetWords,
ggbParameters2,
opts,
topics,
topics_bkp,
topics_code,
alphabetWords2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Generative Adversarial Networks (Gans)`}</h1>
    <br />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/GNA_APP.png" title="Why should I learn GANs?" description="Generative Adversarial Networks (GANs) are a powerful class of neural networks that are used for generating synthetic data." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <h1>{`Embark on an Interactive Learning Journey`}</h1>
    <br />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
    <h1 style={{
      color: "green"
    }}>1. Exploration Stage </h1>
    <Header mdxType="Header">Play, Build, Learn: Your First GAN Made Easy!</Header>
    <br />
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      // this will give space between your markers
      alignItems: 'center',
      backgroundColor: 'transparent',
      // making the background transparent
      color: 'white',
      // text color
      padding: '20px',
      // inner padding
      overflowY: 'auto',
      // add a scrollbar when content is too tall
      position: 'relative',
      // make the div position relative
      height: '80px',
      // set the height of the div
      width: '500px'
    }}>
      <Marker left="50%" top="20%" mleft="50%" mtop="25%" text="1" title="Task" mdxType="Marker">
  <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
    <HowlerRead soundSrc="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/_GenerativeAdversarial.mp3" mdxType="HowlerRead" />
    <img src="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/GAN_reference.png" alt="Quiz Image" style={{
            maxWidth: '100%',
            height: 'auto',
            marginTop: '20px'
          }} />
  </div>
  </Marker>
    </div>
    <div style={{
      width: '81%',
      height: '100vh',
      backgroundColor: '#424242',
      // dark grey
      color: 'white',
      // text color
      margin: 'auto',
      // center the box
      padding: '20px',
      // inner padding
      overflowY: 'auto',
      // add a scrollbar when content is too tall
      position: 'relative'
    }}>
  <GANComponentBeginner mdxType="GANComponentBeginner" />
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
  <br />
  <br />
  <TimeEstimate time={2} mdxType="TimeEstimate" />
  <MyCard title="2 minute quiz (Optional)" caption="Short, rapid quiz. Test your prior knowledge" mdxType="MyCard">
    <div style={{
          position: 'relative',
          width: '100%'
        }}>
  <AlphabetCards alphabetWords={alphabetWords} mdxType="AlphabetCards" />
  </div>
  </MyCard>
  <br />
  <br />
  <br />
  <br />
  <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
  <br />
  <br />
  <br />
  </div>
   <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide1.m4v" title="Introduction to GANs" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GAN.png" mdxType="VideoMediaCard" />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
 <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide2.m4v" title="Understanding GAN Models" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GenLoss_2.png" mdxType="VideoMediaCard" />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
    <h1>{`2 - Interactive Tour: GAN Architecture`}</h1>
    <br />
    <br />
    <GAN_QUIZ mdxType="GAN_QUIZ" />
    <br />
    <br />
    <br />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
 <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide9.m4v" title="Data Loading and preparations" description="" thumbnail="" mdxType="VideoMediaCard" />
              <br />
              <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
 <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide10.m4v" title="Generator and Discriminator Pytorch classes" description="" thumbnail="" mdxType="VideoMediaCard" />         <br />
      <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide11.m4v" title="Training initialization" description="" link="" thumbnail="" mdxType="VideoMediaCard" />
               <br />
               <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
          <Header mdxType="Header">Discrminator and Generator Training code </Header>
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide12.m4v" title="Discrminator and Generator Training code" description="" thumbnail="" mdxType="VideoMediaCard" />
    <br />
    <br />


    <br />
    <br />
    <br />




    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
    <Header mdxType="Header"> Post-Lesson Knowledge Check</Header>
    <SomeComponent mdxType="SomeComponent" />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
  <br />
  <br />
  <TimeEstimate time={2} mdxType="TimeEstimate" />
  <MyCard title="2 minute quiz (Optional)" caption="Short, rapid quiz. Test your prior knowledge" mdxType="MyCard">
    <div style={{
          position: 'relative',
          width: '100%'
        }}>
          <AlphabetCards alphabetWords={alphabetWords2} mdxType="AlphabetCards" />
  </div>
  </MyCard>
  <br />
  <br />
  <br />
  <br />
  <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
  <br />
  <br />
  <br />
  </div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
  <TimeEstimate time={25} mdxType="TimeEstimate" />
  <div style={{
        position: 'relative',
        width: '100%'
      }}>
 <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/GAN_CODE.png" title="Real world use case for GANs." description="Detailed Pytorch code walkthrough. Data preparation to Model Inference" link="" mdxType="ImgMediaCard" />
      </div>
 <br />
 <br />
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;