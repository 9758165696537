
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import AlphabetCards from "../../../components/AlphabetCards";
import GeogebraDraw_Temp from "../../../components/GeogebraDraw_Temp";
import VAEComponentBeginner from  "../../../blocks/VAEComponentBeginner.js";
import VAEComponentGraduate from  "../../../blocks/VAEComponentGraduate.js";
import MyCard from  "../../../components/MyCard.js";
import SVGArrow from  "../../../components/SVGArrow.js";
import TimeEstimate from  "../../../components/TimeEstimate.js";
import WaterFlow from  "../../../components/WaterFlow.js";
import SimpleModalBlockly from  "../../../components/SimpleModalBlockly.js";
import ReactPlayer from 'react-player'
import { FaHourglassHalf } from 'react-icons/fa';
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const DecoderVi = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/TRANSFORMERS/VAE_SHERLOCK.mp4' mdxType="ReactPlayer" />
  </div>;
export const ggbParameters = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const GeogebraDraw = makeShortcode("GeogebraDraw");
const layoutProps = {
  ggbParameters2,
DecoderVi,
ggbParameters,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <br />
    <br />
    <WaterFlow mdxType="WaterFlow" />


 <div>
      <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/2023/RAG/RAG_Geogebra_Vertex.json" ggbParameters={ggbParameters2} appId="id_vae_play" email="pavanmirla@gmail.com" data={[{
        component: <DecoderVi mdxType="DecoderVi" />,
        button: "vbutton"
      }]} mdxType="GeogebraDraw" /> 
  </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;