
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import { useState } from 'react'
import Alert from '@mui/material/Alert';
import data from '../../data/options_chain.json';
import GeogebraComponent from "../../components/GeogebraComponent";
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const short_position = data.data;
export const short_position_params = {
  // material_id: "",
  width: 1800,
  height: 900,
  borderColor: "#FFFFFF",
  enableShiftDragZoom: false,
  ggbBase64: short_position
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const Highlight = ({
  children,
  color
}) => <span style={{
  backgroundColor: color,
  borderRadius: '2px',
  color: '#fff',
  padding: '0.2rem'
}}>
    {children}
  </span>;
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: 950,
  borderColor: "#FFFFFF",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const Figure = props => {
  return <figure class="chart">
      {/* <object data={props.data} type="image/svg+xml"></object> */}
      <img src={props.data} alt="yoast seo" height={props.height} width={props.width} />
      <figcaption>{props.caption}</figcaption>
    </figure>;
};

const layoutProps = {
  opts,
short_position_params,
meta,
Highlight,
ggbParameters,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
Figure
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">














    <GeogebraComponent id={"short_position_id"} newParameters={short_position_params} mdxType="GeogebraComponent" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;