import React, { useState } from "react";
import TitleComponent from "./TitleComponent";

import {
  createTheme,
  ThemeProvider,
  styled,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const StyledDrawer = styled(Drawer)({
  width: "240px",
  flexShrink: 0,
  top: '50px',  // adjust this value

});

const DrawerContainer = styled("div")({
  width: "240px",
  padding: "16px",
});


const StyledListItem = styled(ListItem)({
    '& a': {
      color: '#ffffff',  // Text Color
      textDecoration: 'none',
    },
    '& a:hover': {
      color: 'yellow',  // Hover Color
    },
    '& a:active': {
      color: '#4caf50',  // Active Color
    },
  });


  const ChapterText = styled('div')({
    fontWeight: 'bold',
    fontSize: '1.2em',
  });
  
  const SubTopicText = styled('div')({
    fontSize: '1em',
    paddingLeft: '20px',
  });

  
const topics = [
    {
        title: 'Introduction',
        link: '#introduction',
        subtopics: [],
      },
      {
        title: 'About The Author',
        link: '#about_me',
        subtopics: [],
    },
    {
      title: 'Chapter 1: Foundations of Machine Learning',
      link: '#chapter1',
      subtopics: [
        { title: 'Calculus for Deep Learning', link: '#PartialDerivatives' },
        { title: 'Mastering Vector Operations', link: '#vector_arithmetic' },
        { title: 'Single Layer Perceptron', link: '#single_layer_perceptron' },
      ],
    },
    {
      title: 'Chapter 2: Exploring Neural Networks',
      link: '#chapter2',
      subtopics: [
        { title: 'Multilayer Perceptrons', link: '#Perceptron' },
        { title: 'Activation Functions', link: '#activation_functions' },
        { title: 'Derivatives of Activation Functions', link: '#d_activation_functions' },
      ],
    },
    {
      title: 'Chapter 3: Training Neural Networks',
      link: '#chapter3',
      subtopics: [
        { title: 'Backpropagation Algorithm', link: '#BackPropagation' },
        { title: 'Interactive Exploration of Gradient Descent', link: '#Gradient' },
      ],
    },
    {
      title: 'Chapter 4: Core Concepts in Deep Learning',
      link: '#chapter4',
      subtopics: [
        { title: 'AI Core Concepts: Embeddings, Tensors and More', link: '#LogisticRegression' },
        { title: 'Understanding Embeddings', link: '#embeddings' },
      ],
    },
    {
        title: 'Chapter 5: Transformers',
        link: '#Transformers',
        subtopics: [
          { title: 'Introduction to Transformers', link: '#intro_transformers' },
          { title: 'Attention Mechanisms', link: '#attention_mechanisms' },
          // More sections can be added here
        ],
      },
      {
        title: 'Chapter 6: Generative Adversarial Networks (GANs)',
        link: '#GANs',
        subtopics: [
          { title: 'Introduction to GANs', link: '#intro_gans' },
          { title: 'Architecture and Components', link: '#architecture_gans' },
          // More sections can be added here
        ],
      },
      {
        title: 'Chapter 7: Variational Autoencoders (VAEs)',
        link: '#VAEs',
        subtopics: [
          { title: 'Introduction to VAEs', link: '#intro_vaes' },
          { title: 'VAE Architecture', link: '#architecture_vaes' },
          // More sections can be added here
        ],
      },
      {
        title: 'Chapter 8: Large Language Models',
        link: '#Large_Language_Models',
        subtopics: [
          { title: 'Introduction to Language Models', link: '#intro_language_models' },
          { title: 'Fine-tuning OpenAI Model', link: '#fine_tuning_openai' },
          { title: 'Fine-tuning LLAMA Model', link: '#fine_tuning_llama' },
          // More sections can be added here
        ],
      },
      {
        title: 'Chapter 9: Generative AI Companies',
        link: '#gen_ai_companies',
        subtopics: [
          { title: 'Introduction', link: '#intro_gen_ai_companies' },
          { title: 'Types of Companies Using Generative AI', link: '#types_of_companies' },
          { title: 'Case Studies', link: '#case_studies' },
            { title: 'Healthcare', link: '#case_study_healthcare' },
            { title: 'Finance', link: '#case_study_finance' },
            { title: 'Retail', link: '#case_study_retail' },
            { title: 'Entertainment', link: '#case_study_entertainment' },
            { title: 'Manufacturing', link: '#case_study_manufacturing' },
          { title: 'Investment Landscape', link: '#investment_landscape' },
          { title: 'Challenges and Ethical Considerations', link: '#challenges_ethics' },
          { title: 'Future Outlook', link: '#future_outlook' },
        ],
      }
      
  ];
  

export default function App() {
    const [openIndexes, setOpenIndexes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(true);
    

  const toggleOpen = (index) => {
    setOpenIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  const filteredTopics = topics.reduce((acc, topic) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    if (topic.title.toLowerCase().includes(lowerSearchTerm)) {
      acc.push(topic);
    } else {
      const matchingSubtopics = topic.subtopics.filter((subtopic) =>
        subtopic.title.toLowerCase().includes(lowerSearchTerm)
      );
      if (matchingSubtopics.length > 0) {
        acc.push({
          ...topic,
          subtopics: matchingSubtopics,
        });
      }
    }
    return acc;
  }, []);
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
      <Button variant="contained" 
        onClick={() => setDrawerOpen(!drawerOpen)}
        style={{ marginTop: '60px', zIndex: 1000000, left: '25px',position: 'fixed', top: '5px' }}  // Adjust the values as needed
        >
        Toggle Menu
      </Button>
      <StyledDrawer variant="persistent" anchor="left" open={drawerOpen}>
        <DrawerContainer>
          <TextField
            fullWidth
            label="Search Topics"
            variant="outlined"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginTop: '100px'}}

          />
          <List>
            {filteredTopics.map((topic, index) => (
              <div key={index}>
                <StyledListItem button onClick={() => toggleOpen(index)}>
                  <ListItemText>
                    <ChapterText><a href={topic.link}>{topic.title}</a></ChapterText>
                  </ListItemText>
                  {topic.subtopics.length > 0 ? (
                    openIndexes.includes(index) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </StyledListItem>
                <Collapse in={openIndexes.includes(index)}>
                  <List component="div" disablePadding>
                    {topic.subtopics.map((subtopic, subIndex) => (
                      <StyledListItem key={subIndex}>
                        <ListItemText>
                          <SubTopicText><a href={subtopic.link}>{subtopic.title}</a></SubTopicText>
                        </ListItemText>
                      </StyledListItem>
                    ))}
                  </List>
                </Collapse>
              </div>
            ))}
          </List>
        </DrawerContainer>
      </StyledDrawer>
      </div>
      <div style={{ marginLeft: drawerOpen ? "240px" : "0px", padding: "16px" }}>
        {/* Your main content goes here */}
      </div>
    </ThemeProvider>
  );
}