import React, { useRef, useEffect, useImperativeHandle } from 'react';
import Blockly from 'blockly';
import DarkTheme from '@blockly/theme-dark';


Blockly.Blocks['network_type'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Network Type")
        .appendField(new Blockly.FieldDropdown([["Simple", "MLP"], ["Medium", "RNN"], ["Complex", "CNN"]]), "network_type");
    this.appendDummyInput()
        .appendField("Input Dimension")
        .appendField(new Blockly.FieldNumber(0), "input_dim");
    this.appendDummyInput()
        .appendField("Output Dimension")
        .appendField(new Blockly.FieldNumber(0), "output_dim");
        this.appendDummyInput()
        .appendField("Signal Type")
        .appendField(new Blockly.FieldDropdown([["Bumpy", "ReLU"], ["Smooth", "Sigmoid"], ["Fast", "Tanh"], ["Probability", "Sigmoid"]]), "signal_type");
    this.setPreviousStatement(true, "NetworkType");
    this.setNextStatement(true, "NetworkType");
    this.setColour(120);
    this.setTooltip("This block allows you to choose the type of network for the generator and set the input and output dimensions.");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['generator_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Generator (Counterfeitor)");
    this.appendStatementInput("RANDOM_NOISE")
        .setCheck("RandomNoise")
        .appendField("Random Noise");
    this.appendStatementInput("NETWORK_TYPE")
        .setCheck("NetworkType")
        .appendField("Network Configuration");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(290);
    this.setTooltip("This block represents the generator in a GAN. The generator takes in input data and random noise, and it outputs fake data.");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['input_data'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Real Data");
    this.setPreviousStatement(true, "RealData");
    this.setNextStatement(true, "RealData");
    this.setColour(30);
    this.setTooltip("This block represents the real data for the discriminator.");
    this.setHelpUrl("");
  }
};


Blockly.Blocks['random_noise'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Random Noise");
    this.setPreviousStatement(true, "RandomNoise");
    this.setNextStatement(true, "RandomNoise");
    this.setColour(290);
    this.setTooltip("This block represents the random noise for the generator.");
    this.setHelpUrl("");
  }
};



Blockly.Blocks['input_dim'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Input Dimension")
        .appendField(new Blockly.FieldNumber(0), "input_dim");
    this.setPreviousStatement(true, "InputDim");
    this.setNextStatement(true, "InputDim");
    this.setColour(120);
    this.setTooltip("This block allows you to set the input dimension for the generator.");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['output_dim'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Output Dimension")
        .appendField(new Blockly.FieldNumber(0), "output_dim");
    this.setPreviousStatement(true, "OutputDim");
    this.setNextStatement(true, "OutputDim");
    this.setColour(120);
    this.setTooltip("This block allows you to set the output dimension for the generator.");
    this.setHelpUrl("");
  }
};




Blockly.Blocks['signal_type'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Signal Type")
        .appendField(new Blockly.FieldDropdown([["Bumpy", "ReLU"], ["Smooth", "Sigmoid"], ["Fast", "Tanh"], ["Probability", "Sigmoid"]]), "signal_type");
    this.setPreviousStatement(true, "SignalType");
    this.setNextStatement(true, "SignalType");
    this.setColour(120);
    this.setTooltip("This block lets you choose the signal type.");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['discriminator_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Discriminator (Detector)");
    this.appendStatementInput("FAKE_DATA")
        .setCheck("FakeData")
        .appendField("Fake Data");
    this.appendStatementInput("REAL_DATA")
        .setCheck("RealData")
        .appendField("Real Data");
    this.appendStatementInput("NETWORK_TYPE")
        .setCheck("NetworkType")
        .appendField("Network Configuration");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(30);
    this.setTooltip("This block represents the discriminator in a GAN. The discriminator takes in both fake data from the generator and real data, then outputs a signal type (probability).");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['probability_output'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Probability Output");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip("This block represents the probability output from the discriminator (detector). It indicates the likelihood that the input data is real.");
  }
};

Blockly.Blocks['real_probability_output'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Real Probability Output");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip("This block represents the probability output from the discriminator (detector) for real data. It indicates the likelihood that the real input data is identified as real.");
  }
};

Blockly.Blocks['fake_probability_output'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Fake Probability Output");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(240);
    this.setTooltip("This block represents the probability output from the discriminator (detector) for fake data. It indicates the likelihood that the fake input data is identified as fake.");
  }
};




Blockly.Blocks['real_loss'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Real Loss (BCE)");
    this.appendDummyInput()
        .appendField("Target Probability")
        .appendField(new Blockly.FieldNumber(1), "TARGET_PROB");
    this.appendStatementInput("REAL_PROB")
        .appendField("Real Probability Output");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip("This block represents the real loss, which is the binary cross-entropy loss for the real data. The discriminator should classify these as real (1).");
  }
};

Blockly.Blocks['fake_loss'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Fake Loss (BCE)");
    this.appendDummyInput()
        .appendField("Target Probability")
        .appendField(new Blockly.FieldNumber(0), "TARGET_PROB");
    this.appendStatementInput("FAKE_PROB")
        .appendField("Fake Probability Output");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip("This block represents the fake loss, which is the binary cross-entropy loss for the fake data generated by the generator. The discriminator should classify these as fake (0).");
  }
};



Blockly.Blocks['generator_loss'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Generator Loss");
    this.appendStatementInput("FAKE_OUTPUT")
        .appendField("Fake Probability Output")
        .setCheck(['ProbabilityOutput']);
    this.appendDummyInput()
        .appendField("Target Probability")
        .appendField(new Blockly.FieldDropdown([["1 (Real)", "1"], ["0 (Fake)", "0"]]), "target_probability");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip("This block configures the loss for the generator. The loss is the binary cross-entropy between the discriminator's predictions on the fake data and the target probability.");
  }
};


// Optimizer configuration block
Blockly.Blocks['optimizer_config'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Optimizer Config");
    this.appendDummyInput()
        .appendField("Learning Rate")
        .appendField(new Blockly.FieldNumber(0.01), "learning_rate");
    this.appendDummyInput()
        .appendField("Optimizer Type")
        .appendField(new Blockly.FieldDropdown([["Adam", "ADAM"], ["SGD", "SGD"], ["RMSprop", "RMSPROP"]]), "optimizer_type");
    this.setPreviousStatement(true, 'OptimizerConfig');
    this.setNextStatement(true, 'OptimizerConfig');
    this.setColour(60);
    this.setTooltip("This is the optimizer configuration block.");
  }
};



// Loss function block
Blockly.Blocks['loss_function'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Loss Function")
        .appendField(new Blockly.FieldDropdown([["Mean Squared Error", "MSE"], ["Cross-Entropy", "CROSS_ENTROPY"], ["Binary Cross-Entropy", "BINARY_CROSS_ENTROPY"]]), "loss_type");
    this.setPreviousStatement(true, 'LossFunction');
    this.setNextStatement(true, 'LossFunction');
    this.setColour(60);
    this.setTooltip("This is the loss function block.");
  }
};




Blockly.Blocks['input_data_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Original Data");
    this.setNextStatement(true, null);
    this.setPreviousStatement(true, null);
    this.setColour(20);
    this.setTooltip("This block represents the original data to the model.");
  } 
};
Blockly.Blocks['generated_data'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Generated Fake Data");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(290);
    this.setTooltip("This block represents the data generated by the model.");
  }
};

Blockly.Blocks['ground_truth_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Expected Data");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(10);
    this.setTooltip("This block represents the expected data that the model should generate.");
  }
};





Blockly.Blocks['training_loop_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Learning Process");
    this.appendDummyInput()
        .appendField("Measure of Error")
        .appendField(new Blockly.FieldDropdown([["Small difference", "MSE"], ["Large difference", "CrossEntropy"], ["Average difference", "LogLoss"]]), "loss_type");
    this.appendDummyInput()
        .appendField("Learning Speed")
        .appendField(new Blockly.FieldDropdown([["Slow", "SGD"], ["Moderate", "Adam"], ["Fast", "RMSprop"]]), "optimizer");
    this.appendDummyInput()
        .appendField("Learning Duration")
        .appendField(new Blockly.FieldNumber(10), "epochs");

    // Create a new dummy input for the "Loss" label
    this.appendDummyInput()
        .appendField("Loss");

    this.appendStatementInput("PREDICTION")
        .appendField("Generated Data")
        .setCheck(['Prediction']);
    this.appendStatementInput("GROUND_TRUTH")
        .appendField("Expected Data")
        .setCheck(['GroundTruth']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This block configures the learning process of the model.");
  }
};



Blockly.Blocks['model_save_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Remember Model");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(80);
    this.setTooltip("This block saves the trained model so you can use it again later.");
  }
};

Blockly.Blocks['model_load_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Recall Model");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(80);
    this.setTooltip("This block loads a previously saved model.");
  }
};

Blockly.Blocks['train_step_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Training Step");
    this.setPreviousStatement(true, "TrainStep");
    this.setNextStatement(true, "TrainStep");
    this.setColour(60);
    this.setTooltip("This block represents a single step in the training process.");
  }
};

// Training block for Generator
Blockly.Blocks['training_generator'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Generator Training");
    this.appendStatementInput("LOSS")
        .setCheck(['LossFunction'])
        .appendField("Loss Function");
    this.appendStatementInput("CONFIG")
        .setCheck(['OptimizerConfig'])
        .appendField("Optimizer Config");
    this.setColour(290);
    this.setTooltip("Training configuration for the generator.");
  }
};

// Training block for Discriminator
Blockly.Blocks['training_discriminator'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Discriminator Training");
    this.appendStatementInput("LOSS")
        .setCheck(['LossFunction'])
        .appendField("Loss Function");
    this.appendStatementInput("CONFIG")
        .setCheck(['OptimizerConfig'])
        .appendField("Optimizer Config");
    this.setColour(30);
    this.setTooltip("Training configuration for the discriminator.");
  }
};





Blockly.Blocks['model_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Model");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This block represents the model. You can specify the structure of the model here.");
  }
};


// Placeholder for Generator
Blockly.Blocks['placeholder_generator'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Generator block here");
    this.appendStatementInput("OPTIONS")
        .setCheck(['NetworkType']); 
    this.setColour(290);
    this.setTooltip("Placeholder for the Generator block.");
  }
};

// Placeholder for Discriminator
Blockly.Blocks['placeholder_discriminator'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Discriminator block ");
    this.appendStatementInput("OPTIONS")
        .setCheck(['NetworkType']); 
    this.setColour(30);
    this.setTooltip("Discriminator block.");
  }
};


// Placeholder for Training
Blockly.Blocks['placeholder_training'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Training block");
    this.appendStatementInput("OPTIONS")
        .setCheck(['NetworkType']); 
    this.setColour(230);
    this.setTooltip("Placeholder for the Training block.");
  }
};



Blockly.Blocks['large_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setInputsInline(true);
    this.setColour(230);
    this.setTooltip("This is a large block.");
  }
};

Blockly.Blocks['list_length'] = {
  init: function() {
    this.appendValueInput("LIST")
        .setCheck("Array")
        .appendField("length of list");
    this.setOutput(true, "Number");
    this.setColour(230);
    this.setTooltip("This block returns the length of the given list.");
  }
};



// Parent block
Blockly.Blocks['horizontal_list'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Horizontal List");
    this.appendStatementInput("LIST")
        .setCheck('ListItem');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This is a horizontal list of blocks.");
  }
};

// Child block
Blockly.Blocks['list_item'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("List Item");
    this.setPreviousStatement(true, 'ListItem');
    this.setNextStatement(true, 'ListItem');
    this.setColour(60);
    this.setTooltip("This is an item in the list.");
  }
};









// Blockly.Blocks['large_block'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("                  "); // Fill up with space
//     this.appendDummyInput()
//         .appendField("                  "); // Fill up with space
//     this.appendDummyInput()
//         .appendField("                  "); // Fill up with space
//     this.appendDummyInput()
//         .appendField("                  "); // Fill up with space
//     this.setOutput(true, null);
//     this.setColour(230);
//     this.setTooltip("This is a large block.");
//   }
// };


Blockly.Blocks['chain_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Chain Block");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This is a block in a chain.");
  }
};


// Child block
Blockly.Blocks['list_item'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("List Item");
    this.setPreviousStatement(true, 'ListItem');
    this.setNextStatement(true, 'ListItem');
    this.setColour(60);
    this.setTooltip("This is an item in the list.");
  }
};

const VAEComponentBeginner = React.forwardRef(({ workspaceXML, onChange }, ref) => {
  const workspaceRef = useRef(null);
  const blocklyDivRef = useRef(null);

  const toolboxXML = `
  <xml xmlns="http://www.w3.org/1999/xhtml" id="toolbox" style="display: none;">
  <block type="generator_block"></block>
  <block type="input_data"></block>
  <block type="random_noise"></block>
  <block type="network_type"></block>


  <block type="discriminator_block"></block>
  <block type="probability_output"></block>

  <block type="real_probability_output"></block>
  <block type="fake_probability_output"></block>

  <block type="generated_data"></block>
  <block type="real_loss"></block>
  <block type="fake_loss"></block>
  <block type="generator_loss"></block>

  <block type="loss_function"></block>
  <block type="optimizer_config"></block>


  
  </xml>
`;

  useEffect(() => {

    const workspace = Blockly.inject(blocklyDivRef.current, {
   toolbox: toolboxXML,
      scrollbars: false,
      theme: DarkTheme,  // Use the imported dark theme
      toolboxPosition: 'left'
    });

    if (!workspaceXML) {


            // Create placeholders and add to workspace
      const placeholderGenerator = workspace.newBlock('placeholder_generator');
      placeholderGenerator.moveBy(100, 50);
      placeholderGenerator.initSvg();
      placeholderGenerator.render();

      const placeholderDiscriminator = workspace.newBlock('placeholder_discriminator');
      placeholderDiscriminator.moveBy(600, 50);
      placeholderDiscriminator.initSvg();
      placeholderDiscriminator.render();

      const placeholderTrainingG = workspace.newBlock('training_generator');
      placeholderTrainingG.moveBy(100, 420);
      placeholderTrainingG.initSvg();
      placeholderTrainingG.render();

      const placeholderTrainingD = workspace.newBlock('training_discriminator');
      placeholderTrainingD.moveBy(600, 420);
      placeholderTrainingD.initSvg();
      placeholderTrainingD.render();





    } else {
      Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(workspaceXML), workspace);
    }

    const handleChange = (event) => {
      if (event.type === Blockly.Events.UI) {
        return;
      }

      const newWorkspaceXML = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
      onChange(newWorkspaceXML);
    };

    workspace.addChangeListener(handleChange);

    workspaceRef.current = workspace;

    // Clean up
    return () => {
      workspace.removeChangeListener(handleChange);
      workspace.dispose();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    get workspace() {
      return workspaceRef.current;
    }
  }));

  return (
    <div ref={blocklyDivRef} style={{ height: '100vh', width: '100%' }} />
  );
});

export default VAEComponentBeginner;
