
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import LearningGoals from "../../components/LearningGoals";
import FlipCardGrid from "../../components/FlipCardGrid";
import TitleSection from "../../components/TitleSection";
import SubtitleSection from "../../components/SubtitleSection";
import ImgMediaCard from "../../components/ImgMediaCard";
import VideoMediaCard from "../../components/VideoMediaCard";
import "../../markdown-styles/styles.css";
export const Intro_Videos = props => <div style={{
  padding: 0,
  backgroundColor: "#1e1e1e",
  width: "100%",
  marginTop: '50px',
  margin: "auto",
  zIndex: 9999
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} mdxType="ReactPlayer" />
    <br />
  </div>;
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  // material_id: "",
  width: 300,
  height: 450,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "510",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: "green"
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: "yellow"
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const cardsContentServices = [{
  title: 'Efficiency and Productivity',
  content: 'AI consulting can help automate repetitive tasks, freeing up your workforce to focus on more strategic and valuable tasks.'
}, {
  title: 'Data-Driven Decision Making',
  content: 'AI can process and analyze vast amounts of data quickly, uncovering patterns, trends, and insights that would be impossible for humans to identify manually.'
}, {
  title: 'Personalized Customer Experience',
  content: 'AI can help businesses deliver a more personalized and improved customer experience, based on insights derived from customer behavior and preferences.'
}, {
  title: 'Competitive Advantage',
  content: 'By incorporating AI into your business strategy, you can gain a competitive edge over businesses that are slower to adopt such technology.'
}, {
  title: 'Consistent and Structured Learning Experience',
  content: 'Our tool aids teachers by offering a consistent format for teaching. This helps maintain the quality of education, makes lesson planning easier, and ensures that every student gets the same comprehensive learning experience.'
}, {
  title: 'Cost Savings',
  content: 'While the initial investment in AI may be significant, the long-term cost savings from increased efficiency, reduced errors, and improved decision-making can more than make up for it.'
}, {
  title: 'Risk Management',
  content: 'AI can help businesses predict and mitigate risks by identifying patterns and trends that may signify potential future issues.'
}, {
  title: 'Innovation and Growth',
  content: "It can help businesses develop new products, services, and business models that wouldn't be possible without AI."
}];
export const buttons = [{
  label: "Agenda",
  time: 0
}, {
  label: "What is optimization?",
  time: .09
}, {
  label: "cardboard experiment",
  time: .36
}, {
  label: "Volume as a function of height",
  time: 1.14
}, {
  label: "First derivative",
  time: 1.41
}, {
  label: "What is critical point?",
  time: 1.59
}, {
  label: "What is slope of tangent line?",
  time: 2.09
}, {
  label: "Solving for height h to optimize volume",
  time: 2.15
}, {
  label: "Summary",
  time: 2.51
}];
export const goals = [{
  id: 'LG01',
  description: "Understand the concept of optimization in the context of maximizing volume."
}, {
  id: 'LG02',
  description: "Identify the optimization function and its role in optimization problems."
}, {
  id: 'LG03',
  description: "Recognize the constraints in an optimization problem and understand their significance."
}, {
  id: 'LG04',
  description: "Grasp the concept of a derivative and how it can be applied in optimization."
}, {
  id: 'LG05',
  description: "Comprehend the idea of a tangent and its relationship with derivatives."
}, {
  id: 'LG06',
  description: "Master the formula for derivatives and its application in solving optimization problems."
}, {
  id: 'LG07',
  description: "Understand what an inflection point is and how to identify it."
}, {
  id: 'LG08',
  description: "Define a critical point and explain its relevance in optimization."
}];
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" playing controls config={{
      file: {
        attributes: {
          id: "audio-element"
        }
      },
      playerVars: {}
    }} width="320px" height="180px" mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>
        What is optimization
      </button>
      <br />
      <button onClick={() => ref.current.seekTo(20)}>
        What is critical point?
      </button>
    </div>;
};
export const ClipVideo = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" startTime={10} endTime={20} playing controls mdxType="ReactPlayer" />
  </div>;
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const ReactPlayer = makeShortcode("ReactPlayer");
const layoutProps = {
  Intro_Videos,
meta,
ggbParameters,
ggbParameters2,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
cardsContentServices,
buttons,
goals,
Player,
ClipVideo,
Box11,
Box22
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">















    <br />
    <br />
    <br />
    <br />

    <>
      <TitleSection title="Why Choose AI for Your Business?" mdxType="TitleSection" />
      <FlipCardGrid cardsContent={cardsContentServices} mdxType="FlipCardGrid" />;
    </>
    <br />
    <br />
    <br />
    <br />
    <>
      <TitleSection title="AI Portfolio" mdxType="TitleSection" />
      <SubtitleSection subtitle="Here are a few examples of our AI products and services." mdxType="SubtitleSection" />
    </>
    <Header mdxType="Header">Enhancing Sports Performance with AI</Header>
    <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/People_TennisAI.mp4" title="Intelligent Tennis Assistant" description="Unlock your true potential with Tennis AI: Tailored coaching powered by advanced machine learning. Learn, adapt, and improve with personalized analytics and insights." link="/tennis_ai" mdxType="VideoMediaCard" />
    <Header mdxType="Header">Large Language Models</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/googleio.png" title="Transforming Unstructured Data into Valuable Insights" description="Our AI-driven pipeline methodically parses, processes, and presents data in an intuitive format, seamlessly integrating insights into your existing workflows. Harness the untapped potential of your data with our bespoke AI solutions." link="https://www.perceptron.solutions/googleio" mdxType="ImgMediaCard" />
    <>
      <TitleSection title="What Next?" mdxType="TitleSection" />
      <SubtitleSection subtitle="Ready to Leverage AI for Your Business? Connect with Our Experts Today!." mdxType="SubtitleSection" />
      <SubtitleSection subtitle="
 office@davinci-dynamics.com
" mdxType="SubtitleSection" />
    </>




    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;