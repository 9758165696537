
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
export const goals = [{
  id: 'CL01',
  title: "Foundations of Calculus",
  description: "Recognize the fundamentals of calculus and its relevance in the context of deep learning."
}, {
  id: 'CL02',
  title: "Concept of Derivatives",
  description: "Understand the concept of derivatives, how they represent the rate of change, and their role in optimization problems."
}, {
  id: 'CL03',
  title: "Partial Derivatives",
  description: "Comprehend the idea of partial derivatives and their role in multi-variable functions within deep learning."
}, {
  id: 'CL04',
  title: "Concept of Tangent Planes",
  description: "Understand the concept of tangent planes and how it extends the idea of the tangent line to higher dimensions."
}, {
  id: 'CL05',
  title: "Real-world Applications",
  description: "Identify how calculus concepts are used in real-world applications and deep learning models."
}, {
  id: 'CL06',
  title: "Visualization of Calculus Concepts",
  description: "Visualize calculus concepts in 3D to gain a better understanding of how they function in the context of deep learning."
}, {
  id: 'CL07',
  title: "Hessian Matrix and Second Order Derivatives",
  description: "Learn the concept of Hessian matrix, its significance in representing second order derivatives, and its role in understanding the curvature of functions."
}];
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const SomeComponent = makeShortcode("SomeComponent");
const ImageCard = makeShortcode("ImageCard");
const Marker = makeShortcode("Marker");
const Howler = makeShortcode("Howler");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  goals,
Box2,
ggbParameters2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Calculus for Deep learning`}</h1>
    <Header mdxType="Header"> Learning goals</Header>
    <SomeComponent mdxType="SomeComponent" />

    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />
    <div>
    <div style={{
        position: "relative"
      }}>
                    <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/6.png" mdxType="ImageCard" />
                    <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
                    <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
      </div>
      <div style={{
        position: "relative"
      }}>
                    <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/7.png" mdxType="ImageCard" />
                    <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
                    <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
      </div>
      <div>
        <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/partial_derivatives_car_3.json" ggbParameters={ggbParameters2} appId="partial_derivatives_1" email="pavanmirla@gmail.com" data={[{
          component: <Box2 mdxType="Box2" />,
          button: "XYVIEW"
        }]} mdxType="GeogebraDraw" /> 
   </div>
    </div>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;