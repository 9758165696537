
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import InteractiveTour from "../../../components/InteractiveTour";
import VideoSection from "../../../components/VideoSection";
import YouTube from "react-youtube";
import LearningGoals from "../../../components/LearningGoals";
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const goals = [{
  id: 'LG01',
  description: "Understand why a single layer perceptron cannot solve the XOR problem."
}, {
  id: 'LG02',
  description: "Learn the concept of a multi-layer perceptron and how it differs from a single layer perceptron."
}, {
  id: 'LG03',
  description: "Understand how additional layers in a multi-layer perceptron allow it to solve non-linearly separable problems like XOR."
}, {
  id: 'LG04',
  description: "Visualize the decision boundaries formed by hyperplanes in a multi-dimensional space."
}, {
  id: 'LG05',
  description: "Learn how the sigmoid activation function in a 3D context can aid in the decision-making process of a multi-layer perceptron."
}, {
  id: 'LG06',
  description: "Understand what each hidden layer in a multi-layer perceptron learns and how it contributes to the final decision."
}, {
  id: 'LG07',
  description: "Visualize and comprehend the transformation of data as it passes through different layers of the multi-layer perceptron."
}];
export const Box3 = () => <div style={{
  backgroundColor: 'tomato'
}}>
  <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/youtube_intro_video.mp4" title="Interactive Learning Platform" description="Specialized in mathematics, analytical topics, and machine learning topics." link="/tennis_ai" mdxType="VideoMediaCard" />
</div>;
export const Box2 = () => <div style={{
  backgroundColor: 'tomato'
}}>
  <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/covered_call.mp4"} mdxType="VideoSection" /> 
</div>;
export const opts = {
  height: "390",
  width: "650",
  playerVars: {
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const SomeComponent = makeShortcode("SomeComponent");
const Header = makeShortcode("Header");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const VideoMediaCard = makeShortcode("VideoMediaCard");
const layoutProps = {
  ggbParameters2,
goals,
Box3,
Box2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SomeComponent mdxType="SomeComponent" />
    <h1>{`Multi Layer Perceptron`}</h1>
    <Header mdxType="Header"> Learning goals</Header>

    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />



    <h1>{`Interactive visualization`}</h1>
    <div>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/XOR_Zoomable_v1.json" ggbParameters={ggbParameters2} appId="xor_two" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;