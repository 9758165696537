import React from "react";

const scroll = () => {
    const section = document.querySelector( '#geogebra_element' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    ggbApplet.evalCommand('Execute({"RunClickScript(text36)"})')

  };

  
function Icon(props) {
    const handleClick = (value) => {
      alert(value)
        //ggbApplet.evalCommand(value)
        if (props.geogebraRef) {
         props.geogebraRef.current.scrollIntoView( { behavior: 'smooth', block: 'start' } )
           }
    };
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' pointerEents="bounding-box">
      <path fill='#1e1e1e' d='M5.662 7.283H492.724V100H5.662z'></path>
      <text
        style={{ whiteSpace: "pre" }}
        x='14.544'
        y='26.287'
        fill='#FFCE00'
        fontFamily='Apple SD Gothic Neo'
        fontSize='15.6'
      >
        21IFM
      </text>
      <text
        style={{
          WebkitTextTransform: "capitalize",
          textTransform: "capitalize",
          whiteSpace: "pre",
        }}
        fill='#F4EEEE'
        fontFamily='Apple SD Gothic Neo'
        fontSize='24'
        transform='translate(-12.031 .197)'
      >
        <tspan x='131.374' y='45.586'>
          Let&apos;s interpret Gamma{" "}
        </tspan>
        <tspan x='131.374' dy='1em'>
          ​
        </tspan>
        <tspan>for a Long call Option</tspan>
      </text>
      <path stroke='#000' d='M5.662 200L486.283 200.135'></path>
      <path stroke='#000' d='M5.662 300L486.283 300'></path>
      <path stroke='#000' d='M6.013 399.748L486.283 400'></path>
      <path stroke='#000' d='M252.122 99.865L252.122 200'></path>
      <path stroke='#000' d='M200 200L200 299.865'></path>
      <path stroke='#000' d='M351.547 200.135L351.547 300'></path>
      <path stroke='#000' d='M252.122 300L252.122 500'></path>
      <text
        style={{ whiteSpace: "pre" }}
        fill='#333'
        fontFamily='Apple SD Gothic Neo'
        fontSize='12'
        transform='translate(-10.147 -1.636)'
      >
        <tspan x='72.133' y='121.366'>
          Add 10 cups of wate
        </tspan>
        <tspan x='72.133' dy='1em'>
          ​
        </tspan>
        <tspan>or broth to a 3-4 quart</tspan>
        <tspan x='72.133' dy='1em'>
          ​
        </tspan>
        <tspan>size pot and bring to a </tspan>
        <tspan x='72.133' dy='1em'>
          ​
        </tspan>
        <tspan>rolling boil.</tspan>
      </text>
      <g id='step1' transform='translate(0 9.16)' pointerEvents="bounding-box" onclick={() => {alert('click')}}>
        <circle cx='28.619' cy='114.088' r='14.088' fill='#FFCE00'></circle>
        <text
          style={{ whiteSpace: "pre" }}
          x='23.538'
          y='121.004'
          fill='#333'
          fontFamily='Arial, sans-serif'
          fontSize='15.6'
        >
          1
        </text>
      </g>
      <text
        style={{ whiteSpace: "pre" }}
        fill='#333'
        fontFamily='Apple SD Gothic Neo'
        fontSize='12'
        transform='translate(242.712 -1.636)'
      >
        <tspan x='72.133' y='121.366'>
          {props.step1}
        </tspan>
        <tspan x='72.133' dy='1em'>
          ​
        </tspan>
        <tspan>or broth to a 3-4 quart</tspan>
        <tspan x='72.133' dy='1em'>
          ​
        </tspan>
        <tspan>size pot and bring to a </tspan>
        <tspan x='72.133' dy='1em'>
          ​
        </tspan>
        <tspan>rolling boil.</tspan>
      </text>
      <g id='step2'>
        <circle cx='273.87' cy='123.249' r='14.088' fill='#FFCE00'></circle>
        <text
          style={{ whiteSpace: "pre" }}
          x='268.789'
          y='130.165'
          fill='#333'
          fontFamily='Arial, sans-serif'
          fontSize='15.6'
        >
          2
        </text>
      </g>
      <path   className="mycsstest"
              onClick={()=> handleClick(1)}
              pointerEvents= "bounding-box"
            //   onMouseEnter={() => {alert("clicked mouse enter")}}
        fill='rgba(216, 216, 216, 0)'
        d='M6.013 100H252.122V200H6.013z'
      ></path>
      <path
        fill='rgba(216, 216, 216, 0)'
        d='M252.859 100H500V200H252.859z'
      ></path>
    </svg>
  );
}

export default React.memo(Icon);