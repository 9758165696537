import {useContext } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import Link from '@material-ui/core/Link';

export default function GeogebraDraw (props) {
    const frmContext = useContext(firebaseAuth);
    const token = frmContext.token
    
return (
      <>

{token !== null ? (
         
         <>
        {props.component}
         </>

     ) : (
       <>
       <Link href="/signin" variant="body2">
           <h2>Have an account? Click here to Sign In</h2>
        </Link>

        <Link href="/signup" variant="body2">
           <h2>Don't Have an account? Create it</h2>
        </Link>

       </>
     )}

      </>
       )
}