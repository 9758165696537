import React, { useRef } from 'react';
import BlocklyWorkspace from './VAEBlocksBeginner';

const VAEComponentBeginner = () => {
  const [workspaceXML, setWorkspaceXML] = React.useState('');
  const workspaceRef = useRef(null);

  return (
    <div>
      <BlocklyWorkspace
        workspaceXML={workspaceXML}
        onChange={setWorkspaceXML}
        ref={workspaceRef} // Make sure to pass the ref to the BlocklyWorkspace component
      />
    </div>
  );
};

export default VAEComponentBeginner;
  