
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import AskBot from "../../components/chatbot/AskBotGenericV2";
import "../../markdown-styles/styles.css";
import { useState } from 'react';
export const Intro_Videos = props => <div style={{
  padding: 0,
  backgroundColor: "#1e1e1e",
  width: "100%",
  marginTop: '50px',
  margin: "auto",
  zIndex: 9999
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} mdxType="ReactPlayer" />
    <br />
  </div>;
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  // material_id: "",
  width: 300,
  height: 450,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "510",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const VideoComponent = () => <div style={{
  position: 'relative',
  paddingBottom: '56.25%',
  height: '0'
}}>
    <iframe title="YouTube Video" style={{
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%'
  }} src="https://www.youtube.com/embed/esodqUt1DlM" frameBorder="0">
    </iframe>
  </div>;
export const PersonaComponent = ({
  addBlock
}) => {
  const [loading, setLoading] = useState(false);
  const handlePersonaClick = async persona => {
    setLoading(true);
    addBlock(`You selected ${persona}`, 'system');
    try {
      const response = await fetch('http://localhost:3001/getQuestions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          persona
        })
      });
      const data = await response.json();
      if (data.questions) {
        // Concatenate the AI-generated questions and add as a single block
        const concatenatedQuestions = data.questions.join('\n');
        addBlock(concatenatedQuestions, 'system');
      } else {
        addBlock('Sorry, no questions generated.', 'system');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      addBlock('An error occurred while fetching questions.', 'system');
    } finally {
      setLoading(false);
    }
  };
  return <div>
      <button onClick={() => handlePersonaClick('Foodie')} disabled={loading}>
        Foodie
      </button>
      <button onClick={() => handlePersonaClick('Traveler')} disabled={loading}>
        Traveler
      </button>
      {loading && <p>Loading questions...</p>}
    </div>;
};
export const LocationComponent = ({
  addBlock
}) => {
  const [location1, setLocation1] = useState('');
  const [location2, setLocation2] = useState('');
  const handleLocationClick = async () => {
    addBlock(`Calculating distance between ${location1} and ${location2}...`, 'system');
    try {
      // Call the server to get the distance
      const response = await fetch('http://localhost:3001/calculateDistance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          location1,
          location2
        })
      });
      const data = await response.json();
      if (data.distance) {
        addBlock(`The distance between ${location1} and ${location2} is ${data.distance}.`, 'system');
      } else {
        addBlock('Sorry, could not calculate distance.', 'system');
      }
    } catch (error) {
      console.error('Error fetching distance:', error);
      addBlock('An error occurred while fetching distance.', 'system');
    }
  };
  return <div>
      {/* Placeholder for Google Map */}
      <div>Your Google Map Here</div>
      {/* Inputs to get the locations */}
      <input type="text" placeholder="Location 1" value={location1} onChange={e => setLocation1(e.target.value)} />
      <input type="text" placeholder="Location 2" value={location2} onChange={e => setLocation2(e.target.value)} />
      <button onClick={handleLocationClick}>Calculate Distance</button>
    </div>;
};
export const MapComponent = <div>Your Map Component Here</div>;
export const menuItems = [{
  label: 'Select Persona',
  action: addBlock => addBlock('Select a persona:', 'system', null, <PersonaComponent mdxType="PersonaComponent" />)
}, {
  label: 'Select Location',
  action: addBlock => addBlock('Select a location:', 'system', null, <LocationComponent mdxType="LocationComponent" />)
}, {
  label: 'Select Video',
  action: addBlock => addBlock('Select a Video:', 'system', null, <VideoComponent mdxType="VideoComponent" />)
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ReactPlayer = makeShortcode("ReactPlayer");
const layoutProps = {
  Intro_Videos,
meta,
ggbParameters,
ggbParameters2,
opts,
VideoComponent,
PersonaComponent,
LocationComponent,
MapComponent
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    <AskBot menuItems={menuItems} mdxType="AskBot" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;