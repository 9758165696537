import React, { useState } from 'react';

function SimpleModal({ title, children }) {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <div>
      <button onClick={showModal}>Open Modal</button>
      {visible && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white' }}>
          <h2>{title}</h2>
          <button onClick={closeModal}>Close</button>
          {children}
        </div>
      )}
    </div>
  );
}

export default SimpleModal;
