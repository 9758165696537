import React from 'react';
import Howler from 'react-howler';
import './styles.css';
import 'font-awesome/css/font-awesome.css';


class BackgroundImageWithOverlaidButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonAudioUrls: [],
      buttonPositions: [],
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick(audioUrl) {
    this.setState({
      buttonAudioUrls: [audioUrl],
    });
  }

  render() {
    const { backgroundImageUrl, buttonPositions } = this.props;
    const { buttonAudioUrls } = this.state;

    return (
      <div style={{position: 'relative'}}>
        <img src={backgroundImageUrl} alt="Background" style={{width: '100%', height: '100%'}} />
        {buttonPositions.map((buttonPosition, index) => (
          <button
            key={index}
            className="overlaid-button"
            style={{
              top: `${buttonPosition.top}%`,
              left: `${buttonPosition.left}%`,
            }}
            onClick={() => this.handleButtonClick(buttonPosition.audioUrl)}
          >
            <div className="button-number">{index + 1}</div>
            <i className="fa fa-volume-up" />
          </button>
        ))}
        {buttonAudioUrls.map((audioUrl, index) => (
          <Howler
            key={index}
            src={audioUrl}
            playing={true}
          />
        ))}
      </div>
    );
  }
}

export default BackgroundImageWithOverlaidButtons;
