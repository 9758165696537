import { useState, useEffect } from "react";
import { Howl } from 'howler';

let sound = null;

const MyComponent = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Initialize sound object once
    if (!sound) {
      sound = new Howl({
        src: props.soundSrc,
        onend: function() {
          setIsPlaying(false);
        }
      });
    }

    // Play or pause the sound based on isPlaying state
    if (isPlaying) {
      sound.play();
    } else {
      sound.pause();
    }

    // Cleanup when component unmounts
    return () => {
      if (sound) {
        sound.unload();
        sound = null;  // Make sure to set sound to null to fully reset the audio
      }
    };
  }, [isPlaying, props.soundSrc]);  // Re-run this effect when isPlaying or soundSrc changes

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <button onClick={handlePlayPause}>
      {isPlaying ? 'Pause' : 'Play'}
    </button>
  );
}

export default MyComponent;
