
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import { useState } from 'react'
import Alert from '@mui/material/Alert';
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const Highlight = ({
  children,
  color
}) => <span style={{
  backgroundColor: color,
  borderRadius: '2px',
  color: '#fff',
  padding: '0.2rem'
}}>
    {children}
  </span>;
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: 950,
  borderColor: "#FFFFFF",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const MyDynamicButton = () => {
  const [open, setOpen] = useState(false);
  return <>
    <button onClick={() => setOpen(true)}>Click to open</button>
    <button onClick={() => setOpen(false)}>Click to close</button>
    {open && <GeogebraDraw jsonUrl="https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/testfolder/csDeltav1.json" ggbParameters={ggbParameters} appId="DefinfitionVector2" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box2 mdxType="Box2" />,
      button: "text14"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text4"
    }]} mdxType="GeogebraDraw" />}
  </>;
};
export const Figure = props => {
  return <figure class="chart">
      {/* <object data={props.data} type="image/svg+xml"></object> */}
      <img src={props.data} alt="yoast seo" height="500" width="588" />
      <figcaption>{props.caption}</figcaption>
    </figure>;
};

const layoutProps = {
  meta,
Highlight,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
MyDynamicButton,
Figure
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">











    <h1>{`History. Dutch Tulip mania`}</h1>


    <p align="center">
      <Figure data="https://ichef.bbci.co.uk/news/976/cpsprodpb/E794/production/_101248295_tulips_painting_alamy.jpg" caption="A Tulip trade illustration. Source: Ref#1" mdxType="Figure" />
      <MyDynamicButton mdxType="MyDynamicButton" />
    </p>
    <h1>{`Spot transaction`}</h1>
    <p>{`Let's say Farmer Smith 👨‍🌾 wishes to sell unique Tulips 🌷from his farm for $10 each.`}</p>
    <p>{`In exchange for the ownership of the Tulips, Jerry (the trader) can agree to pay the Tulip price.`}</p>
    <p>{` Jerry and Farmer Smith entered into a spot or cash 💵 transaction in this case.`}</p>
    <h1>{`Forward Contract`}</h1>
    <p>{`Due to Trader Jerry's wedding within a year ⏳, he thought it would be best to approach Farmer Smith differently. `}</p>
    <p>{`Trader Jerry will pay Farmer Smith $10 for each Tulip, but he will ask Farmer Smith to complete the transaction later. Not now.`}</p>
    <p>{`At later time, Farmer Smith will receive payment, and Jerry will take possession of the Tulips.`}</p>
    <p>{`Jerry and Farmer Smith will have entered into a forward contract 🤝🏽if both parties agree. `}</p>
    <h1>{`Expiration date`}</h1>
    <p>{`A forward contract allows the parties to agree on the terms now, but the actual transfer of money or goods doesn't occur until a later date, the maturity or `}<strong parentName="p">{`expiration date`}</strong>{` .`}</p>
    <p>{`In a forward contract, the parties agree on the terms now, but nothing is actually exchanged until a later date, the maturity or expiration date.`}</p>
    <h1>{`Forward price`}</h1>
    <p>{`The price Farmer Smith will ask for his Tulips in a year is unlikely to be the same price he is asking today.`}</p>
    <p>{`There may be advantages or disadvantages for one party or another since both the payment and the transfer of goods are deferred. `}</p>
    <p>{`Farmer Smith might point out that if he receives full payment of right now, he can deposit the money in his bank account 🏦 and begin earning interest. `}</p>
    <p>{`However, he will have to forego any interest earnings under a forward contract. `}</p>
    <p>{`In this circumstance, Farmer Smith could insist that he and Jerry negotiate 🤝a one-year price that takes into account this loss of interest.`}</p>
    <h1>{`Futures Contract`}</h1>
    <p>{`Forward contracts traded on organized exchanges 🏛️ are often referred to as futures contracts.`}</p>
    <p>{`A futures exchange standardizes the contract 🙏specifications for forward contracts to make trading easier.`}</p>
    <p>{`The exchange specifies the quantity and quality of the goods to be delivered, the date and place of delivery, and the payment method. `}</p>
    <p>{`Furthermore, the exchange guarantees the integrity of the contract 🔏👏. `}</p>
    <p>{`In the event of default by either the buyer or the seller, the exchange is responsible for fulfilling the terms of the forward contract 👍.`}</p>
    <h1>{`Option Contract`}</h1>
    <p>{`Jerry seems to have a new problem. `}</p>
    <p>{`What if his fiancee walks out on their wedding plans? `}</p>
    <p>{`If the wedding does not happen, Jerry wants to be free of any obligation to buy Tulips.`}</p>
    <p>{`Jerry approaches Farmer Smith with a new proposal.`}</p>
    <p>{`Farmer Smith and Jerry will negotiate a one-year forward price for Tulips, but Jerry will have one year to decide whether to purchase Tulips.`}</p>
    <p>{`Within one year, Jerry can either buy Tulips at the agreed-on forward price, or walk away with no obligation or penalty`}</p>
    <h1>{`Call Option`}</h1>
    <p>{`Jerry realizes Farmer Smith's dilemma and offers to negotiate a separate payment as compensation for this uncertainty.`}</p>
    <p>{`In effect, Jerry is offering to buy the right to decide later whether he wants to acquire Tulips.`}</p>
    <p>{`Farmers Smith will keep this separate payment regardless of Jerry's final decision. `}</p>
    <p>{`When Jerry and Farmer Smith agree on both this separate payment and the forward price, they will enter into an option contract.`}</p>
    <p>{`An option contract gives one party the right to make a decision at a later time. `}</p>
    <h1>{`Call Buyer and Seller`}</h1>
    <p>{`Jerry is the buyer of a call option, which entitles him to buy at a later date. `}</p>
    <p>{`Farmer Smith is the call option seller.`}</p>
    <h1>{`Put Option as Insurance`}</h1>
    <p>{`Jerry is considering options in case Tulip prices drop and his fiance no longer desires Tulips.`}</p>
    <p>{`Jerry, the trader, has purchased insurance for Tulips.`}</p>
    <p>{`Tulips were insured so he could sell them back at a fixed price to the insurance company in the future.`}</p>
    <p>{`If Tulips  appreciates in value, Jerry iis under no obligation to sell them to the insurance company.`}</p>
    <p>{`In essence, Jerry has bought a Put option from an insurance company.`}</p>
    <p>{`Purchases of options involve the payment of a premium, just like insurance contracts. `}</p>
    <p>{`A premium is negotiated between the buyer and the seller, and the seller keeps it regardless of the buyer's decision.`}</p>
    <p>{`A contract of insurance may also define an exercise price, the amount the holder (Jerry) will receive if certain events occur `}</p>
    <h1>{`Long and Short`}</h1>
    <p>{`In order to sell something, we typically assume that we must first own it. Typically, we buy something first, then sell it.`}</p>
    <p>{`In derivative markets, 🙀however, the order is reversed. Rather than buying first and selling later, we can sell first and buy later.`}</p>
    <p>{`There are times when we may want to specify the order in which trades are entered. `}</p>
    <p>{`When a new trade is made, whether it be buying or selling, its an opening trade. A closing trade is one that reverses the initial trade.`}</p>
    <h1>{`Open Interest`}</h1>
    <p>{`Among the measures of trading activity in exchange-traded derivatives contracts is open interest, referring to the number of contracts that have yet to be closed out on an exchange. `}</p>
    <p>{`For every buyer there must be a seller, so the number of long and short contracts that haven't been closed out must be equal.`}</p>
    <h1>{`Debit and Credit`}</h1>
    <p>{`The result of a long position is usually a debit (we have to pay money when we buy).`}</p>
    <p>{`Selling a short position typically results in a credit (we are expecting to receive money). `}</p>
    <h1>{`Notional Value`}</h1>
    <p>{`When a forward contract is traded, no money exchanges hands, since the goods are to be exchanged at a later date.`}</p>
    <p>{`As there is no cash flow from the contract, in a sense, there is no cash value. `}</p>
    <p>{`A forward contract does have a notional or nominal value.`}</p>
    <p>{`The notional value of a forward contract for physical commodities is equal to the unit price multiplied by the number of units to be delivered at maturity. `}</p>
    <p>{`In the case of a forward contract that calls for the delivery of 1,000 Tulips at a price of $10 per unit, the notional value of the contract would be $10 × 1,000 = $10,000.`}</p>
    <div>
Ref #1: https://www.bbc.com/news/business-44067178
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;