
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
export const goals = [{
  id: 'BP01',
  title: "Understanding Backpropagation",
  description: "Understand the basic concept of backpropagation and its role in training neural networks."
}, {
  id: 'BP02',
  title: "Forward Propagation",
  description: "Learn about forward propagation and how predictions are made in a neural network."
}, {
  id: 'BP03',
  title: "Loss Calculation",
  description: "Understand how the loss function is computed after forward propagation."
}, {
  id: 'BP04',
  title: "Backward Propagation",
  description: "Learn about the process of backward propagation, where gradients are computed to adjust the weights."
}, {
  id: 'BP05',
  title: "Weight Updates",
  description: "Understand how weights in the neural network are updated using gradients calculated through backpropagation."
}, {
  id: 'BP06',
  title: "Role of Activation Functions",
  description: "Learn about the role of activation functions in backpropagation and how their derivatives are involved in weight updates."
}];
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const SomeComponent = makeShortcode("SomeComponent");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  goals,
Box2,
ggbParameters2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Back Propagation`}</h1>
    <Header mdxType="Header"> Learning goals</Header>

    <SomeComponent mdxType="SomeComponent" />
    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />
  <div>
      <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/Backprop_rajan_simple_7.json" ggbParameters={ggbParameters2} appId="backprop" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
  </div>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;