
import React, { useState, useEffect, useRef } from 'react';
import MyDraggableComponent from './DraggableComponent';
import { FaVideo, FaMusic, FaLink } from 'react-icons/fa';

const SimpleModal = ({isOpen, setIsOpen, children}) => { 
    return (
      <>
        {isOpen && (
          <MyDraggableComponent setIsModalOpen={setIsOpen}>
            {children}
          </MyDraggableComponent>
        )}
      </>
    );
  };
  
  export default SimpleModal;
