import React, { useState, useEffect } from 'react';

const CanvaEmbed = () => {
  const [timestamp, setTimestamp] = useState(Date.now());
  const baseIframeSrc = 'https://www.canva.com/design/DAFpkCwBwjo/watch?embed';
  const iframeSrc = `${baseIframeSrc}&t=${timestamp}`;
  const anchorHref = 'https://www.canva.com/design/DAFpkCwBwjo/watch?utm_content=DAFpkCwBwjo&utm_campaign=designshare&utm_medium=embeds&utm_source=link';
  const anchorText = 'Copy of Whiteboard by Pavan Vikram Mirla';

  const iframeStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    border: 'none',
    padding: '0',
    margin: '0'
  };

  const divStyle = {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingTop: '100.0000%',
    paddingBottom: '0',
    boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
    marginTop: '1.6em',
    marginBottom: '0.9em',
    overflow: 'hidden',
    borderRadius: '8px',
    willChange: 'transform'
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(Date.now());
    }, 30000); // Refresh every 30 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div style={divStyle}>
        <iframe 
          loading="lazy" 
          style={iframeStyle}
          src={iframeSrc} 
          allowFullScreen
        >
        </iframe>
      </div>
      <a 
        href={anchorHref} 
        target="_blank" 
        rel="noopener"
      >
        {anchorText}
      </a>
    </div>
  );
};

export default CanvaEmbed;
