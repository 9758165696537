import React from 'react';

const SoundCloudPlayer = ({ src, html }) => {
    const divStyle = {
        fontSize: '10px',
        color: '#cccccc',
        lineBreak: 'anywhere',
        wordBreak: 'normal',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontFamily: 'Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif',
        fontWeight: '100'
    };
    
    return (
        <div>
            <iframe
                width="100%"
                height="300"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src={src}>
            </iframe>
            <div style={divStyle} dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
};

export default SoundCloudPlayer;
