import React from 'react';
import {
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60%',
    margin: '40px auto',
    marginBottom: theme.spacing(3),
  },
  table: {
    minWidth: 400,
  },
  heading: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subHeading: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textDecoration: 'none', // No underline for dark mode
    color: '#E0E0E0', // Light grey text
    backgroundColor: '#333333',  // Slightly darker grey background
    '&:hover': {
      backgroundColor: '#292929'  // Darker grey when hovered over
    },
    borderRadius: '8px',  // Rounded corners
    boxShadow: '0px 3px 10px #111111',  // Subtle drop shadow
    border: '1px solid #444444',  // Slight border
    letterSpacing: '0.1em',  // Letter spacing
    lineHeight: '1.5em'  // Line height
  },
  intro: {
    marginBottom: theme.spacing(3),
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const categories = [
  {
    title: 'Educational Approach',
    traditional: 'Fixed curriculum',
    interactive: 'Adaptive learning paths',
  },
  {
    title: 'Student Engagement',
    traditional: 'Passive listening and note-taking',
    interactive: 'Hands-on experience with interactive simulations, live coding sessions, and collaborative projects',
  },
  {
    title: 'Assessment Techniques',
    traditional: 'Scheduled Examinations with Post-assessment Feedback',
    interactive: 'Real-time, adaptive quizzes and instant peer reviews for immediate feedback and improvement'
  }
  
  // ... more categories
];

export default function IntroAndComparison() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <Typography variant="h2" align="center" className={classes.heading}>
        Welcome to the Interactive Book on Generative AI!
      </Typography>
      <Typography align="center" className={classes.intro}>
        Discover the world of Generative AI through an interactive and engaging experience unlike any other.
      </Typography>
      
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.subHeading}>Learning Aspects</TableCell>
              <TableCell align="center" className={classes.subHeading}>Conventional Methods</TableCell>
              <TableCell align="center" className={classes.subHeading}>Interactive Experience</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => (
              <TableRow key={index} className={classes.row}>
                <TableCell align="center">{category.title}</TableCell>
                <TableCell align="center">{category.traditional}</TableCell>
                <TableCell align="center">{category.interactive}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}