
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import LearningGoals from "../../../components/LearningGoals";
import LearningPath from "../../../components/learnPath";
import learningPathData from '../../../learningPaths/lrpath.json';
import LearningPath2 from "../../../components/learnPath2";
import DotProduct from "!babel-loader!@mdx-js/loader!./DotProduct.mdx";
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const ImageCard = makeShortcode("ImageCard");
const MarkerDialog = makeShortcode("MarkerDialog");
const VideoMediaCard = makeShortcode("VideoMediaCard");
const layoutProps = {
  ggbParameters2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Logistic Regression Leanring path`}</h1>
    <Header mdxType="Header"> Learning goals</Header>
    <br />
    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/GENAI/PICS/Logistic_Highway.png" mdxType="ImageCard" />
    
                 <MarkerDialog left="35%" top="13%" mleft="50%" mtop="25%" text="T" type="video" mdxType="MarkerDialog">
                        <div>
                       <br />
                            <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide2.m4v" title="Understanding GAN Models" description="" link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/T_GenLoss_2.png" mdxType="VideoMediaCard" />
                        </div>
                </MarkerDialog>
         <MarkerDialog left="35%" top="53%" mleft="50%" mtop="25%" text="B" type="video" mdxType="MarkerDialog">
                        <div>
                       <br />
                          <DotProduct mdxType="DotProduct" />
                        </div>
                </MarkerDialog>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;