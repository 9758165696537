
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import LoadGGBLayoutHtml from "../../components/LoadGGBLayoutHtml";
import TouchAppIcon from '@mui/icons-material/TouchApp';
export const data = [{
  title: "1.Gamma for entire duration of the long call option and a range of stock prices",
  id: 1,
  script: "SetViewDirection[Vector[(90;85°;0°)]]",
  text: "Stock prices are on the x-axis, Time period from start to expiry is on y-axis. z-axis represents Gamma for corresponding x and y."
}, {
  title: "2.Gamma for near-the-money options as time is approaching expiry",
  id: 2,
  script: 'Execute({"SetValue(t,0.02)", "SetValue(N,(5,0.02))"})',
  text: "For options near the money,close to expiration the Delta can have large, sudden moves between close to 0 (OTM) and close to 1 or -1 (ITM)"
}, {
  title: "3.At expiry, What is Gamma Option  is OTM or deep ITM?",
  id: 3,
  script: 'Execute({"SetValue(t,0.02)", "SetValue(N,(2,0.02))"})',
  text: "Gamma goes to 0 when Delta has reached 0 (out-of-the-money) or +1 / -1 (in-the-money) at expiration."
}, {
  title: "4.What happens when implied volatility is high?",
  id: 4,
  script: "SetValue(sgm,1.8)",
  text: "Does Gamma surface flatten or rise?"
}, {
  title: "5.What happens when implied volatility is low?",
  id: 5,
  script: "SetValue(sgm,1)",
  text: "Observe the Gamma surface"
}];
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: "100%",
  borderColor: "#FFFFFF",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '90',
  width: '250',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/videos/gamma_video_drive.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => {
  const handleClick = value => {
    alert(value);
  };
  return <div style={{
    padding: 20,
    backgroundColor: '#1e1e1e',
    maxWidth: 150,
    minHeight: 200
  }} onClick={() => handleClick('test')}>
   THIS IS STEP ONE
  </div>;
};
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  minWidth: 300
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const StyledBox = makeShortcode("StyledBox");
const layoutProps = {
  data,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">












    <h1>{`What is Gamma?`}</h1>
    <p>{`Gamma represents the rate of change between an option's Delta and the underlying asset's price. `}</p>
    <p>{`Higher Gamma values indicate that the Delta could change dramatically with even very small price changes in the underlying stock or fund.`}</p>
    <>
      <div style={{
        width: "50%",
        textAlign: "center",
        margin: "auto"
      }}>
    <ReactPlayer controls="true" width="100%" height="100%" url={"https://storage.googleapis.com/chapters-ifm/testfolder/videos/delta_gamma_00.mp4"} mdxType="ReactPlayer" />
    <StyledBox width="30%" mdxType="StyledBox">{"A brief intro  video for First Interactive App "}</StyledBox>
      </div>
    </>
    <>
      <h1>{`How to determine Delta and Gamma for a given range of stock prices and option times?`}</h1>
      <p>{`In 3 Dimensions, you can find how Delta and Gamma profiles change w.r.t changes in time, moneyness of a Call option.
You could click on each cardr to see corresponding changes in the App`}</p>
    </>
    <>
      <LoadGGBLayoutHtml data={data} geogebraJSON={"https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/testfolder/d3gamma/gamma3d_delta3d.json"} buttonTitle="Click here to load Call Options Intro App" mdxType="LoadGGBLayoutHtml" />
    </>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;