
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "100%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  width: "100vw",
  height: '100%',
  enableShiftDragZoom: false,
  showToolBar: false,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "What is the capital of France?",
  // image: "https://example.com/france.jpg",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question2 = {
  text: "What is the capital of France?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question3 = {
  text: "What is the capital of France?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const topics = [{
  title: 'Topic 1',
  component: () => <div>
        <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
          position: 'relative',
          width: '100%',
          height: '50%'
        }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/brownian_motion_code_2.png" style={{
            width: '100%',
            height: 'auto'
          }} />
              <LandmarkButton id={1} x={4} y={25} text="r s_t dt represents drift of the asset price, which reflects the overall direction of the price movement." mdxType="LandmarkButton" />
              <LandmarkButton id={2} x={35} y={25} text="product of sigma S_t and small change in brownian process dB_t, represents the random diffusion of the asset price, which reflects the random fluctuations in the price due to various factors such as market demand, supply, and investor sentiment" mdxType="LandmarkButton" />
            </div>
          </>
        </ImageAnnotation>
      </div>
}, {
  title: "Interactive App",
  component: () => <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%'
  }}>
      <BlueLink url="https://options.21ifm.com/sofr" text="sofr" mdxType="BlueLink" />
      <div style={{
      left: "20%",
      top: "60%"
    }}>
      <SimpleDialog buttonPosition={{
        left: "40%",
        top: "40%"
      }} title={'My silly title'} mdxType="SimpleDialog">
            <Container mdxType="Container">
              <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/landscape/strategy_diag_8.json" ggbParameters={ggbParameters2} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[{
            component: <Box2 mdxType="Box2" />,
            button: "longcall"
          }]} mdxType="GeogebraDraw" /> 
            </Container>
      </SimpleDialog>
      <ButtonMailto label="Email this" mailto="mailto:office@21ifm.com?subject=Note on SOFR chapter" top={"91%"} left={"88%"} mdxType="ButtonMailto" />
      </div>
  </div>
}, {
  title: 'Topic India. what is this is a long button. how does it look',
  component: () => <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%'
  }}>
        <Box1 mdxType="Box1" /> 
  </div>
}, {
  title: 'Topic India. what is this is a long button. how does it look',
  component: () => <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: '20px'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {/* <Quiz question={question2}/> */}
          </Container>
  </div>
}, {
  title: 'Video',
  component: () => <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: '20px'
  }}>
          <Container mdxType="Container">
            <Quiz question={question3} mdxType="Quiz" />
             {/* <Quiz question={question2}/> */}
          </Container>
  </div>
}, {
  title: 'Image',
  component: () => <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: '20px'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
             {/* <Quiz question={question2}/> */}
          </Container>
  </div>
}, {
  title: 'MyAudio',
  component: () => <div style={{
    overflowY: 'scroll',
    maxHeight: '75vh'
  }}>
        <Container mdxType="Container"> 
      <MyAudioPlayer backgroundImageUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/sofr_1.png" buttonPositions={[{
        top: 50,
        left: 50,
        audioUrl: 'https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/audio2.mp3'
      }, {
        top: 35,
        left: 75,
        audioUrl: 'https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/audio1.mp3'
      }]} mdxType="MyAudioPlayer" />
    </Container>
  </div>
}

// Add more topics here
];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Container = makeShortcode("Container");
const MyCarousel = makeShortcode("MyCarousel");
const Marker = makeShortcode("Marker");
const ImageAnnotation = makeShortcode("ImageAnnotation");
const LandmarkButton = makeShortcode("LandmarkButton");
const BlueLink = makeShortcode("BlueLink");
const SimpleDialog = makeShortcode("SimpleDialog");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Box2 = makeShortcode("Box2");
const ButtonMailto = makeShortcode("ButtonMailto");
const Quiz = makeShortcode("Quiz");
const MyAudioPlayer = makeShortcode("MyAudioPlayer");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <h1>{`SOFR`}</h1>




    <Container mdxType="Container">
  <div>
   <MyCarousel topics={topics} mdxType="MyCarousel" />
  </div>
    </Container>
 <div style={{
      position: 'absolute',
      left: "25%",
      top: '12%'
    }}>
   <Marker left="25%" top="5%" mleft="50%" mtop="25%" text="1" mdxType="Marker">
          <Box1 mdxType="Box1" /> 
        </Marker>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;