import React from "react";

import * as posts from "./posts";

export default () => [
  {
    name: "BRIEF INTRODUCTION TO FORWARDS AND OPTIONS CONCEPT",
    id: "into",
    Component: posts.IntroConcepts,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/videos/WhatareOptions2.mp4",
    description:
      "Here is a simplified description of derivatives. In our story, we introduce a trader and a farmer to learn the purposes of using options.",
    active: 1,
    category: "intro"
  },
  {
    name: "BRIEF INTRODUCTION TO FORWARDS AND OPTIONS CONCEPT",
    id: "sofr",
    Component: posts.SOFR,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/videos/WhatareOptions2.mp4",
    description:
      "Here is a simplified description of derivatives. In our story, we introduce a trader and a farmer to learn the purposes of using options.",
    active: 1,
    category: "intro"
  },

  {
    name: "LINEAR FUNCTION",
    id: "linearfunction",
    Component: posts.LinearFunction,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/linear_func.mp4",
    description:
      "It is important to learn about concept of the gradient to understand Option price sensitivities",
    active: 1,
    category: "math"
  },
  {
    name: "QUADRATIC FUNCTION",
    id: "quadraticfunction",
    Component: posts.QuadraticFunction,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/quad_func.mp4",
    description:
      "Delta hedging and Gamma Scalping need understanding of Quadratic function.",
    active: 1,
    category: "math"
  },
  {
    name: "NORMAL AND LOGNORMAL RELATIONSHIP",

    id: "lognormal+normal",
    Component: posts.Normal_LogNormal_relationship,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/log_normal.mp4",
    description:
      "Importance of using lognormal distributions in Finance.",
    active: 1,
    category: "math"
  },
  {
    name: "EXPONENTAIL FUNCTIONS",
    id: "exponential",
    Component: posts.Exponential_Logarithm_basics,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/exponential_functions.mp4",
    description:
      "Natural log, Exponential and linear functions. Special number e",
    active: 0,
    category: "math"
  },
  {
    name: "BINOMIAL DISTRIBUTION",
    id: "binomial",
    Component: posts.GaltonBoard_Binomial,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/binomial_func.mp4",
    description:
      "Delta hedging and Gamma Scalping need understanding of Quadratic function.",
    active: 1,
    category: "math"
  },
  {
    name: "CUMULATIVE DISTRIBUTION ",
    id: "cumulative_stock",
    Component: posts.Cumulative_Dist_Stock,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/density_functions.mp4",
    description:
      "Delta hedging and Gamma Scalping need understanding of Quadratic function.",
    active: 1,
    category: "math"
  },
  {
    name: "BLACK SCHOLES FRAMEWORK ",
    id: "log_normal",
    Component: posts.LOG_NORMAL_DIST,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/math/desktop_nd1.png",
    video :"",
    description:
      "Log Normal distribution of stock prices. Normal distribution for returns.",
    active: 1,
    category: "pricing"
  },


  {
    name: "CALLS. PRICE SIMULATION. INTRINSIC VALUE",
    id: "SIMULATE SCENARIOS CALL OPTION PAYOFFS",
    Component: posts.Calls_basic,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/long_call.png",
    video: "",
    description:
      "Explore how stock price, interest rates, implied volatility, and strike price impact call option payoffs. Take the quiz.",
    active: 1,
    category: "basics"
  },
  {
    name: "PUTS PAYOFF",
    id: "puts_1",
    Component: posts.Puts,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/puts_long.png",
      video: "",
    description:
    "Learn how time value, intrinsic value, and extrinsic value affect Put option payoffs.  Interactive quizes to reinforce core  ",
    active: 1,
    category: "basics"
  },
  {
    name: "PUTS CALL PARITY",
    id: "put_call_parity",
    Component: posts.Put_Call_Parity,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/put_call_parity.png",
      video: "",
    description:
    "Put–call parity refers to the value of european call and put options with the same strike price and the same expiration date. ",
    active: 1,
    category: "basics"
  },
  {
    name: "OPTIONS CHAIN",
    id: "put_calloptions_chain_parity",
    Component: posts.OptionsChain,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/options_chain.png",
      video: "",
    description:
    "Options chain. Extrinsic value, IMoneyness, Option Open Interest. Implied Volatility. Call and Puts ",
    active: 1,
    category: "basics"
  },

  {
    name: "TAYLOR POLYNOMIAL APPROXIMATION FOR DELTAS",
    id: "taylordelta",
    Component: posts.Taylor_delta_Gamma,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/taylor_series.png",
    description:
      "Find average Gamma, Gamma PNL for Long call. Measure Gamma for a Delta Hedged position. Enjoy an interactive quiz that you'll never forget",
    active: 1,
    category: "greeks_intro"
  },

  {
    name: "CALL DELTA & NEUTRAL PORTFOLIO",
    id: "delta_neutral_port",
    Component: posts.LCDelta2D,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/laptop_delta.png",
    description:
      "Delta ITM, OTM and OTM for Long call. Delta Neutral portfolio. Multiple interpretations of Delta",
    active: 1,
    category: "greeks_delta"
  },
  {
    name: "DELTA SENSITIVITIES",
    id: "delta_sensitivities",
    Component: posts.LCDelta2DSensitivity,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/wall_sensitivities.png",
    description:
      "Delta ensitivity to Time to Maturity and Volatility for ITM/OTM and ATM Call Options",
    active: 1,
    category: "greeks_delta"
  },

  
  {
    name: "PUT DELTA & NEUTRAL PORTFOLIO",
    id: "delta_puts",
    Component: posts.LCDelta2DSensitivity,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/greeks/hoddie_puts_delta2.png",
    description:
      "Delta ITM, OTM and OTM for Long Put. Delta Neutral portfolio. Multiple interpretations of Delta",
    active: 1,
    category: "greeks_delta"
  },
  {
    name: "PLOT DELTA AND GAMMA 3D SURFACE(TIME, STOCK,VOLATILITY)",
    id: "lcGamma3d",
    Component: posts.LCGamma3D,
    image:
    "",
   video : "https://storage.googleapis.com/chapters-ifm/testfolder/videos/gamma_intro.mp4",
    description:
      "Examine Gamma sensitivity to implied volatilities, time, and interest rates. Analyze the relationship between Gamma and moneyness of options.",
    active: 1,
    category: "greeks_gamma"
  }, 
  {
    name: "Gamma Squeeze Explainer",
    id: "gamma_squeeze",
    Component: posts.GammaSqueeze,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/gamma_sqeeze.png",
    video: "",
    description:
      "What causes Gamma Sqeeze. Explains basic incentives for the Market Maker to delta and gamma profiles over lifetime of option.",
    active: 1,
    category: "greeks_gamma"
  },
  {
    name: "Theta - Sensitivity to Time",
    id: "lcTheta2d",
    Component: posts.LCTheta2D,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/desktop_theta.png",
   video : "",
    description:
      "Intuition behind Time decay. Illustration of time decay for ATM Call Option. Influence of stock price, volatility and interest rates",
    active: 1,
    category: "greeks_theta"
  }, 
  {
  
    name: "PLOT 3D THETA SURFACE",
    id: "lcTheta3d",
    Component: posts.LCTheta3D,
    image:
    "",
   video : "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/laptop_d_theta.mp4",
    description:
      "Examine Theta sensitivity to implied volatilities, time, and interest rates. Analyze the relationship between Theta and moneyness of options.",
    active: 1,
    category: "greeks_theta"
  },
  {
    name: "PUTS. BUYER AND SELLER",
    id: "puts_2",
    Component: posts.Puts_basic_2,
    image:
      "",
    video : "https://storage.googleapis.com/chapters-ifm/testfolder/videos/PUTS_CASH_SECURED.mp4",
    description:
      "Understand how the time value, intrinsic value, and extrinsic value influence the buyer and seller of a put option.",
    active: 0,
    category: "basics"
  }, 
 
  {
    name: "ALL GREEKS FOR LONG AND SHORT CALL",
    id: "all_greeks_call",
    Component: posts.Greeks_Call,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/deskitop_call.png",
    description:
      "Delta, Gamma, Vega, Theta for Long and Short Call positions is illustrated. Scenarios for ITM, OTM and ATM options",
    active: 1,
    category: "strategy_bullish"
  },
  {
    name: "CALLS BUYER AND SELLER INCENTIVES/PAYOFFS",
    id: "calls_2",
    Component: posts.Calls_basic_2,
    video: "",
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/desktop_call_buyer-seller.png",
    description:
      "Investigate how intrinsic, extrinsic, and time value influence payoff both for buyers and sellers of calls.",
    active: 1,
    category: "strategy_bullish"
  },
  {
    name: "BULLISH DEBIT SPREAD",
    id: "bullish_debit_spread",
    Component: posts.BullishLongCall,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/DEBITCALLSPREAD_B.png",
    description:
      "Delt/Long Call Spread connstituents. Breakeven points. Scenarios for ITM, OTM and ATM options",
    active: 1,
    category: "strategy_bullish"
  },
 
  {
    name: "BEARISH OUTLOOK: DEBIT PUT SPREAD",
    id: "debit_puts_spread",
    Component: posts.Bearish_Put_Spread,
    image:
      "",
      video: "https://storage.googleapis.com/chapters-ifm/testfolder/math/bearish_long_put.mp4",
    description:
    "Learn about enterign trade, calculating profits and losses and volatility impact on this bearish outlook trade   ",
    active: 1,
    category: "strategy_bearish"
  },
  
  {
    name: "BUYING STRADDLE",
    id: "straddles",
    Component: posts.Volatility_Straddle,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/laptop_bullish_vol_debit_straddle.png",
    video: "",
    description:
      "Gamma, Delta, Theta and Vega for Long and Short Stradle positions",
    active: 1,
    category: "strategy_vol"
  },
  {
    name: "PUT RATIO BACKSPREAD",
    id: "put_ratio_backspread",
    Component: posts.Bearish_Put_Ratio,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/laptop_put_ratio_back_spread.png",
    video: "",
    description:
      "Bearish, Expecting a breakdown in stock. Reserved for experienced speculators",
    active: 1,
    category: "strategy_vol"
  },

  {
    name: "TRADITIONAL COVERED CALLS + POOR MAN'S COVERED CALL",
    id: "cCalls",
    Component: posts.CoveredCalls,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/calls/covered_call.png",
    video: "",
    description:
      "Learn about the benefits of a Covered call position. Explore the benefits from both the Seller's and Buyer's perspective. Take the quiz",
    active: 1,
    category: "strategy_hedging"
  },

  {
    name: "WHEEL STRATEGY TO COLLECT PREMIUMS",
    id: "wStrategy",
    Component: posts.WheelStrategy,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/cash_secured_puts_wheel.png",
    video: "",
    description:
      "Popular Wheel Strategy begins with a cash secured Puts, that needs adjustments when stock is assigned. All three stages are illustrated",
    active: 1,
    category: "strategy_hedging"
  },

  {
    name: "SHORT BUTTERFLY",
    id: "short_butterfly",
    Component: posts.Volatility_Butterfly,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/short_butterfly.png",
    video: "",
    description:
      "Gamma, Delta, Theta and Vega for Long and Short Butterfly positions. The maximum profit is the amount of premium collected by writing the options.",
    active: 1,
    category: "strategy_sideways"
  },
  {
    name: "SHORT CONDORS",
    id: "short_condor",
    Component: posts.Volatility_Condor,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/iron_condor.png",
    video: "",
    description:
      "Gamma, Delta, Theta and Vega for Long and Short Condor positions.. The maximum profit is the amount of premium collected by writing the options.",
    active: 1,
    category: "strategy_sideways"
  },
  {
    name: "SHORT STRADDLE",
    id: "short_straddle",
    Component: posts.Neutral_Straddle,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/short_straddle.png",
    video: "",
    description:
      "Expecting minimal price movement.The maximum profit is the amount of premium collected by writing the options.",
    active: 1,
    category: "strategy_sideways"
  },
  {
    name: "LONG PUT CALENDAR SPREAD",
    id: "put_long_calendar_spread",
    Component: posts.Bearish_Long_Put_Calendar,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/calendar.gif",
    video: "",
    description:
      "Expecting miminal price movement. Reserved for experienced speculators",
    active: 1,
    category: "strategy_sideways"
  },
  {
    name: "RISK MANAGEMENT",
    id: "pinrisk",
    Component: posts.Lessons_1,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/neiderhoffer.png",
    video: "",
    description:
      "Pin Risk, Early Exercise, Margin Calls, Dividend risk, PDT flag  are explained with examples.",
    active: 1,
    category: "risk_management"
  },


  {
    name: "Niederhoffer",
    id: "Niederhoffer",
    Component: posts.Lessons_1,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/neiderhoffer.png",
    video: "",
    description:
      "Niederhoffer was a A Hedge Fund Manager who had been selling naked puts.",
    active: 1,
    category: "online"
  },
  {
    name: "Bayes Therom",
    id: "bayestheorm",
    Component: posts.BayesTheorm,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/neiderhoffer.png",
    video: "",
    description:
      "Niederhoffer was a A Hedge Fund Manager who had been selling naked puts.",
    active: 1,
    category: "online"
  },
  {
    name: "Optimization",
    id: "optimization",
    Component: posts.OptimizationBox,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/neiderhoffer.png",
    video: "",
    description:
      "Niederhoffer was a A Hedge Fund Manager who had been selling naked puts.",
    active: 1,
    category: "online"
  },
 


 
];
function Default() {
  const text = "Hello World . I am Default APP";

  return (
    <div className="App">
      <p> {text} </p>
    </div>
  );
}

function App1() {
  const text = "Hello World . I am inside Pages / App1";

  return (
    <div className="App">
      <p> {text} </p>
    </div>
  );
}
