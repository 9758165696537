
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import SimpleAccordion from "../../components/SimpleAccordian";
import IfPaidUser from "../../components/IfPaidUser";
import useFetchData from "../../components/UseFetchData";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Papa from "papaparse";
import ReactPlayerComp from "../../components/ReactPlayerComp";
import GRCard from "../../components/GRCard";
export const data = [{
  "question": "what is Forward contract",
  "answer": "It is an agreement to purchase a product in future"
}, {
  "question": "what is Options contract",
  "answer": "It is an agreement to purchase a product in future"
}];
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/videos/Theta_video_drive.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayerComp url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayerComp" />
  </div>;
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: 950,
  borderColor: "#FFFFFF",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const PokemonData = () => {
  const [pokemon, setPokemon] = React.useState(null);
  const [{
    data,
    isLoading,
    isError
  }] = useFetchData({
    initialFetchUrl: 'https://yacdn.org/proxy/https://storage.googleapis.com/chapters-ifm/LinearAlgebra/finviz_colab.json'
  });
  if (!isLoading) {
    var ggbBase64 = data;
    debugger;
    console.log(data);
  }
  if (!pokemon) {
    return <h1>Fetching pokemon...</h1>;
  }
  return <h1>Fetching pokemon...</h1>;
};

const layoutProps = {
  data,
Box1,
Box2,
Text14,
ggbParameters,
PokemonData
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`What is a Log Normal Distribution?`}</h1>

    <SimpleAccordion data={data} mdxType="SimpleAccordion" />
    <p>{`Why are asset prices modelled with log normal distributions?`}</p>
    <GeogebraDraw jsonUrl="https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/testfolder/math/black_scholes_log_normal_lkg.json" ggbParameters={ggbParameters} appId="appId1" email="pavanmirla@gmail.com" data={[{
      component: <Text14 mdxType="Text14" />,
      button: "text14"
    }]} mdxType="GeogebraDraw" /> 



    <h1>{`What is the intuitition behind d1, N(d1), d2 and N(d2)?`}</h1>
    <p>{`Why is it important to understand them to derive option prices `}</p>
    <GeogebraDraw jsonUrl="https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/testfolder/math/black_scholes_log_normal.json" ggbParameters={ggbParameters} appId="appId2" email="pavanmirla@gmail.com" data={[{
      component: <Text14 mdxType="Text14" />,
      button: "text14"
    }]} mdxType="GeogebraDraw" /> 


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;