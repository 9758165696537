/**
  This CodeSandbox has been automatically generated using
  `codesandboxer`. If you're curious how that happened, you can
  check out our docs here: https://github.com/codesandbox/codesandboxer

  If you experience any struggles with this sandbox, please raise an issue
  on github. :)
*/
import React from "react";
import ReactDOM from "react-dom";
import MathApp from "./mathApp";
import BlogsApp from "./blogsApp";
import AuthProvider from "./provider/AuthProvider";
import Howler from 'react-howler';


import PaymentProvider from './components/paymentProvider';



import {MDXProvider} from '@mdx-js/react'
// import CodeBlock from './markdown-styles/CodeBlock'
import Header1 from './markdown-styles/Header1'
import Header3 from './markdown-styles/Header3'
import Header2 from './markdown-styles/Header2'
import Paragraph from './markdown-styles/Paragraph'
import Link_ from './markdown-styles/Link_'
import Thing from './markdown-styles/Sections'
import { BlocksProvider } from 'mdx-blocks'
import AppHeader from "./components/AppHeader";
import MyCarousel from "./components/MyCarousel";
import ButtonMailto from "./components/ButtonMailto.js";
import SimpleDialog from "./components/SimpleDialog.js";
import Quiz from "./components/Quiz.js";
import LandmarkButton from './components/LandmarkButton';
import MyAudioPlayer from './components/layouts/AudioPlayer';
import ReactPlayer from 'react-player'
import VideoPlayer from './components/VideoPlayer';
import Marker from './components/ImageMarkers';
import MarkerDialog from './components/ImageMarkersFullScreen';

import ImageAnnotation from "./components/ImageAnnotation";
import Container from "./components/layouts/Container";
import GeogebraDraw from "./components/GeogebraDraw";
import ImageCard from './components/ImageCard'
import BlinkingButton from './components/BlinkingButton';
import SimpleAccordion from './components/SimpleAccordian'
import ImgMediaCard from "./components/ImgMediaCard";
import VideoMediaCard from "./components/VideoMediaCard";
import Header from "./components/Header";
import HowlerRead from "./components/HowlerRead";
import LearningGoals from "./components/LearningGoals";
import TitleSection from "./components/TitleSection";
import SubtitleSection from "./components/SubtitleSection";
import SomeComponent from "./components/SomeComponent";


import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';


const components = {
    h1:  Header1,
    h2:  Header2,
    h3:  Header3,
    p:   Paragraph,
    a:   Link_,
    Howler,
    MarkerDialog,
    SomeComponent,
    TitleSection,
    SubtitleSection,
    Header,
    HowlerRead,
    ReactPlayer,
    // pre: CodeBlock,
    thing: Thing,
    MyCarousel,
    ButtonMailto,
    SimpleDialog,
    Quiz,
    LandmarkButton,
    MyAudioPlayer,
    ReactPlayer,
    VideoPlayer,
    ImageCard,
    Marker,
    ImageAnnotation,
    Container,
    GeogebraDraw,
    AppHeader,
    BlinkingButton,
    SimpleAccordion,
    ImgMediaCard,
    VideoMediaCard,
    LearningGoals,
    wrapper: ({ ...props}) => (
      <div style={{padding: '50px', backgroundColor: 'cream'}}>
        <main {...props} />
      </div>
    )

  //  code: props => <pre style={{color: 'tomato'}} {...props} />
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  spacing: 4
});




ReactDOM.render(
  <ThemeProvider theme={darkTheme}>
    <MDXProvider components={components}>
      <AuthProvider>
        <PaymentProvider>
          <BlogsApp/>
        </PaymentProvider>
      </AuthProvider>
    </MDXProvider>
  </ThemeProvider>,
  document.getElementById('root')
);