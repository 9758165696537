
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1700,
  height: 950,
  borderColor: "#FFFFFF",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};

const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
DefaultBox,
Text4,
componetArray,
indexArray
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <h1>{`Short Straddle`}</h1>
    <p>{`Explore influence of time passing and volatility on the straddle Payoff profile`}</p>
  <GeogebraDraw jsonUrl="https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/testfolder/strategies/volatality_straddle_short.json" ggbParameters={ggbParameters} appId="id2" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    <p>{`On January 16, 1995, Leeson placed a short straddle on the Singapore and Tokyo stock exchanges, guessing that the exchange would remain stable overnight, neither going up nor down by a significant margin. Normally, this would have been a conservative position, especially for Leeson. But on January 17, 1995, an earthquake with an epicenter in Kobe, Japan caused a sharp drop in the Asian markets.
Faced with huge losses, Leeson attempted to offset the losses with a series of increasingly risky trades that were based on the rate of recovery of the Nikkei. Leeson ended up fleeing Singapore on February 23, 1995. In the end, his losses accounted for £827 million (or $1.4 billion), twice Barings's available trading capital.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.reddit.com/r/wallstreetbets/comments/gtbv8e/the_father_and_founder_of_wsb_nicholas_leeson/"
      }}>{`https://www.reddit.com/r/wallstreetbets/comments/gtbv8e/the_father_and_founder_of_wsb_nicholas_leeson/`}</a></p>
    <p>{`Euan Sinclar Book:`}</p>
    <p>{`The trader and risk manager, Nick Leeson, lost £827 million in 1995 by tak- ing unauthorized positions in Nikkei and Japanese government bond fu- tures and option straddles. Originally, he was trying to make back money lost due to other errors, but as he lost money he added to his losing posi- tions.`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/trS85RIaqOQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <img src="https://storage.googleapis.com/chapters-ifm/testfolder/longform/call_volatility_vol.gif" alt="Girl in a jacket" width={"100%"} height={"100%"} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;