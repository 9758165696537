import styled from 'styled-components'

const Paragraph = styled.p`
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
font-family: 'Roboto', sans-serif;
    color: #f3e5f5;
    margin: auto;
    text-align: left;
    line-height:1.6;
`

export default Paragraph; 