
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
export const goals = [{
  id: 'LG01',
  title: 'Vector Norms',
  description: "Understand different types of vector norms like L1, L2, and Infinity, and their significance in regularization techniques."
}, {
  id: 'LG02',
  title: 'Vector Spaces and Subspaces',
  description: "Gain insight into the concept of vector spaces and subspaces and how vectors operate within them."
}, {
  id: 'LG03',
  title: 'Orthogonal Projections',
  description: "Learn about orthogonal projections and their role in dimensionality reduction."
}, {
  id: 'LG04',
  title: 'Linear Independence and Dependence',
  description: "Understand linear independence and dependence, and their implications for data features and redundancy."
}, {
  id: 'LG05',
  title: 'Matrix-Vector Multiplication',
  description: "Master the concept of matrix-vector multiplication and its role in neural network operations."
}, {
  id: 'LG06',
  title: 'Eigenvalues and Eigenvectors',
  description: "Learn about eigenvalues and eigenvectors and their importance in dimensionality reduction techniques."
}, {
  id: 'LG07',
  title: 'Understanding Angles Between Vectors',
  description: "Grasp the concept of angles between vectors and its importance in calculating similarity measures."
}];
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1950,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const SomeComponent = makeShortcode("SomeComponent");
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  goals,
Box2,
ggbParameters2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Vector Arithmetic`}</h1>
    <Header mdxType="Header"> Learning goals</Header>

    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />
    <h1>{`Dot Product`}</h1>
 <div>
      <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/dot_product_4.json" ggbParameters={ggbParameters2} appId="perceptron_algo" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
  </div>



    <SomeComponent mdxType="SomeComponent" />
    <h1>{`Eigen Values and Eigen Vectors`}</h1>
 <div>
      <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/Eigen_2023_1.json" ggbParameters={ggbParameters2} appId="eigen_values" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
  </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;