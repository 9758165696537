
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import InteractiveOptionPlot from "../../../components/options/InteractiveOptionPlot";

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Header mdxType="Header">Welcome to the Pre-Lesson Warm-up!</Header>
    <h1>{`D3 Call Option Plot with Sliders`}</h1>
    <InteractiveOptionPlot mdxType="InteractiveOptionPlot" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;