// add useContext
import React, { useContext } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import { withRouter } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import {
  useHistory
} from "react-router-dom";
import { alertClasses } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const ResetPasswordFireBase = ({props}) => {
  const classes = useStyles();
  const history = useHistory()
  const { handleSignup, inputs, setInputs, errors } = useContext(firebaseAuth);
  alert(JSON.stringify({props}))
  const delay = ms => new Promise(res => setTimeout(res, ms));
  var accountEmail
  props.auth.verifyPasswordResetCode(props.auth, props.actionCode).then((email) => {
     accountEmail = email;
     alert(accountEmail)

  }).catch((error) => {
    // Invalid or expired action code. Ask user to try to reset the password
    // again.
    accountEmail= null
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit");
    //wait to signup
    await handleSignup();
    await delay(2000);
    if(window.localStorage.getItem('error') !== "noerror")
    {
      history.push("/")
    }
    else {
      history.push("/signin")
      // alert(window.localStorage.getItem('error'))
    }
    

    //push home
    // props.history.push("/");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password for {accountEmail}
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
              <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
               Minimum password length is 6
             
            </Grid> 
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/signin" to="/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        {/* <Copyright /> */}
      </Box>
      <Box mt={5}>
            {errors.length > 0
             ? errors.map((error) => (
              <>
                <p style={{ color: "red" }}>{error}</p>
                {/* <Signup /> */}
              </>
            ))
          :null}
            </Box>
    </Container>
  );
};

export default withRouter(ResetPasswordFireBase);
