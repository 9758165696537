
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import LearningGoals from "../../../components/LearningGoals";
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const goals = [{
  id: 'DAF01',
  title: 'Understanding Derivatives of Activation Functions',
  description: "Grasp the concept and significance of the derivatives of activation functions in neural networks, and their pivotal role in backpropagation."
}, {
  id: 'DAF02',
  title: 'Sigmoid Function Derivative',
  description: "Understand the derivative of the sigmoid function, explore its properties, and see how it impacts the learning in neural networks."
}, {
  id: 'DAF03',
  title: 'Tanh Function Derivative',
  description: "Learn about the derivative of the tanh function, its properties, and how it influences the weight updates during training."
}, {
  id: 'DAF04',
  title: 'ReLU Function Derivative',
  description: "Get familiar with the derivative of the Rectified Linear Unit (ReLU) function, its behavior, and its implications for network learning."
}, {
  id: 'DAF05',
  title: 'Softmax Function Derivative',
  description: "Investigate the derivative of the softmax function and understand its effect on the propagation of errors in multi-class classification problems."
}, {
  id: 'DAF06',
  title: '2D and 3D Visualization',
  description: "Experience the power of visual learning by observing the derivatives of activation functions in 2D and 3D, facilitating a deeper understanding of their impact on learning."
}];
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const SomeComponent = makeShortcode("SomeComponent");
const ImageCard = makeShortcode("ImageCard");
const Marker = makeShortcode("Marker");
const Howler = makeShortcode("Howler");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const layoutProps = {
  ggbParameters2,
goals,
Box2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Derivatives of Activation Functions`}</h1>
    <Header mdxType="Header"> Learning goals</Header>
    <SomeComponent mdxType="SomeComponent" />

    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />


    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/Sigmoid_Derivative.png" mdxType="ImageCard" />
                  <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
                 <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
          
    </div>
    <h1>{`Sigmoid Function Interactive`}</h1>
    <div>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/Sigmoid_Derivative.json" ggbParameters={ggbParameters2} appId="xor-sigmoid_der" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
    </div>
    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/sigmoid-activity.png" mdxType="ImageCard" />
                  <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
                 <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
          
    </div>
    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/Activity_2.png" mdxType="ImageCard" />
                  <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
                 <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
          
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;