import React, { useRef, useEffect, useImperativeHandle } from 'react';
import Blockly from 'blockly';
import DarkTheme from '@blockly/theme-dark';
import { TroubleshootOutlined } from '@mui/icons-material';


Blockly.Blocks['animal'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Animal")
          .appendField(new Blockly.FieldDropdown([
            ['cat', 'cat'], 
            ['dog', 'dog'],
            // ... other animals ...
          ]), "ANIMAL_NAME")
          .appendField("Category")
          .appendField(new Blockly.FieldDropdown([
            ['mammal', 'mammal'], 
            ['bird', 'bird'],
            // ... other categories ...
          ]), "CATEGORY")
          .appendField("Lifespan")
          .appendField(new Blockly.FieldNumber(0), "LIFESPAN")
          .appendField("Weight")
          .appendField(new Blockly.FieldNumber(0), "WEIGHT")
          .appendField("Is Pet?")
          .appendField(new Blockly.FieldCheckbox("FALSE"), "IS_COMMON_PET");
      this.setPreviousStatement(true, null); // Allows connection above
      this.setNextStatement(true, null); // Allows connection below
      this.setColour(160);
      this.setTooltip("Represents an animal with its category and features.");
    }
  };
  

  Blockly.Blocks['dataset_e'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(" Dataset");
      this.appendStatementInput("ANIMALS") // Allows vertical stacking of animal blocks
          .setCheck("animal"); // Check to allow only animal blocks
      this.setColour(160);
      this.setNextStatement(true, null); // Allows connection below
      this.setTooltip("Represents a dataset of animals.");
    }
  };


  Blockly.Blocks['data_prep'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Data Preparation");
      this.appendStatementInput("LABEL_ENCODER")
          .setCheck("Category")
          .appendField("Label Encoder");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Prepares data for training, including encoding categorical labels.");
    }
  };

  
  Blockly.Blocks['category'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Categories")
      this.setPreviousStatement(true, "Category");
      this.setNextStatement(true, "Category");
      this.setColour(200);
      this.setTooltip("Categories. Ex: mammal/Bird/Repriles");
    }
  };


  Blockly.Blocks['features'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Features")
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(200);
      this.setTooltip("Data Features.");
    }
  };


  
  
  Blockly.Blocks['embedding_dimension'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Embedding Dimension")
          .appendField(new Blockly.FieldNumber(3), "EMBEDDING_DIM");
      this.setColour(220);
      this.setTooltip("Defines the dimension of the embedding.");
    }
  };

  


Blockly.Blocks['input_data_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Original Data");
    this.setNextStatement(true, null);
    this.setPreviousStatement(true, null);
    this.setColour(20);
    this.setTooltip("This block represents the original data to the model.");
  } 
};
Blockly.Blocks['prediction_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Predictions");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip("This block represents the predicted label by the model.");
  }
};


Blockly.Blocks['model_i'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Model");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.appendStatementInput("TDATA")
           .appendField("Training Data");
      this.setTooltip("This is model Instance");
    }
  };

  

Blockly.Blocks['ground_truth_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Expected Data");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(10);
    this.setTooltip("This block represents the expected data that the model should generate.");
  }
};

Blockly.Blocks['n_labels'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Numeric categories");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(10);
      this.setTooltip("This block represents the expected data that the model should generate.");
    }
  };


  Blockly.Blocks['weights'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Network Update");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(10);
      this.setTooltip("Gradients update the network weights.");
    }
  };






//   Blockly.Blocks['training_loop_e'] = {
//     init: function() {
//       this.appendDummyInput()
//           .appendField("Learning Process");
//       this.appendStatementInput("FORWARD_PASS")
//           .appendField("Forward Pass");
//       this.appendStatementInput("LOSS_CALCULATION")
//           .appendField("Loss Calculation");
//       this.appendStatementInput("BACKWARD_PASS")
//           .appendField("Backward Pass");
//       this.appendStatementInput("METRICS_CALCULATION")
//           .appendField("Metrics Calculation");
//       this.appendStatementInput("PREDICTION")
//           .appendField("Prediction")
//           .setCheck(['Predictions']);
//       this.appendStatementInput("LABEL")
//           .appendField("Labels")
//           .setCheck(['GroundTruth']);
      
//       this.setPreviousStatement(true, null);
//       this.setNextStatement(true, null);
//       this.setColour(230);
//       this.setTooltip("This block configures the learning process of the model.");
//     }
//   };


Blockly.Blocks['training_loop_e'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Training Loop");
      this.appendDummyInput()
          .appendField("Number of Epochs:")
          .appendField(new Blockly.FieldNumber(10), "epochs");
      this.appendDummyInput()
          .appendField("Batch Size:")
          .appendField(new Blockly.FieldNumber(32), "batch_size");
      this.appendStatementInput("MODEL_INSTANCE")
          .appendField("Model Instance")
      this.appendStatementInput("FORWARD_PASS")
          .appendField("Forward Pass Output");
      this.appendStatementInput("LOSS_CALCULATION")
          .appendField("Loss Calculation Inputs");
      this.appendStatementInput("BACKWARD_PASS")
          .appendField("Backward Pass");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(290);
      this.setTooltip("This block configures the training process of the model.");
    }
  };
  

  Blockly.Blocks['softmax_operation'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Softmax Operation");
      this.setPreviousStatement(true, 'ListItem');

      this.appendStatementInput("MODEL_OUTPUT")
          .setCheck(null)
          .appendField("Model Output:");
      this.setColour(120);
      this.setTooltip("Applies the softmax function to the model's output to convert it into probabilities.");
    }
  };



  

Blockly.Blocks['horizontal_input'] = {
  init: function() {
    this.appendValueInput('INPUT1');
    this.appendValueInput('INPUT2');
    this.appendValueInput('INPUT3');
    this.appendValueInput('INPUT4');
    this.setInputsInline(true);
    this.setColour(230);
    this.setTooltip("This is a block with four horizontal inputs.");
  }
};



Blockly.Blocks['empty_input_b'] = {
  init: function() {
    this.appendDummyInput();
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This is an empty block that can be used as a connection point.");
  }
};


Blockly.Blocks['model_save_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Remember Model");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(80);
    this.setTooltip("This block saves the trained model so you can use it again later.");
  }
};





Blockly.Blocks['model_e'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Model Definition");
    this.setColour(230);
    this.appendStatementInput("NETWORK")
    .appendField("Network Setup");
this.appendStatementInput("LOSS")
    .appendField("Loss Definition");
this.appendStatementInput("OPTMIZER")
    .appendField("Optimizer Config");
this.setNextStatement(true, null);

    this.setTooltip("This block represents the model. You can specify the structure of the model here.");
  }
};

Blockly.Blocks['data_b'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Data Block");
      this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("This block represents the data structure.");
    }
  };













  
  
  
  
  
  Blockly.Blocks['compile_model'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Compile Model")
          .appendField(new Blockly.FieldDropdown([["Adam", "adam"], ["SGD", "sgd"]]), "optimizer")
          .appendField(new Blockly.FieldDropdown([["Sparse Categorical Crossentropy", "sparse_categorical_crossentropy"]]), "loss");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(200);
      this.setTooltip("Compiles the model.");
    }
  };
  
  Blockly.Blocks['train_model'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Train Model")
          .appendField(new Blockly.FieldNumber(20), "epochs");
      this.appendValueInput("FEATURES")
          .setCheck("Features")
          .appendField("Features");
      this.appendValueInput("LABELS")
          .setCheck("Labels")
          .appendField("Labels");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(250);
      this.setTooltip("Trains the model.");
    }
  };



  // Training block for Discriminator
Blockly.Blocks['training'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Training");
      this.appendStatementInput("LOSS")
          .setCheck(['LossFunction'])
          .appendField("Loss Function");
      this.appendStatementInput("CONFIG")
          .setCheck(['OptimizerConfig'])
          .appendField("Optimizer Config");
      this.setColour(30);
      this.setTooltip("Training configuration for the discriminator.");
    }
  };

  
  Blockly.Blocks['network_type_e'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Network Type")
          .appendField(new Blockly.FieldDropdown([["MLP/Dense", "MLP"], ["RNN/LSTM", "LSTM"], ["Convolutional Neural Network", "CNN"]]), "network_type");

      // Input Dimension Field
      this.appendDummyInput()
          .appendField("Input Dimension:")
          .appendField(new Blockly.FieldNumber(0), "INPUT_DIMENSION");
  
      // Output Dimension Field
      this.appendDummyInput()
          .appendField("Output Dimension:")
          .appendField(new Blockly.FieldNumber(0), "OUTPUT_DIMENSION");
    this.appendDummyInput()
          .appendField("Activation Function")
          .appendField(new Blockly.FieldDropdown([["Bumpy (ReLU)", "ReLU"], ["Smooth (Sigmoid)", "Sigmoid"], ["Fast (Tanh)", "Tanh"], ["Slow (Softmax)", "Softmax"]]), "activation_function");
  
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(20);
      this.setTooltip("This block lets you choose the type of your model, the activation function it uses, and define the input and output dimensions.");
    }
  };
  
  
  Blockly.Blocks['input_shape_e'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Input Shape")
          .appendField(new Blockly.FieldDropdown([["1D", "1"], ["2D", "2"], ["3D", "3"]]), "dimension")
          .appendField("Dimension 1:")
          .appendField(new Blockly.FieldNumber(3), "dim1");
      this.appendDummyInput('description')
          .appendField("1D shape represents 1 column with 3 elements.");
      this.setOutput(true, null);
      this.setColour(160);
  
      this.getField('dimension').setValidator(function(value) {
        this.getSourceBlock().updateShape_(value);
      });
    },
  
    updateShape_: function(dimension) {
      // Remove existing inputs
      if (this.getInput('dim2')) this.removeInput('dim2');
      if (this.getInput('dim3')) this.removeInput('dim3');
      this.removeInput('description');
      // Add inputs and description based on selected dimension
      if (dimension == "2") {
        this.appendDummyInput('dim2')
            .appendField("Dimension 2:")
            .appendField(new Blockly.FieldNumber(0), "dim2");
      }
      if (dimension == "3") {
        this.appendDummyInput('dim2')
            .appendField("Dimension 2:")
            .appendField(new Blockly.FieldNumber(0), "dim2");
        this.appendDummyInput('dim3')
            .appendField("Dimension 3:")
            .appendField(new Blockly.FieldNumber(0), "dim3");
      }
      if (dimension == "1") {
        this.appendDummyInput('description')
            .appendField("1D shape is like a single row with a certain number of elements.");
      } else if (dimension == "2") {
        this.appendDummyInput('description')
            .appendField("2D shape is like a table with rows and columns.");
      } else if (dimension == "3") {
        this.appendDummyInput('description')
            .appendField("3D shape is like a cube with layers, rows, and columns.");
      }
      
    }
  };


// Optimizer configuration block
Blockly.Blocks['optimizer_config'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Optimizer Config");
      this.appendDummyInput()
          .appendField("Learning Rate")
          .appendField(new Blockly.FieldNumber(0.01), "learning_rate");
      this.appendDummyInput()
          .appendField("Optimizer Type")
          .appendField(new Blockly.FieldDropdown([["Adam", "ADAM"], ["SGD", "SGD"], ["RMSprop", "RMSPROP"]]), "optimizer_type");
      this.setPreviousStatement(true, 'OptimizerConfig');
      this.setNextStatement(true, 'OptimizerConfig');
      this.setColour(60);
      this.setTooltip("This is the optimizer configuration block.");
    }
  };
  
  
// Loss function block
Blockly.Blocks['loss_function_e'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Loss Function")
          .appendField(new Blockly.FieldDropdown([
              ["Mean Squared Error", "MSE"],
              ["Cross-Entropy", "CROSS_ENTROPY"],
              ["Binary Cross-Entropy", "BINARY_CROSS_ENTROPY"],
              ["Sparse Categorical Cross-Entropy", "SPARSE_CATEGORICAL_CROSS_ENTROPY"]
          ]), "loss_type");
      this.setPreviousStatement(true, 'LossFunction');
      this.setNextStatement(true, 'LossFunction');
      this.setColour(60);
      this.setTooltip("This is the loss function block.");
    }
  };
  



  Blockly.Blocks['input_dimension'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Input Dimension")
            .appendField(new Blockly.FieldNumber(32), "input_dim");
        this.setOutput(true, null);
        this.setColour(160);
        this.setTooltip("Define the input dimension of the layer.");
    }
};

Blockly.Blocks['output_dimension'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Output Dimension")
            .appendField(new Blockly.FieldNumber(32), "output_dim");
        this.setOutput(true, null);
        this.setColour(160);
        this.setTooltip("Define the output dimension of the layer.");
    }
};


Blockly.Blocks['sparse_categorical_crossentropy_loss'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Sparse Categorical Crossentropy Loss");
      this.appendStatementInput("PREDICTIONS")
          .setCheck(null)
          .appendField("Predicted Labels:");
      this.appendStatementInput("TRUE_LABELS")
          .setCheck(null)
          .appendField("True Labels:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(0);
      this.setTooltip("This block represents the sparse categorical crossentropy loss function, used for multi-class classification where labels are integers.");
    }
  };


  Blockly.Blocks['training_data'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Training data");
      this.appendStatementInput("FEATURES")
          .appendField("Features");
      this.appendStatementInput("TRUE_LABELS")
          .appendField("True Labels:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Training Data.");
    }
  };



  Blockly.Blocks['argmax_operation'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Argmax Operation");
      this.appendValueInput("SOFTMAX_OUTPUT")
          .setCheck("SoftmaxOutput")
          .appendField("Softmax Output:");
      this.setOutput(true, "Predictions");
      this.setColour(60);
      this.setTooltip("Finds the class with the highest probability in the softmax output.");
    }
  };

  

  
  
const VAEComponentBeginner = React.forwardRef(({ workspaceXML, onChange }, ref) => {
  const workspaceRef = useRef(null);
  const blocklyDivRef = useRef(null);

  const toolboxXML = `
  <xml xmlns="http://www.w3.org/1999/xhtml" id="toolbox" style="display: none;">
  <block type="animal"></block>
  <block type="category"></block>
  <block type="embedding_dimension"></block>
  <block type="features"></block>


  <block type="input_dimension"></block>

  <block type="loss_function_e"></block>
  
  <block type="model_i"></block>

  <block type="network_type_e"></block>
  <block type="n_labels"></block>
  <block type="optimizer_config"></block>
  <block type="output_dimension"></block>
  <block type="prediction_b"></block>

  <block type="softmax_operation"></block>
  <block type="sparse_categorical_crossentropy_loss"></block>
  <block type="training_loop_e"></block>
  <block type="training_data"></block>
  <block type="train_step_b"></block>
  <block type="weights"></block>
  </xml>
`;

  useEffect(() => {

    const workspace = Blockly.inject(blocklyDivRef.current, {
   toolbox: toolboxXML,
      scrollbars: true,
      theme: DarkTheme,  // Use the imported dark theme
      toolboxPosition: 'left'
    });

    if (!workspaceXML) {
      // Create a new default block of type 'network_type_b' and add it to the workspace
      const defaultBlock = workspace.newBlock('model_e');
      // Move the block to a specific position
      defaultBlock.moveBy(60, 300);
      // Initialize block and render itfday
      defaultBlock.initSvg();
      defaultBlock.render();

      const defaultBlock2 = workspace.newBlock('dataset_e');
      // Move the block to a specific position
      defaultBlock2.moveBy(60, 20);
      // Initialize block and render it
      defaultBlock2.initSvg();
      defaultBlock2.render();

      const defaultBlock3 = workspace.newBlock('data_prep');
      // Move the block to a specific position
      defaultBlock3.moveBy(60, 190);
      // Initialize block and render it
      defaultBlock3.initSvg();
      defaultBlock3.render();


      const defaultBlock4 = workspace.newBlock('training_loop_e');
      // Move the block to a specific position
      defaultBlock4.moveBy(500, 300);
      // Initialize block and render it
      defaultBlock4.initSvg();
      defaultBlock4.render();






    } else {
      Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(workspaceXML), workspace);
    }

    const handleChange = (event) => {
      if (event.type === Blockly.Events.UI) {
        return;
      }

      const newWorkspaceXML = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
      onChange(newWorkspaceXML);
    };

    workspace.addChangeListener(handleChange);

    workspaceRef.current = workspace;

    // Clean up
    return () => {
      workspace.removeChangeListener(handleChange);
      workspace.dispose();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    get workspace() {
      return workspaceRef.current;
    }
  }));

  return (
    <div ref={blocklyDivRef} style={{ height: '100vh', width: '100%' }} />
  );
});

export default VAEComponentBeginner;
