// paymentProvider.js
import React, { useState, useEffect } from 'react';
import firebase from "../firebase/firebaseIndex";
import PaymentContext from './paymentContext';

const PaymentProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState([]);

  const [paymentFlag, setPaymentFlag] = useState(null);

  useEffect(() => {
    // similar code as in your BuyButton component
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        setUser(user);
        checkPaymentStatus(user.uid);
      } else {
        setUser(null);
        setPaymentFlag(null);
      }
    });
  }, []);

  const checkPaymentStatus = async (uid) => {
    const db = firebase.firestore();
    const doc = await db.collection("users").doc(uid).get();
    const data = doc.data();

    if (data && data.gen_ai && data.gen_ai.payment) {
      setPaymentFlag(true);
    } else {
      setPaymentFlag(false);
    }
  }

  return (
    <PaymentContext.Provider value={{ user, setUser, errors, setErrors, paymentFlag, setPaymentFlag }}>
    {children}
    </PaymentContext.Provider>
  )
};

export default PaymentProvider;
