import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    root: {
      backgroundColor: '#424242', // Dark theme
      color: '#fff',
      maxWidth: 400, // Set the maximum width of the card
      margin: '0 auto', // Center the card
    },
    closeButton: {
      position: 'absolute',
      right: '10px',
      top: '10px',
      color: '#fff',
    },
  });

function MyCard({ title, caption, children }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Card className={classes.root} onClick={handleClickOpen}>
        <CardContent>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" component="p">
            {caption}
          </Typography>
        </CardContent>
      </Card>
      <Dialog fullScreen open={open} onClose={handleClose} enforceFocus={false} autoFocus={false}>
    <DialogTitle>{title}</DialogTitle>
    <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" className={classes.closeButton}>
      <CloseIcon />
    </IconButton>
    {children}
</Dialog>

    </div>
  );
}

export default MyCard;
