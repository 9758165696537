import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { palette } from "@material-ui/system";
import ReactPlayer from 'react-player'
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles({
  root: {
    maxWidth:555,
    maxHeight: "50%",
    minHeight: 440,
   
  },
  image: {
    width:"100%",
    height:300,
    margin: "auto",
    objectFit: "fill",
    overflow: "hidden"
  },
  video: {
  maxHeight: 375,
  margin: 'auto',
  },
  content: {
    // maxHeight: 200,
    // backgroundColor: "#ffcf02"
    // backgroundColor: "#e8f5fc"
    // maxWidth: 400,
    // minWidth: 300
  },
  cardButton: {
    // backgroundColor: "gray",
    // color: "black",
    fontWeight: "bond"

  },
  lightFont: {
    fontWeight: 200
  }
});



export default function SingleCard({ title, image,video, description }) {
  const [checked, setChecked] = React.useState(true);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const classes = useStyles();
  const VidCom = <ReactPlayer controls="true" width="100%" height="100%" url={video} />
  const CardMediaComp  = <>   
                <CardMedia component={image == "" ? 'video':'image'}
                       style={{height: "100%", width: "100%", paddingTop: '56.25%'}}
                        image={image}
                        />
                </>
  const CompLoad = image == "" ? VidCom:CardMediaComp
  return (
    <Card className={classes.root}>
      <CardActionArea>
        {/* <CardMedia className={classes.media} image={image} title={title} /> */}
        <div >
          {CompLoad}
         </div>
        <CardContent className={classes.content}>
          <Typography
            className={classes.cardButton}
            gutterBottom
            // variant="h5"
            // component="h2"
          >
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary.light">
          Share
        </Button>
        <Button size="small" color="primary.light">
          Learn More
        </Button>
      </CardActions>
    </Card>

  );
}
