import React, { useEffect, useState } from 'react';
import firebase from "../firebase/firebaseIndex";
import { authMethods } from '../firebase/authmethods';
import { createCheckoutSession } from '../stripe/createCheckoutSession';
import { loadStripe } from "@stripe/stripe-js";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// Load Stripe
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");


const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: '#f5f5f5',
      fontWeight: 'lighter'
    },
    // Other styles...
  }));


const BuyButton = () => {
  const [user, setUser] = useState(null);
  const [paymentFlag, setPaymentFlag] = useState(null);
  const [errors, setErrors] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    // Check if user is signed in
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        setUser(user);
        checkPaymentStatus(user.uid);
      } else {
        setUser(null);
        setPaymentFlag(null);
      }
    });
  }, []);

  useEffect(() => {
    if(user){
      const unsubscribe = firebase.firestore()
        .collection('users')
        .doc(user.uid)
        .collection('payments') // the 'payments' subcollection
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === "added" || change.type === "modified") {
              const data = change.doc.data();
              if (data && data.status === 'succeeded') {
                // loop through items array to find the specific product
                let hasPaidForSpecificItem = false;
                for (let i = 0; i < data.items.length; i++) {
                  if (data.items[i].id === "prod_OD2bR0hzFNKcoC") {
                    hasPaidForSpecificItem = true;
                    break;
                  }
                }
  
                if (hasPaidForSpecificItem) {
                  firebase.firestore().collection('users').doc(user.uid).set({
                    gen_ai: {payment: true}
                  }, {merge: true})
                  .then(() => setPaymentFlag(true))
                  .catch(error => console.log(error));
                }
              }
            }
          });
        });
  
      return () => unsubscribe(); // cleanup on unmount
    }
  }, [user]);
  

  const checkPaymentStatus = async (uid) => {
    const db = firebase.firestore();
    try {
      const doc = await db.collection("users").doc(uid).get();
      const data = doc.data();
  
      if (data && data.gen_ai && data.gen_ai.payment) {
        setPaymentFlag(true);
      } else {
        setPaymentFlag(false);
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
      alert(`Error fetching user data: ${error.message}`);
    }
  };
  const handleLogin = () => {
    authMethods.signinWithGoogle(setErrors, setUser); 
  }
  

  const handlePurchase = async () => {
    try {
      // Check if the user is authenticated
      if (user) {
        await createCheckoutSession(user.uid);
      } else {
        // Optionally, show a message or do something when the user is not authenticated
        console.error("User is not authenticated");
      }
    } catch (error) {
      // Log the error and optionally show a message to the user
      console.error("Error creating checkout session: ", error);
    }
  };
  

  if (!user) {
    return (
      <Button variant="contained" className={classes.button} onClick={handleLogin}>
        Login with Google
      </Button>
    );
  }

  if (paymentFlag === false) {
    return (
      <Button variant="contained" className={classes.button} onClick={handlePurchase}>
        Purchase Access
      </Button>
    );
  }

  if (paymentFlag === true) {
    return (
      <Paper style={{
        border: '1px solid green', 
        backgroundColor: '#e6ffe6', 
        padding: '20px', 
        borderRadius: '5px', 
        textAlign: 'center',
        color: 'green',
        marginTop: '20px'
      }}>
        <Typography variant="h6" style={{fontWeight: 'bold'}}>
          You have access!
        </Typography>
      </Paper>
    );
  }
  

  return null;
};

export default BuyButton;
