
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import LearningGoals from "../../components/LearningGoals";
import FlipCardGrid from "../../components/FlipCardGrid";
import TitleSection from "../../components/TitleSection";
import SubtitleSection from "../../components/SubtitleSection";
import ImgMediaCard from "../../components/ImgMediaCard";
import VideoMediaCard from "../../components/VideoMediaCard";
import "../../markdown-styles/styles.css";
export const Intro_Videos = props => <div style={{
  padding: 0,
  backgroundColor: "#1e1e1e",
  width: "100%",
  marginTop: '50px',
  margin: "auto",
  zIndex: 9999
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} mdxType="ReactPlayer" />
    <br />
  </div>;
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  // material_id: "",
  width: 300,
  height: 450,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "510",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: "green"
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: "yellow"
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const cardsContentServices = [{
  title: 'Personalized Learning',
  content: "AI can provide customized training programs based on a player's strengths, weaknesses, and style of play, enhancing the learning curve."
}, {
  title: 'Detailed Analysis',
  content: "With its ability to process and analyze massive amounts of data quickly, AI can offer detailed insights about a player's performance, such as swing velocity, footwork, shot placement, and even subtle technique improvements."
}, {
  title: 'Monitoring Progress',
  content: "AI systems can track a player's performance over time, providing objective, data-driven assessments of their progress and areas that need improvement."
}, {
  title: 'Strategic Insights',
  content: "By analyzing patterns and tendencies in a player's game, AI can provide strategic recommendations for match play."
}, {
  title: 'Accessibility',
  content: 'With AI, a high level of coaching and feedback can be available anytime, anywhere, making quality coaching more accessible to all.'
}, {
  title: 'Efficiency',
  content: "AI can instantly analyze and provide feedback on a player's performance, saving valuable time compared to traditional coaching methods."
}, {
  title: 'Injury Prevention',
  content: 'AI can identify potentially harmful movements or habits that might lead to injury over time, promoting safer training.'
}];
export const buttons = [{
  label: "Agenda",
  time: 0
}, {
  label: "What is optimization?",
  time: .09
}, {
  label: "cardboard experiment",
  time: .36
}, {
  label: "Volume as a function of height",
  time: 1.14
}, {
  label: "First derivative",
  time: 1.41
}, {
  label: "What is critical point?",
  time: 1.59
}, {
  label: "What is slope of tangent line?",
  time: 2.09
}, {
  label: "Solving for height h to optimize volume",
  time: 2.15
}, {
  label: "Summary",
  time: 2.51
}];
export const goals = [{
  id: 'LG01',
  description: "Understand the concept of optimization in the context of maximizing volume."
}, {
  id: 'LG02',
  description: "Identify the optimization function and its role in optimization problems."
}, {
  id: 'LG03',
  description: "Recognize the constraints in an optimization problem and understand their significance."
}, {
  id: 'LG04',
  description: "Grasp the concept of a derivative and how it can be applied in optimization."
}, {
  id: 'LG05',
  description: "Comprehend the idea of a tangent and its relationship with derivatives."
}, {
  id: 'LG06',
  description: "Master the formula for derivatives and its application in solving optimization problems."
}, {
  id: 'LG07',
  description: "Understand what an inflection point is and how to identify it."
}, {
  id: 'LG08',
  description: "Define a critical point and explain its relevance in optimization."
}];
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" playing controls config={{
      file: {
        attributes: {
          id: "audio-element"
        }
      },
      playerVars: {}
    }} width="320px" height="180px" mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>
        What is optimization
      </button>
      <br />
      <button onClick={() => ref.current.seekTo(20)}>
        What is critical point?
      </button>
    </div>;
};
export const ClipVideo = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" startTime={10} endTime={20} playing controls mdxType="ReactPlayer" />
  </div>;
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const AppHeader = makeShortcode("AppHeader");
const ReactPlayer = makeShortcode("ReactPlayer");
const layoutProps = {
  Intro_Videos,
meta,
ggbParameters,
ggbParameters2,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
cardsContentServices,
buttons,
goals,
Player,
ClipVideo,
Box11,
Box22
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">















    <br />
    <br />
    <br />
    <br />
    <AppHeader mdxType="AppHeader" />

    <>
      <TitleSection title="Advancing Your Game: The Importance of AI in Tennis" mdxType="TitleSection" />
      <FlipCardGrid cardsContent={cardsContentServices} mdxType="FlipCardGrid" />;
    </>
    <br />
    <br />
    <br />
    <h1>{`Enhancing Sports Performance with AI`}</h1>
    <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/People_TennisAI.mp4" title="Intelligent Tennis Assistant" description="Unlock your true potential with Tennis AI: Tailored coaching powered by advanced machine learning. Learn, adapt, and improve with personalized analytics and insights." link="/tennis_ai" mdxType="VideoMediaCard" />
    <>
      <TitleSection title="What Next?" mdxType="TitleSection" />
      <SubtitleSection subtitle="Ready to realize Your Potential? Connect with us at office@davinci-dynamics.com for a personalized demo and discover how Tennis AI can transform your game. Don't wait, step into the future of tennis coaching today!" mdxType="SubtitleSection" />
    </>




    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;