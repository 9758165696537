
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import LoadGGBLayoutHtml from "../../components/LoadGGBLayoutHtml";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Demo from '../../components/DraggableDialog';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import  {useState,useEffect,useReducer,useRef} from "react";
export const data = [{
  title: "1.Call price simulation",
  id: 1,
  script: 'Execute({"RunClickScript(scenarioB)"})',
  text: "How does Call value change with change in underlying price?"
}, {
  title: "2.Call price derivation",
  id: 2,
  script: 'Execute({"RunClickScript(formulasB)"})',
  text: "Derive Call price from Put Call parity "
}, {
  title: "3.Intrinsic value",
  id: 3,
  script: 'Execute({"RunClickScript(intextB)"})',
  text: "What factors influence Options Intrinsuic value?"
}, {
  title: "4.Moneyness of Option",
  id: 4,
  script: 'Execute({"RunClickScript(itmB)"})',
  text: "When is Call option ITM, ATM and OTM"
}, {
  title: "5.Strike price, Asignment and exercise",
  id: 5,
  script: 'Execute({"RunClickScript(excB)"})',
  text: "Rights and Obligations of Call options seller and buyer"
}];
export const BoxExample = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  minWidth: 300
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;

const layoutProps = {
  data,
BoxExample
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <>
      <LoadGGBLayoutHtml data={data} geogebraJSON={"https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/testfolder/calls/calls_lesson.json"} buttonTitle="Click here to load Call Options Intro App" mdxType="LoadGGBLayoutHtml" />
    </>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;