import React, { useState } from 'react';
import D3CallOptionPlot from './CallPayoff'; // assuming it's in the same directory


function InteractiveOptionPlot() {
    const [stockPrice, setStockPrice] = useState(100);
    const [strikePrice, setStrikePrice] = useState(95);
    const interestRate = 0.05;
    const time = 1;
    const volatility = 0.2;
  
    return (
      <div>
        <div>
          <label>Stock Price: {stockPrice}</label>
          <input 
            type="range" 
            min="50" 
            max="150" 
            value={stockPrice} 
            onChange={(e) => setStockPrice(Number(e.target.value))}
          />
        </div>
  
        <div>
          <label>Strike Price: {strikePrice}</label>
          <input 
            type="range" 
            min="50" 
            max="150" 
            value={strikePrice} 
            onChange={(e) => setStrikePrice(Number(e.target.value))}
          />
        </div>
  
        {/* Add similar sliders for interestRate, time, and volatility */}
  
        <D3CallOptionPlot 
          stockPrice={stockPrice}
          strikePrice={strikePrice}
          interestRate={interestRate}
          time={time}
          volatility={volatility}
        />
      </div>
    );
  }



export default InteractiveOptionPlot;
