import "./style.css";
import Marker from './ImageMarkers';
import React from 'react';


export default function ImageAnnotation (props) {
  return (
     <div >
       {props.children}
    </div>
  );
}
