
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
export const goals = [{
  id: 'LG01',
  description: "Introduction to Vectors: Develop an understanding of vectors, their properties, and their role in machine learning. Understand how vectors can be used to represent data in higher-dimensional spaces."
}, {
  id: 'LG02',
  description: "Understanding Hyperplanes: Learn about the concept of a hyperplane and its role in separating different classes in a binary classification problem. Explore how a hyperplane is defined in the context of a vector space."
}, {
  id: 'LG03',
  description: "Exploration of Logits: Gain an understanding of logits, their importance in the context of machine learning, and how they are used in binary classification problems. Discover the relationship between logits and probabilities."
}, {
  id: 'LG04',
  description: "Parameter Updation: Grasp the process of updating model parameters during the training process. Understand how the perceptron algorithm iteratively updates the weights and bias to minimize the classification error."
}, {
  id: 'LG05',
  description: "The Step Function: Discover the role of the step function in the Perceptron Learning Algorithm. Understand how it helps in making binary decisions and explore its characteristics."
}, {
  id: 'LG06',
  description: "Perceptron Learning Algorithm: Learn the entire process of the Perceptron Learning Algorithm, including initialization, the learning rule, and convergence. Understand how all the concepts learned so far are used in this algorithm to perform binary classification."
}];
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const SomeComponent = makeShortcode("SomeComponent");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Box2 = makeShortcode("Box2");
const layoutProps = {
  goals,
ggbParameters2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Perceptron Learning Algorithm`}</h1>
    <Header mdxType="Header"> Learning goals</Header>

    <SomeComponent mdxType="SomeComponent" />
    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />
    <h1>{`Interactive visualization`}</h1>
    <div>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/Perceptron_Algo_2.json" ggbParameters={ggbParameters2} appId="perceptron_algo_id" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;