import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}));

const HeadingBold = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="h2" align="center" className={classes.heading}>
      {children}
    </Typography>
  );
};

export default HeadingBold;
