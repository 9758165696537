import React, { useRef, useEffect, useState } from "react";

function DrawingCanvas() {
  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    

    const draw = (event) => {
        if (!drawing) return;
      
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
      
        context.lineWidth = 5;
        context.lineCap = "round";
        context.strokeStyle = 'black';
      
        context.lineTo(x, y);
        context.stroke();
        context.beginPath();
        context.moveTo(x, y);
      };

    canvas.addEventListener('mousedown', () => setDrawing(true));
    canvas.addEventListener('mouseup', () => {
      setDrawing(false);
      context.beginPath();
    });
    canvas.addEventListener('mousemove', draw);
    
    return () => {
      canvas.removeEventListener('mousedown', () => setDrawing(true));
      canvas.removeEventListener('mouseup', () => setDrawing(false));
      canvas.removeEventListener('mousemove', draw);
    };
  }, [drawing]);

  const downloadImage = () => {
    const canvas = canvasRef.current;
    const imageURI = canvas.toDataURL('image/png');
    let a = document.createElement('a');
    a.href = imageURI;
    a.download = 'canvas.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight} 
      style={{cursor: "url('./barretr_Pencil.svg') 0 32, auto"}}/>
      <button onClick={downloadImage}>Download Image</button>
    </div>
  );
}

export default DrawingCanvas;
