import React, { useState } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { CssBaseline, Box, Switch, FormGroup, FormControlLabel, Typography, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    maxWidth: '70%',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  listItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: '#424242',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#616161',
    },
  },
  listItemText: {
    marginRight: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  }
}));

const CustomSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#4caf50',
    },
    '&$checked + $track': {
      backgroundColor: '#4caf50',
    },
  },
  checked: {},
  track: {},
})(Switch);

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

export default function LearningGoals({learningGoals}) {
  const classes = useStyles();
  const [checkedCount, setCheckedCount] = useState(0);

  const handleChange = (event) => {
    setCheckedCount(checkedCount + (event.target.checked ? 1 : -1));
  };

  const levelOfUnderstanding = () => {
    const percentage = (checkedCount / learningGoals.length) * 100;
    if (percentage >= 70) {
      return "Expert Level";
    } else if (percentage >= 30) {
      return "Intermediate Level";
    } else {
      return "Beginner Level";
    }
  };

  const handleSubmit = () => {
    // perform the operation you want on submit
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box className={classes.root} color="text.primary">
        <Typography variant="h5">Make Selections:</Typography>
        <List component="nav" aria-label="Learning Goals">
          {learningGoals.map((goal) => (
            <ListItem button className={classes.listItem} key={goal.id}>
              <FormGroup>
                <FormControlLabel
                  control={<CustomSwitch onChange={handleChange} />}
                  label={`${goal.id}. ${goal.description}`}
                  labelPlacement="end"
                  className={classes.listItemText}
                />
              </FormGroup>
            </ListItem>
          ))}
        </List>
        <Button variant="contained" color="primary" className={classes.submitButton} onClick={handleSubmit}>Submit</Button>
        <Typography variant="h6">Your level of understanding is: {levelOfUnderstanding()}</Typography>
      </Box>
    </ThemeProvider>
  );
}
