

import React, { useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import VideoPlayer from './VideoPlayer';
import DrawingCanvas from './DrawingCanvas';
import VAEComponentBeginner from  "../blocks/VAEComponentBeginner.js";
import VAEComponentGraduate from  "../blocks/VAEComponentGraduate.js";

const PageCover = React.forwardRef((props, ref) => (
  <div className="page page-cover" ref={ref} data-density="hard">
    <div className="page-content">
      <h2>{props.children}</h2>
    </div>
  </div>
));

const Page = React.forwardRef((props, ref) => (
  <div className="page" ref={ref}>
    <div className="page-content">
      <h2 className="page-header">Page header - {props.number}</h2>
      <div className="page-text">{props.children}</div>
      <div className="page-footer">{props.number + 1}</div>
    </div>
  </div>
));

const MyBook = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div>
    <HTMLFlipBook
  width={550}
  height={600}
  size="stretch"
  minWidth={315}
  maxWidth={1000}
  minHeight={400}
  maxHeight={1533}
  maxShadowOpacity={0.5}
  showCover={true}
  mobileScrollSupport={true}
  duration={1000}  // This property controls the flip speed
  onPage={(e) => setCurrentPage(e.data+1)}
  className="demo-book"
>
        <PageCover>BOOK TITLE</PageCover>
        <Page number={1}>
          <div>
            <VAEComponentBeginner/>
          </div>
        </Page>
        <Page number={2}>
            <div>
            <div style={{
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                }}>
                <VideoPlayer
                    width="80%"  // Set width to be 80% of the parent div
                    height="80%" // Set height to be 80% of the parent div
                    url="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide3.m4v"
                />
                </div>    
            </div>
        </Page>
        <Page number={3}>
          <div>
            <VAEComponentGraduate/>
          </div>
        </Page>
        <Page number={4}>
            <div>
            <div style={{
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                }}>
                <VideoPlayer
                    width="80%"  // Set width to be 80% of the parent div
                    height="80%" // Set height to be 80% of the parent div
                    url="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide3.m4v"
                />
                </div>    
            </div>
        </Page>
        <PageCover>THE END</PageCover>
      </HTMLFlipBook>
      <div style={{position: 'absolute', top: '50%', left: '5%', pointerEvents: 'none'}}>
        <AiOutlineArrowLeft size={50} />
      </div>
      <div style={{position: 'absolute', top: '50%', right: '5%', pointerEvents: 'none'}}>
        <AiOutlineArrowRight size={50} />
      </div>
    </div>
  );
};

export default MyBook;


