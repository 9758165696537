import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Box,
  Modal,
  useMediaQuery,
  Link,
} from "@material-ui/core";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import YouTube from "react-youtube";

const useStyles = makeStyles((theme) => ({
  // ...existing styles
  videoButton: {
    background: "rgba(255, 255, 255, 0.3)", // white background with transparency
    border: "none",
    borderRadius: "50%", // to create round shape
    padding: theme.spacing(2),
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.5)", // Increase transparency on hover
    },
  },
  hero: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  carousel: {
    width: "100%",
    height: "100%",
    // minHeight: '100vh', // Add this line
  },
  carouselItemVideo: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
  carouselItem: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",

    position: "relative",
    backgroundSize: "contain", // Change from 'cover' to 'contain'
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat", // Add this line
    backgroundColor: "#000", // Set a fallback background color
    overflow: "hidden", // Add this line
  },
  overlay: {
    position: "absolute",
    top: 50,
    left: 0,
    right: 0,
    bottom: 50, // change bottom to leave space for the button
  },
  titleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    maxWidth: "60%",
    margin: "0 auto",
    textAlign: "left",
  },
  subtitle: {
    color: "#FFCC00",
    marginBottom: theme.spacing(5),
    width: "40%",
    textAlign: "left", // Align text to left
    [theme.breakpoints.down("xs")]: {
      display: "none", // Hide the subtitle on small screens
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  video: {
    outline: "none",
    maxWidth: "80%", // limit the width to 80% of screen width
    maxHeight: "80%", // limit the height to 80% of screen height
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 1000,
  },
  goToButton: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginTop: theme.spacing(2),
  },
}));

const Hero = ({ items }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isYouTube, setIsYouTube] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0); // New state variable

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [open, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  const handleOpen = (videoSrc) => {
    setCurrentVideo(videoSrc);
    if (videoSrc.includes("youtube.com")) {
      setIsYouTube(true);
    } else {
      setIsYouTube(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.hero}>
            <Box display="flex" justifyContent="center" flexWrap="wrap" mt={2}>
        {items.map((item, index) => (
          <Button
          key={index}
          variant="contained"
          color="secondary" // Or primary, according to your preference
          style={{
              margin: theme.spacing(1), // Add some spacing between the buttons
              whiteSpace: 'normal', // Allow the text to wrap
              height: 'auto', // Allow the button to expand vertically
              maxWidth: '40%' // Set a maximum width for the button
          }}
          onClick={() => setCurrentSlide(index)}
          >
            {item.title}
          </Button>
        ))}
      </Box>
      <br/>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        selectedItem={currentSlide} // Use currentSlide to set the active slide
        onChange={setCurrentSlide} // Update currentSlide when the active slide changes
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={classes.carouselItem}
            style={{
              backgroundImage: item.backgroundImage
                ? `url(${item.backgroundImage})`
                : undefined,
            }}
          >
            {item.video && (
              <Button
                className={classes.videoButton}
                onClick={() => handleOpen(item.video)}
              >
                <PlayArrowIcon style={{ fontSize: 60, color: "#ffffff" }} />
              </Button>
            )}
            <Modal open={open} onClose={handleClose} className={classes.modal}>
              {isYouTube ? (
                <YouTube
                  videoId={currentVideo.split("=")[1]}
                  className={classes.video}
                />
              ) : (
                <video
                  autoPlay
                  controls
                  className={classes.video}
                  src={currentVideo}
                />
              )}
            </Modal>
            <br />
            <br />
            <br />
            <Box className={classes.titleContainer}>
              {item.title.split(":").map((part, index) => (
                <Typography
                  key={index}
                  variant="h2"
                  className={classes.title}
                  component="div"
                >
                  {part}
                </Typography>
              ))}
            </Box>

            <br />
            <br />
            {!isSmallScreen && item.subtitle && (
              <Typography variant="h6" className={classes.subtitle}>
                {item.subtitle}
              </Typography>
            )}
            {item.link && (
              <Box mt={2} display="flex" justifyContent="center">
                <Button variant="contained" color="primary">
                  <Link
                    href={`http://${item.link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    More Details
                  </Link>
                </Button>
              </Box>
            )}
            <br />
          </div>
        ))}
      </Carousel>

    </Box>
  );
};

export default Hero;
