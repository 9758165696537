import React, { useRef, useEffect, useImperativeHandle, useState } from 'react';
import Blockly from 'blockly';
import DarkTheme from '@blockly/theme-dark';
// import 'blockly/msg/es';




// Define Blockly blocks
Blockly.Blocks['device_map'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Device Map")
          .appendField(new Blockly.FieldDropdown([
            ["CPU", "CPU"], 
            ["GPU", "GPU"], 
        ]), "device_map");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(20); 
      this.setTooltip("Whether to use CPU or GPU acceleration..");
    }
  };


  // Define Blockly blocks
Blockly.Blocks['model_id'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Model id")
          .appendField(new Blockly.FieldDropdown([
            ["TheBloke/Llama-2-7b-Chat-GPTQ", "qm"]
            ]), "model_type");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230); 
      this.setTooltip("Facebook Optimus 125M model, which is a variant of the BERT");
    }
  };




  // Define Blockly blocks
Blockly.Blocks['quant_model'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Quantized language model")
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230); 
      this.setTooltip("Prepared language model ready for deployment on a mobile or edge device");
    }
  };


  // Define Blockly blocks
  Blockly.Blocks['peft_model'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("PEFT model")
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230); 
      this.setTooltip("PEFT Model");
    }
  };



Blockly.Blocks['AutoModelForCausalLM'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Load Pre-trained Model");
      this.appendStatementInput("model")
          .appendField("Model Id");
      this.appendStatementInput("quantization")
          .appendField("Quantization")
      this.appendStatementInput("GROUND_TRUTH")
          .appendField("Device")
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("This block configures the learning process of the model.");
    }
  };



Blockly.Blocks['tokenizer'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Pre-trained Tokenizer");
      this.appendStatementInput("decode")
          .appendField("Model Id");
      this.setColour(130);
      this.setTooltip("This loads the pre-trained tokenizer associated with the specified model ID..");
      this.setPreviousStatement(true, null);
    }
  };




  Blockly.Blocks['quantize_step'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Quantize a Model");
      this.appendStatementInput("OPTIONS");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.appendDummyInput()
      .appendField("Output");
  this.appendStatementInput("output");
      this.setColour(260);
      this.setTooltip("Loading models that are quantized with GPTQ algorithm");
    }
  };


  Blockly.Blocks['get_peft_model'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Get PEFT Model");
      this.appendStatementInput("OPTIONS");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.appendDummyInput()
      .appendField("Output");
  this.appendStatementInput("output");
      this.setColour(260);
      this.setTooltip("convert the model into a peft model ");
    }
  };



  Blockly.Blocks['inference_step'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Inference with Quantized model");
      this.appendStatementInput("OPTIONS");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(260);
      this.appendDummyInput()
      .appendField("Output");
      this.appendStatementInput("output");
      this.setColour(160);
      this.setTooltip("Perform an inference on the quantized model.");
    }
  };


  Blockly.Blocks['quantization'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Quantization Config");
     this.appendDummyInput()
          .appendField("Bits")
          .appendField(new Blockly.FieldTextInput("??"), "bits");
    this.appendDummyInput()
          .appendField("Group size")
          .appendField(new Blockly.FieldTextInput("??"), "group_size");
    this.appendDummyInput()
          .appendField("Dataset")
          .appendField(new Blockly.FieldTextInput("??"), "dataset")
      this.setColour(130);
      this.setTooltip("This defines the settings for the quantization process");
      this.setPreviousStatement(true, null);
    }
  };



  Blockly.Blocks['Trainer'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Trainer");
        this.appendDummyInput()
          .appendField("Model");
      this.appendDummyInput()
          .appendField("Dataset");
      this.appendStatementInput("dataset");
     this.appendDummyInput()
          .appendField("Optimizer")
          .appendField(new Blockly.FieldTextInput("adamw_hf"), "optimizerits");
    this.appendDummyInput()
          .appendField("Output dir")
          .appendField(new Blockly.FieldTextInput("outputs"), "outputs");
    this.appendDummyInput()
          .appendField("Learning rate")
          .appendField(new Blockly.FieldTextInput(".001"), "lr")
      this.setColour(130);
      this.setTooltip("Some preprocessing to the model to prepare it for training");
      this.setPreviousStatement(true, null);
      this.appendDummyInput()
      .appendField("Output");
     this.appendStatementInput("output");
    }
  };




  Blockly.Blocks['dataset'] = {
    init: function() {

    this.appendDummyInput()
          .appendField("Dataset")
      this.setColour(130);
      this.setTooltip("This defines the settings for the quantization process");
      this.setPreviousStatement(true, null);
    }
  };



  Blockly.Blocks['lora_config'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("LoRa Config");
     this.appendDummyInput()
          .appendField("r")
          .appendField(new Blockly.FieldTextInput("??"), "bits");
    this.appendDummyInput()
          .appendField("alpha")
          .appendField(new Blockly.FieldTextInput("??"), "group_size");
    this.appendDummyInput()
          .appendField("Task Type")
          .appendField(new Blockly.FieldTextInput("CAUSAL_LM"), "dataset")
    this.appendDummyInput()
          .appendField("Target modules")
          .appendField(new Blockly.FieldDropdown([
            ["k_proj", "qm"],
            ["o_proj", "qm"],
            ["p_proj", "qm"],
            ["v_proj", "qm"],
            ["TheBloke/Llama-2-7b-Chat-GPTQ", "qm"]
            ]), "model_type");
      this.setColour(130);
      this.setTooltip("This defines the settings for the quantization process");
      this.setPreviousStatement(true, null);
    }
  };



const BlocklyWorkspace = React.forwardRef(({ workspaceXML, onChange }, ref) => {
  const workspaceRef = useRef(null);
  const blocklyDivRef = useRef(null);

  const toolboxXML = `
  <xml xmlns="http://www.w3.org/1999/xhtml" id="toolbox" style="display: none;">
    <block type="tokenizer"></block>
    <block type="AutoModelForCausalLM"></block>
    <block type="quantization"></block>
    <block type="device_map"></block>
    <block type="quant_model"></block>
    <block type="model_id"></block>
    <block type="lora_config"></block>
    <block type="dataset"></block>
    <block type="get_peft_model"></block>
    <block type="peft_model"></block>
  </xml>
  `;

  useEffect(() => {
    const workspace = Blockly.inject(blocklyDivRef.current, {
      toolbox: toolboxXML,
      scrollbars: false,
      theme: DarkTheme,
      toolboxPosition: 'top',
    });
  
    if (workspaceXML) {
      Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(workspaceXML), workspace);
    }
  
    // Create and move the block
    const b1 = workspace.newBlock('quantize_step');
    b1.initSvg();
    b1.render();
    workspace.addTopBlock(b1);  // Add the block to the workspace
    b1.moveBy(100, 50);  // Move the block to the position (100, 50)

    const b2 = workspace.newBlock('inference_step');
    b2.initSvg();
    b2.render();
    workspace.addTopBlock(b2);  // Add the block to the workspace
    b2.moveBy(550, 100);  // Move the block to the position (100, 50)

    const b3 = workspace.newBlock('get_peft_model');
    b3.initSvg();
    b3.render();
    workspace.addTopBlock(b3);  // Add the block to the workspace
    b3.moveBy(550, 500);  // Move the block to the position (100, 50)


    const b4 = workspace.newBlock('Trainer');
    b4.initSvg();
    b4.render();
    workspace.addTopBlock(b4);  // Add the block to the workspace
    b4.moveBy(250, 500);  // Move the block to the position (100, 50)

    
  
    const handleChange = (event) => {
      if (event.type === Blockly.Events.UI) {
        return;
      }
  
      const newWorkspaceXML = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
      onChange(newWorkspaceXML);
    };
  
    workspace.addChangeListener(handleChange);
    workspaceRef.current = workspace;
  
    return () => {
      workspace.removeChangeListener(handleChange);
      workspace.dispose();
    };
  }, []);
  

  useImperativeHandle(ref, () => ({
    get workspace() {
      return workspaceRef.current;
    }
  }));

  return (
    <div ref={blocklyDivRef} style={{ height: '100vh', width: '100%' }} />
  );
});

const VAEComponentBeginner = () => {
  const [workspaceXML, setWorkspaceXML] = useState('');
  const workspaceRef = useRef(null);

  return (
    <div>
      <BlocklyWorkspace
        workspaceXML={workspaceXML}
        onChange={setWorkspaceXML}
        ref={workspaceRef}
      />
    </div>
  );
};

export default VAEComponentBeginner;
