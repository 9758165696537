import React, { useEffect, useState, lazy, Component, Suspense } from "react";
import SingleCard from "./components/singleCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import FullScreenDialog from "./components/DialogComponent";

import Typography from "@material-ui/core/Typography";
import AppHeader from "./components/AppHeader";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

export default function CardsForBlogs({in_json}) {

    let componentsAndIds = [];
    in_json.forEach((blog) => {
        componentsAndIds.push(blog)
    });

    return (
      <div>
        <LoadCards
        in_json={componentsAndIds}
      />
  
    </div>
      
    )
}

/*

*/
function LoadCards({ in_json }) {
  const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.default,
  },

  eachChapter: {
    // flexGrow: 1,
    backgroundColor: "#f9f9f9",
    padding: theme.spacing(2),
    margin: "auto",
    direction:"column",
    alignItems:"center",
    justify:"center",
    minHeight: '50vh'
  },
  appbar: {
    // backgroundColor: "black",
    color: "lightgray"
  },

  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500
  },
  control: {
    padding: theme.spacing(2)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 200
  },
  lightFont: {
    fontWeight: 200
  },  
  toolbar: theme.mixins.toolbar,
  appMarginLeft: {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginTop: 60
  },
}));


const handleDialogOpen = (currentTarget, title) => {
  SetDialogTitle(title);
  const clickedBoxId = currentTarget;

  const matchedPageObject = pages.filter((d) => d.id === clickedBoxId);
  let pageComponent = <DefaultComponent />;
  if (matchedPageObject.length !== 0) {
    pageComponent = matchedPageObject[0].Component;
  }
  SetDialogChild(pageComponent); // This is what gets showed up in Dialog. Look for math/index.js for id's and components
  SetIsOpen(true);
};

const handleDialogClose = () => {
  SetIsOpen(false);
};

const handleDrawerClick = () => {
  // SetIsDrawerOpen(false);
  SetIsDrawerOpen(prevCheck => !prevCheck);
};

  
    let history = useHistory();
    const classes = useStyles();
    const [isOpen, SetIsOpen] = useState(false);
    // const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [isDrawerOpen, SetIsDrawerOpen] = useState(true);
    const [dialogTitle, SetDialogTitle] = useState("");
    const [DialogChildsOpen, SetDialogChild] = useState(
      <>
        <DefaultComponent />
      </>
    );
    let pages = in_json;


    let blogCards = [];
    let blogArray = [];

    in_json.forEach((blog) => {
        if (blog.active === 1) {
          blogArray.push(
            <Grid key={blog.name} item>
              <ButtonBase
                key={blog.name}
                id={blog.id}
                className={classes.cardButton}
                onClick={(e) => handleDialogOpen(e.currentTarget.id, blog.name)}
              >
                <SingleCard
                  key={blog.id}
                  title={blog.name}
                  image={blog.image}
                  video ={blog.video} 
                  description={blog.description}
                />
              </ButtonBase>
            </Grid>
          );
        }
      });
  
      const element = (
        <>
          <Grid container spacing={20}
                direction="column"
                alignItems="left"
                justify="left"
                 >
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                {blogArray}
              </Grid>
            </Grid>
          </Grid>
        </>
      );
      blogCards.push(element); 
  



    return (
      <div className={classes.root}>
        {/* <ClippedDrawer isDrawerOpen={isDrawerOpen} categoryList={categoryList}/> */}
        <AppHeader handleDrawerToggle={handleDrawerClick}/>
        <main className={classes.content}>
            {blogCards}
  
            <FullScreenDialog
              isOpen={isOpen}
              handleClose={handleDialogClose}
              title={dialogTitle}
              style={{position: 'fixed'}}
            >
              {DialogChildsOpen}
            </FullScreenDialog>
    
        </main>
      </div>
    );
  }
  
  function DefaultComponent() {
    const text = "Hello World . I am inside math.js / App3";
  
    return (
      <div className="App">
        <p> {text} </p>
      </div>
    );
  }
