
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
export const goals = [{
  id: 'GD01',
  title: "Understanding the Concept",
  description: "Understand the basic concept and intuition behind the Gradient Descent optimization algorithm."
}, {
  id: 'GD02',
  title: "Role in Machine Learning",
  description: "Understand the role of Gradient Descent in Machine Learning and how it helps in model training."
}, {
  id: 'GD03',
  title: "Gradient Descent Variants",
  description: "Learn about different types of Gradient Descent algorithms such as Batch, Stochastic, and Mini-Batch Gradient Descent."
}, {
  id: 'GD04',
  title: "Learning Rate",
  description: "Understand the concept of the learning rate and its role in the Gradient Descent algorithm."
}, {
  id: 'GD05',
  title: "Convergence",
  description: "Learn about the convergence of the Gradient Descent algorithm and understand the challenges such as getting stuck in local minima."
}, {
  id: 'GD06',
  title: "Advanced Optimizers",
  description: "Get introduced to more advanced optimizers that are commonly used in Deep Learning, such as Adam, RMSprop, and Adagrad."
}];
export const PartialDerivatives = () => <div>
    <div style={{
    position: "relative"
  }}>
                    <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/8.png" mdxType="ImageCard" />
                    <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
                    <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
  </div>
  <div style={{
    position: "relative"
  }}>
                    <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/9.png" mdxType="ImageCard" />
                    <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
                    <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
  </div>
  <div>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/partial_derivatives_car_4.json" ggbParameters={ggbParameters2} appId="Gradients_id" email="pavanmirla@gmail.com" data={[{
      component: <Box2 mdxType="Box2" />,
      button: "XYVIEW"
    }]} mdxType="GeogebraDraw" /> 
   </div>
</div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const SomeComponent = makeShortcode("SomeComponent");
const ImageCard = makeShortcode("ImageCard");
const Marker = makeShortcode("Marker");
const Howler = makeShortcode("Howler");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  goals,
PartialDerivatives,
Box2,
ggbParameters2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Gradient Descent Algorithm`}</h1>

    <Header mdxType="Header"> Learning goals</Header>
    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />
    <SomeComponent mdxType="SomeComponent" />

    <PartialDerivatives mdxType="PartialDerivatives" />



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;