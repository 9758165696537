import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Container from "@material-ui/core/Container";
import { css, jsx } from '@emotion/core';
import GeogebraDraw from "./GeogebraDraw";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "99%",
    marginLeft: "20px",  // Add this line
    marginRight: "20px", // And this line
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#90caf9"

  },
  details: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function SimpleAccordion({ title, children, key, padding, marginBottom, borderRadius, backgroundColor, color}) {
  const classes = useStyles();

  return (
    <div className={classes.root} key={key}>
      <Accordion
        css={css`
        width: 99%;
          margin-bottom: ${marginBottom}rem !important;
          border-radius: ${borderRadius};
          &::before {
            display: none;
          }
          background-color: ${backgroundColor};
          color: ${color};
        `}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Container>{children}</Container>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
