// add useContext
import React, { useContext } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import { Signup } from "./SignUp";
import {
  useHistory
} from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Signin = () => {
  const classes = useStyles();
  const history = useHistory()
  const {
    handleSignin,
    handleSigninEmailLink,
    inputs,
    setInputs,
    errors
  } = useContext(firebaseAuth);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignin();
    if(window.localStorage.getItem('token') !== null)
     history.push("/")
     else
     history.push("/forgotPassword")
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(inputs);
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitEmailLink = (e) => {
    e.preventDefault();
    console.log("handleSubmitEmailLink");
    handleSigninEmailLink();
  };

  // return (
  //   <>

  //     <form onSubmit={handleSubmit}>
  //       {/* replace the div tags with a form tag */}
  //       <p>Signin</p>
  //       {/* make inputs  */}
  //       <input
  //         onChange={handleChange}
  //         name="email"
  //         placeholder="email"
  //         value={inputs.email}
  //       />
  //       <input
  //         onChange={handleChange}
  //         name="password"
  //         placeholder="password"
  //         value={inputs.password}
  //       />
  //       <button>signin</button>
  //       {errors.length > 0
  //         ? errors.map((error) => (
  //             <>
  //               <p style={{ color: "red" }}>{error}</p>
  //               {/* <Signup /> */}
  //             </>
  //           ))
  //         : null}
  //     </form>

  //   </>
  // );
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgotPassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
            
            {errors.length > 0
             ? errors.map((error) => (
              <>
                <p style={{ color: "red" }}>{error}</p>
                {/* <Signup /> */}
              </>
            ))
          :null}
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default Signin;
