import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  subtitleSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    maxWidth: '70%',
    margin: 'auto',
    '@media (min-width: 1280px)': {
      maxWidth: '55%',
    },
  },
  subtitleText: {
    fontWeight: 'light',
    color: '#a6d4fa',
    fontSize: '0.875rem',
    textAlign: 'center',
    wordBreak: 'break-word',
  },
  link: {
    color: '#ff6347', // Change this to any color you like.
  },
}));

const SubtitleSection = ({ subtitle, url }) => {
  const classes = useStyles();

  return (
    <Box className={classes.subtitleSection}>
      <Typography variant="h6" className={classes.subtitleText}>
        {subtitle} {' '}
        <Link href={url} target="_blank" rel="noopener" className={classes.link}>
          LinkedIn
        </Link>
      </Typography>
    </Box>
  );
};

export default SubtitleSection;
