import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { exp, log, erf } from 'mathjs';


function D3CallOptionPlot(props) {
    const { stockPrice, strikePrice, interestRate, time, volatility } = props;
    const [showBeforeExpiry, setShowBeforeExpiry] = useState(false);
    const svgRef = useRef(null);  // Reference to our SVG canvas

    const width = 600;
    const height = 400;
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };

    const N = (x) => (1 + erf(x / Math.sqrt(2))) / 2;

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();
    
        const xBuffer = 0.75; 
    
        // Calculating premium based on Black-Scholes
        const d1 = (Math.log(stockPrice / strikePrice) + (interestRate + Math.pow(volatility, 2) / 2) * time) / (volatility * Math.sqrt(time));
        const d2 = d1 - volatility * Math.sqrt(time);
        const premium = stockPrice * N(d1) - strikePrice * Math.exp(-interestRate * time) * N(d2);
    
        const yMax = Math.max(stockPrice - strikePrice, premium * 1.5);
        const yMin = -premium * 1.5;
    
        const xScale = d3.scaleLinear()
            .domain([strikePrice * (1 - xBuffer), strikePrice * (1 + xBuffer)])
            .range([margin.left, width - margin.right]);
    
        const yScale = d3.scaleLinear()
            .domain([yMin, yMax])
            .range([height - margin.bottom, margin.top]);
    
        // X & Y axes styling
        const xAxis = d3.axisBottom(xScale).ticks(5).tickSize(-height);
        const yAxis = d3.axisLeft(yScale);
    
        svg.append("g")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis)
            .call(g => g.select(".domain").remove())
            .call(g => g.selectAll(".tick line").attr("stroke", "#888"))
            .call(g => g.selectAll(".tick text").attr("fill", "#ccc"));
    
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(yAxis)
            .call(g => g.select(".domain").remove())
            .call(g => g.selectAll(".tick line").attr("stroke", "#888"))
            .call(g => g.selectAll(".tick text").attr("fill", "#ccc"));
    
        // Drawing the payoff line
        const line = d3.line()
                       .x(d => xScale(d.x))
                       .y(d => yScale(d.y));
    
        const data = [];
    
        if (showBeforeExpiry) {
            for (let price = 0; price <= stockPrice * 2; price += 2) {
                const d1 = (Math.log(price / strikePrice) + (interestRate + Math.pow(volatility, 2) / 2) * time) / (volatility * Math.sqrt(time));
                const d2 = d1 - volatility * Math.sqrt(time);
                const callPrice = price * N(d1) - strikePrice * Math.exp(-interestRate * time) * N(d2) - premium;
                data.push({ x: price, y: callPrice });
            }
        } else {
            for (let price = 0; price <= stockPrice * 2; price += 2) {
                const payoff = Math.max(0, price - strikePrice) - premium;
                data.push({ x: price, y: payoff });
            }
        }
        
    
        svg.append("line")
            .attr("x1", margin.left)
            .attr("y1", yScale(0))
            .attr("x2", width - margin.right)
            .attr("y2", yScale(0))
            .attr("stroke", "#ccc")
            .attr("stroke-dasharray", "2,2");
    
        svg.append("path")
           .datum(data)
           .attr("fill", "none")
           .attr("stroke", "steelblue")
           .attr("stroke-width", 1.5)
           .attr("d", line);


           svg.append('path')
           .attr('d', d3.symbol().type(d3.symbolTriangle))
           .attr('transform', `translate(${xScale(stockPrice)}, ${yScale(0)})`)
           .attr('fill', '#FF5733')
           .attr('stroke', '#FF5733');
        
        svg.append("text")
           .attr("x", xScale(stockPrice))
           .attr("y", yScale(0) - 10)
           .attr("dy", "-1.25em")
           .attr("text-anchor", "middle")
           .attr("fill", "#FF5733")
           .text("Stock Price");
        
        svg.append('path')
           .attr('d', d3.symbol().type(d3.symbolTriangle))
           .attr('transform', `translate(${xScale(strikePrice)}, ${yScale(0)})`)
           .attr('fill', '#33FF57')
           .attr('stroke', '#33FF57');
        
        svg.append("text")
           .attr("x", xScale(strikePrice))
           .attr("y", yScale(0) - 10)
           .attr("dy", "-1.25em")
           .attr("text-anchor", "middle")
           .attr("fill", "#33FF57")
           .text("Strike Price");
        
        // Append circle for hover effect
        const hoverCircle = svg.append("circle")
            .attr("r", 5)
            .attr("stroke", "steelblue")
            .attr("fill", "white")
            .attr("stroke-width", 1.5)
            .style("display", "none");

           // Append text for hover effect
            const hoverText = svg.append("text")
                .attr("font-size", "10px")
                .attr("dy", "-1em")
                .style("display", "none")
                .attr("text-anchor", "middle")
                .attr("fill", "white");
                    
           

                    // Mouse events
            svg.on("mousemove", function(event) {
                const [mx, my] = d3.pointer(event);
                const xVal = xScale.invert(mx);
                let yVal;
                if (showBeforeExpiry) {
                    const d1 = (Math.log(xVal / strikePrice) + (interestRate + Math.pow(volatility, 2) / 2) * time) / (volatility * Math.sqrt(time));
                    const d2 = d1 - volatility * Math.sqrt(time);
                    yVal = xVal * N(d1) - strikePrice * Math.exp(-interestRate * time) * N(d2) - premium;
                } else {
                    if (xVal < strikePrice) {
                        yVal = -premium;
                    } else {
                        yVal = Math.max(0, xVal - strikePrice) - premium;
                    }
                }

                hoverCircle
                    .attr("cx", xScale(xVal))
                    .attr("cy", yScale(yVal))
                    .style("display", null);

                hoverText
                    .attr("x", xScale(xVal))
                    .attr("y", yScale(yVal))
                    .text(yVal.toFixed(2))
                    .style("display", null);
            });


            svg.on("mouseout", function() {
                hoverCircle.style("display", "none");
                hoverText.style("display", "none");
            });
            
    
    }, [stockPrice, strikePrice, interestRate, time, volatility, showBeforeExpiry]);
    

    return (
        <div>
            <input
                type="checkbox"
                checked={showBeforeExpiry}
                onChange={(e) => setShowBeforeExpiry(e.target.checked)}
            />
            {" "}Show payoff before expiry

            <svg ref={svgRef} width={width} height={height} />
        </div>
    );
}

export default D3CallOptionPlot;
