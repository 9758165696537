import React from 'react';
import Typography from '@mui/material/Typography';
import { FaHourglassHalf } from 'react-icons/fa';

const TimeEstimate = ({ time }) => (
  <Typography variant="body1" color="#6b6b6b">
    <FaHourglassHalf color="#A9A9A9" /> Estimated Time: {time} minutes
  </Typography>
);

export default TimeEstimate;
