import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import ReactCardFlip from 'react-card-flip';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  flipCard: {
    height: '100%',
    cursor: 'pointer',
  },
  flipCardFront: {
    backgroundColor: '#222932', // dark navy blue
    color: 'white', // bright blue
  },
  flipCardBack: {
    backgroundColor: '#161B22', // slightly lighter navy blue
    color: '#FFFFFF', // white
  },
  title: {
    fontWeight: 300, // Lighter font-weight
  },
}));

const FlipCardGrid = ({ cardsContent }) => {
  const classes = useStyles();
  const [flipIndex, setFlipIndex] = useState(null);

  return (
    <Grid container spacing={3} className={classes.gridContainer}>
      {cardsContent.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ReactCardFlip isFlipped={flipIndex === index} flipDirection="vertical">
            <Card 
              onClick={() => setFlipIndex(index)} 
              className={`${classes.flipCard} ${classes.flipCardFront}`}
            >
              <CardContent>
                <Typography gutterBottom variant="body1" component="h2" className={classes.title}>
                  {card.title}
                </Typography>
              </CardContent>
            </Card>

            <Card 
              onClick={() => setFlipIndex(null)} 
              className={`${classes.flipCard} ${classes.flipCardBack}`}
            >
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {card.content}
                </Typography>
              </CardContent>
            </Card>
          </ReactCardFlip>
        </Grid>
      ))}
    </Grid>
  );
};

export default FlipCardGrid;
