import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, Typography, CardMedia, Button, CardActions } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        maxWidth: '50%',
        backgroundColor: '#333',  // Dark theme color
        color: 'white',
        margin: 'auto',
        marginBottom: '2em',
        paddingTop: '1em',  // Adjust the padding here
        '@media (max-width:600px)': {
            maxWidth: '100%',
        },
    },
    scrollContainer: {
        overflowX: 'auto',
        whiteSpace: 'normal',
        '@media (min-width:600px)': {
            overflowX: 'hidden',
        },
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
        marginLeft: '2em', // margin to the left
        marginRight: '2em', // margin to the right
    },
    description: {
        wordWrap: 'break-word',
    },
    moreDetailsButton: {
        backgroundColor: '#757575', // A gray color
        color: '#fff', // White text color for the button
        '&:hover': {
            backgroundColor: '#616161', // Darken the color a bit when hovered
        }
    },
    cardActions: {
        justifyContent: 'center'  // Center the items
    }
});

export default function ImgMediaCard({ image, title, description, link }) {
    const classes = useStyles();

    return (
        <div className={classes.scrollContainer}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia className={classes.media} image={image} title={title} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                {link && 
                <CardActions className={classes.cardActions}>
                    <Button size="small" className={classes.moreDetailsButton} href={link} target="_blank">
                        Go To Interactive
                    </Button>
                </CardActions>
                }
            </Card>
        </div>
    );
}
