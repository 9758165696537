import React, { useState, useEffect, useRef } from 'react';
import MyDraggableComponent from './DraggableComponent';
import { FaVideo, FaMusic, FaLink } from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';

const Marker = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const intervalRef = useRef();

  useEffect(() => {
    // Delay effect
    const timeoutId = setTimeout(() => {
      // Blinking effect
      intervalRef.current = setInterval(() => {
        setIsVisible(v => !v);
      }, 5500);
    }, 50000); // 2 seconds delay

    // Clean-up function
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      clearTimeout(timeoutId);
    };
  }, []);

  const handleMarkerClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="marker" style={{
        position: 'absolute',
        left: props.left,
        top: props.top,
        backgroundColor: isVisible ? '#ff5c5c' : 'transparent',
        borderRadius: '50%', 
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '60px',
        height: '60px',
        flexDirection: 'column' // To stack number and title vertically
      }} onClick={handleMarkerClick}>
        <Typography variant="h5" style={{ color: 'white' }}>{props.text}</Typography>
        {props.title && <Typography variant="caption" style={{ color: 'white' }}>{props.title}</Typography>} {/* Adding a title */}
        {isModalOpen && (
          <MyDraggableComponent setIsModalOpen={setIsModalOpen}>
            {props.children}
          </MyDraggableComponent>
        )}
        {props.type === 'video' && <FaVideo />}
        {props.type === 'audio' && <FaMusic />}
        {props.type === 'link' && <FaLink />}
      </div>
    </>
  );
};

export default Marker;
