import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  videoPlayer: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsContainer: {
    overflowY: 'scroll',
    maxHeight: '25vh',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: '#FFD400',
      color: 'black',
    },
  },
}));

const VideoPlayer = ({ buttons, width = "100%", height="80%", url }) => {
  const classes = useStyles();
  const ref = useRef(null)
  const [playing, setPlaying] = useState(false);

  function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  function minutesToSeconds(n) {
    var minutes = Math.floor(n);
    var seconds = (n % 1) * 100

    return (Math.round(seconds) + minutes * 60)
  }

  return (
    <Box className={classes.videoPlayer} width={width}>
      <ReactPlayer
        ref={ref}
        url={url}
        playing={playing}
        controls
        config={{ file: { attributes: { id: 'audio-element' } }, playerVars: {} }}
        width="100%"
        height={height}
      />
      {buttons && buttons.length > 0 && (
        <>
          <Typography variant="body1">Navigate</Typography>
          <Box className={classes.buttonsContainer}>
            {buttons.map((button, index) => (
              <Button variant="outlined" key={index} onClick={() => {ref.current.seekTo(minutesToSeconds(button.time)); setPlaying(true);}} className={classes.button}>
                {button.label} {button.time}
              </Button>
            ))}
          </Box>
        </>
      )}
    </Box>
  )
}

export default VideoPlayer
