import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Paper, Typography } from '@material-ui/core';

const initialCards = [
  { id: '1', text: 'You might also like...' },
  { id: '2', text: 'Email spam filters' },
  // Add more cards here...
];

const initialColumns = {
  'column-1': {
    id: 'column-1',
    title: 'Stack',
    cardIds: initialCards.map((card) => card.id),
  },
  'column-2': {
    id: 'column-2',
    title: 'Discriminative AI',
    cardIds: [],
  },
  'column-3': {
    id: 'column-3',
    title: 'Generative AI',
    cardIds: [],
  },
};

const DragAndDropGame = () => {
  const [columns, setColumns] = useState(initialColumns);
  const [cards, setCards] = useState(initialCards);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const startColumn = columns[source.droppableId];
    const endColumn = columns[destination.droppableId];

    if (startColumn === endColumn) {
      const newCardIds = Array.from(startColumn.cardIds);
      newCardIds.splice(source.index, 1);
      newCardIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...startColumn,
        cardIds: newCardIds,
      };

      setColumns({
        ...columns,
        [newColumn.id]: newColumn,
      });
    } else {
      const startCardIds = Array.from(startColumn.cardIds);
      startCardIds.splice(source.index, 1);
      const newStartColumn = {
        ...startColumn,
        cardIds: startCardIds,
      };

      const endCardIds = Array.from(endColumn.cardIds);
      endCardIds.splice(destination.index, 0, draggableId);
      const newEndColumn = {
        ...endColumn,
        cardIds: endCardIds,
      };

      setColumns({
        ...columns,
        [newStartColumn.id]: newStartColumn,
        [newEndColumn.id]: newEndColumn,
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {Object.keys(columns).map((columnId) => {
        const column = columns[columnId];
        const columnCards = column.cardIds.map((cardId) =>
          cards.find((card) => card.id === cardId)
        );

        return (
          <Droppable droppableId={column.id} key={column.id}>
            {(provided) => (
              <Paper
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ margin: '16px', padding: '16px' }}
              >
                <Typography variant="h6">{column.title}</Typography>
                {columnCards.map((card, index) => (
                  <Draggable key={card.id} draggableId={card.id} index={index}>
                    {(provided) => (
                      <Paper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          margin: '8px',
                          padding: '8px',
                        }}
                      >
                        <Typography variant="body1">{card.text}</Typography>
                      </Paper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Paper>
            )}
          </Droppable>
        );
      })}
    </DragDropContext>
  );
};

export default DragAndDropGame;
