import React, { useState } from 'react';
import { Dialog, DialogContent, IconButton, Typography, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
  dialog: {
    border: '2px solid white',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  marker: {
    position: 'absolute',
    left: props => props.left,
    top: props => props.top,
    backgroundColor: '#4CAF50',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    border: '2px solid #4CAF50',
    '&:hover': {
      backgroundColor: '#EAAA00',
      color: 'black',
    },
  },
}));

const MarkerDialog = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMarkerClick = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={classes.marker} onClick={handleMarkerClick}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>{props.text}</Typography>
      </div>

      <Dialog fullScreen open={isModalOpen} onClose={handleClose} classes={{paper: classes.dialog}}>
        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" className={classes.closeButton}>
          <CloseIcon />
          <Typography variant="h6" style={{ color: theme.palette.common.white }}>Close</Typography>
        </IconButton>
        <DialogContent>
          {props.children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MarkerDialog;
