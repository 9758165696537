
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import SoundCloudPlayer from "../../../components/SoundCloudPlayer";
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const ImgMediaCard = makeShortcode("ImgMediaCard");
const layoutProps = {
  ggbParameters2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Header mdxType="Header">About us</Header>
    <Header mdxType="Header">1. 2014: Introduction of GANs</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN_Intro.png" title="The GANfather:Ian Goodfellow" description="The concept of GANs was first introduced by Ian Goodfellow and his colleagues at the University of Montreal in a paper titled ]Generative Adversarial Nets,' presented at the Neural Information Processing Systems conference. This started a new era in the field of generative models." link="https://www.technologyreview.com/2018/02/21/145289/the-ganfather-the-man-whos-given-machines-the-gift-of-imagination/" mdxType="ImgMediaCard" />
    <SoundCloudPlayer src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/326749576&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" html='<a href="https://soundcloud.com/theaipodcast" title="The AI Podcast" target="_blank" style="color: #cccccc; text-decoration: none;">The AI Podcast</a> · <a href="https://soundcloud.com/theaipodcast/what-are-generative-adversarial-networks-ian-goodfellow-explains" title="Ep. 25: Google&#x27;s Ian Goodfellow on How an Argument in a Bar Led to Generative Adversarial Networks" target="_blank" style="color: #cccccc; text-decoration: none;">Ep. 25: Google&#x27;s Ian Goodfellow on How an Argument in a Bar Led to Generative Adversarial Networks</a>' mdxType="SoundCloudPlayer" />
    <br />
    <br />
    <ImgMediaCard image="https://i0.wp.com/analyticsarora.com/wp-content/uploads/2021/07/DCGAN-deep-convolutional-generator-architecture-diagram.png?resize=638%2C479&ssl=1" title="2015: Deep Convolutional GAN (DCGAN)" description="Radford et al. proposed DCGANs, which combined Convolutional Neural Networks (CNNs) with GANs, achieving impressive results in generating realistic images and offering a stable architecture for training GANs." link="https://www.technologyreview.com/2018/02/21/145289/the-ganfather-the-man-whos-given-machines-the-gift-of-imagination/" mdxType="ImgMediaCard" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;