import React from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const ButtonMailto = ({ mailto, label, left = "25%", top = "10%" }) => {
  return (
    <Box sx={{ 
      display: "flex" ,
      alignItems: "center",
      justifyContent: 'center',
      // margin: '22px',
      // width: '160px',
      position: "absolute",
              left: left,
              top: top
      }}>
      <Link
          to='#'
          onClick={(e) => {
              window.location = mailto;
              e.preventDefault();
          }}
          style={{ textDecoration: 'none' }}
      >
   
          <Box marginTop={2} display={'flex'} justifyContent={'center'} sx={{width: '100px'}}>
          <Button
            color={'primary'}
            variant={'contained'}
            size={'small'} // Change the size to 'large'
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                width={20}
                height={20}
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            }
            style={{
              width: "100%",
              // position: "absolute",
              // left: left,
              // top: top
            }}
          >
            {label}
          </Button>
        </Box>
      </Link>
  </Box>
  );
};


export default ButtonMailto;
