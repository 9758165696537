import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  policyText: {
    color: theme.palette.text.secondary,
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <Container>
      <Typography variant="h4" gutterBottom className={classes.policyText}>
        Privacy Policy
      </Typography>

      <Typography variant="body1" paragraph className={classes.policyText}>
        Your privacy is important to us. It is DaVinci Dynamics's policy to respect your privacy regarding any information we may collect from you across our website, LinkedIn, and other sites we own and operate.
      </Typography>

      <Typography variant="h6" gutterBottom className={classes.policyText}>
        1. Information we collect
      </Typography>
      <Typography variant="body1" paragraph className={classes.policyText}>
        We collect information to provide better services to all our users...
        We collect information in the following ways:
        <br/><br/>
Information you give us. For example, many of our services require you to sign up for an account. When you do, we’ll ask for personal information, like your name, email address, or telephone number.
<br/><br/>
Information we get from your use of our services. We may collect information about the services that you use and how you use them, like when you visit a specific part of our site or when you use our services.
<br/><br/>
How we use information we collect
<br/>
We use the information we collect to provide, maintain, protect and improve our services, to develop new ones, and to protect [Your Company Name] and our users.
<br/><br/>
<br/>
Transparency and choice
<br/>
People have different privacy concerns. Our goal is to be clear about what information we collect, so you can make meaningful choices about how it is used.
<br/>
<br/>
Information you share
<br/>
<br/>
Our services let you share information with others. Remember that when you share information publicly, it may be indexable by search engines.
<br/>
<br/>
Accessing and updating your personal information
<br/><br/>
We aim to provide you with access to your personal information. If that information is wrong, we strive to give you ways to update it quickly or to delete it – unless we have to keep that information for legitimate business or legal purposes.
<br/><br/>
Changes
<br/><br/>
Our Privac Policy may change from time to time. We will post any policy changes on this page and, if the changes are significant, we will provide a more prominent notice.
<br/><br/>
      </Typography>

      {/* Add other sections here in similar fashion */}

      <Typography variant="body1" paragraph className={classes.policyText}>
        This policy is effective as of June 20, 2023.
      </Typography>

      <Typography variant="body1" paragraph className={classes.policyText}>
        If you have any questions about how we handle user data and personal information, feel free to contact us.
      </Typography>

      <Typography variant="body1" paragraph className={classes.policyText}>
        office@davinci-dynamics.com
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
