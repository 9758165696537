import { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { Box } from '@material-ui/core';

const getScatteredPosition = (index) => {
    const row = Math.floor(index / 3);
    const col = index % 3;

    const top = 10 + row * 40; // Start from 10% top offset and increase 40% for each new row
    const left = col * 30; // 30% horizontal offset for each card in a row

    return {
        position: 'absolute',
        top: `${top}%`,
        left: `${left}%`
    };
};

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const ImageCard = ({ id, name, handleDrop, imageUrl, matchedItems, position }) => {
    const [backgroundColor, setBackgroundColor] = useState('#1e1e1e');
    const [dragHereVisible, setDragHereVisible] = useState(true);
  
    const [, drop] = useDrop({
      accept: "item",
      drop: (item) => {
        if (item.id === id) {
          setBackgroundColor('green');
          setDragHereVisible(false);
          handleDrop(id, item.id);
        }
      },
    });
  
    return (
      <Card ref={drop} style={{...position, width: '200px'}}>
        <CardContent>
          <img src={imageUrl} alt={name} style={{width: '160px', height: '160px'}}/>
          <Typography>{name}</Typography>
          <Box style={{ backgroundColor: backgroundColor, padding: '5px', marginTop: '5px', borderRadius: '5px', height: '40px'}}>
            {dragHereVisible && <Typography style={{color: 'white'}}>Drag here</Typography>}
            {matchedItems.map((item, index) => (
              <Typography key={index} style={{color: 'white'}}>{item.name}</Typography>
            ))}
          </Box>
        </CardContent>
      </Card>
    );
  };
  

  const ItemCard = ({ id, name, type, position }) => {
    const [{ isDragging }, drag] = useDrag({
      type: "item",
      item: { id, name, type },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });
  
    return (
      <Card ref={drag} 
        style={{
          ...position,
          opacity: isDragging ? 0.5 : 1, 
          width: 'auto', 
          minWidth: '100px', 
          maxWidth: '150px', 
          wordWrap: 'break-word' 
        }}>
        <CardContent>
          <Typography>{name}</Typography>
          <DragIndicatorIcon style={{color: 'gray'}}/>
        </CardContent>
      </Card>
    );
  };
  

const MatchingGame = ({ data }) => {
    const [matchedItems, setMatchedItems] = useState({});
    const [positions, setPositions] = useState({});
  
// Fisher-Yates (aka Knuth) Shuffle
function knuthShuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
}

useEffect(() => {
    const newPositions = {};
    // Shuffle the order for items and images separately
    const shuffledItems = shuffleArray([...data.items]);
    const shuffledImages = shuffleArray([...data.images]);
    
    shuffledItems.forEach((item, index) => {
      newPositions[item.id] = getScatteredPosition(index);
    });
  
    shuffledImages.forEach((image, index) => {
      newPositions[image.id] = getScatteredPosition(index + shuffledItems.length);
    });
  
    setPositions(newPositions);
  }, [data]);
  

  const handleDrop = (imageId, itemId) => {
    if (imageId === itemId) {
      setMatchedItems(prevState => {
        return {
          ...prevState,
          [imageId]: [...prevState[imageId] || [], data.items.find(item => item.id === itemId)]
        };
      });
    }
  };

  const unmatchedItems = data.items.filter(item => !Object.values(matchedItems).flat().some(i => i.id === item.id));

  return (
    <div style={{ display: 'flex', width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: '#1F1F1F' }}>
      <div style={{ position: 'relative', width: '40%', height: '100%' }}>
        {unmatchedItems.map((item) => (
          <div style={positions[item.id]}>
            <ItemCard key={item.id} {...item} />
          </div>
        ))}
      </div>
      <div style={{ position: 'relative', width: '60%', height: '100%' }}>
        {data.images.map((image) => (
          <div style={positions[image.id]}>
            <ImageCard key={image.id} {...image} handleDrop={handleDrop} matchedItems={matchedItems[image.id] || []} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatchingGame;
