import React, { useEffect, useState, lazy, Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Reviews from "./components/Reviews.js";
import ProfileCards from "./components/ProfileCards.js";

import BookCardsOptions from "./components/options/BookCardsOptions.js";
import NikeDesign from "./components/options/NikeDesign.js";

import VideoChapterIntros from "./components/VideoChapterIntros.js";

import BookCardsStatistics from "./components/BookCardsStatistics.js";

import Link from "@mui/material/Link";

import ReactPlayer from "react-player";

import Heading from "./components/Heading";
import AIBanner from "./components/AIBanner.js";

import SignUp from "./components/SignUp";
import SignInMagicLink from "./components/SignInMagicLink";
import MagicLinkWait from "./components/MagicLinkWait";

import SignIn from "./components/SignIn";
import Admin from "./components/Admin";

import { loadStripe } from "@stripe/stripe-js";
import ForgotPassword from "./components/ForgotPassword";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Contact from "./components/thefront/Contact";
import ModalSignup from "./components/ModalSignup";
import BlinkingButton from "./components/BlinkingButton";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonMailto from "./components/ButtonMailto.js";
import Hero from "./components/Hero.js";
import HeroCopy from "./components/HeroCopy.js";
import HoverImage from "./components/HoverImage.js";
import PrivacyPolicy from "./components/PrivacyPolicy.js";

import FlipCardGrid from "./components/FlipCardGrid.js";
import TitleSection from "./components/TitleSection.js";
import SubtitleSection from "./components/SubtitleSection.js";

import AnchorPlayground from "./components/AnchorPlayground";
import YoutubeLikeCard from "./components/homepage/YoutubeLikeCard";

import SOFR from "!babel-loader!@mdx-js/loader!./pages_posts/posts/SOFR.mdx";
import OptimizationBox from "!babel-loader!@mdx-js/loader!./pages_posts/posts/OptimizationBox.mdx";
import QuadraticFunction from "!babel-loader!@mdx-js/loader!./pages_posts/posts/QuadraticFunction.mdx";
import InteractiveMathematics from "!babel-loader!@mdx-js/loader!./pages_posts/posts/InteractiveMathematics.mdx";
import InteractiveML from "!babel-loader!@mdx-js/loader!./pages_posts/posts/InteractiveML.mdx";

import AIConsulting from "!babel-loader!@mdx-js/loader!./pages_posts/posts/AIConsulting.mdx";
import TennisAI from "!babel-loader!@mdx-js/loader!./pages_posts/posts/TennisAI.mdx";
import About_Us from "!babel-loader!@mdx-js/loader!./pages_posts/posts/About_Us.mdx";

import MyPdfViewer from "./components/PDFViewer";
import ImageCard from "./components/ImageCard";
import SimpleDialog from "./components/SimpleDialog";
// const About = lazy(() => importMDX('./pages/About.mdx'));
import YouTube from "react-youtube";

import CardsForBlogs from "./CardsForBlogs";
import AppHeader from "./components/AppHeader";

const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
import Marker from "./components/ImageMarkers";
import ContactUs from "./components/ContactUs";
import Container from "./layouts/Container";

import Activations from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Activations.mdx";
import BackPropagation_Buckets from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/BackPropagation_Buckets.mdx";
import BackPropagation from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/BackPropagation.mdx";
import DotProduct from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/DotProduct.mdx";
import MultivariateGaussian from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/MultivariateGaussian.mdx";
import VAE_QUIZ from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/VAE_QUIZ.mdx";
import OptionsD3 from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/OptionsD3.mdx";
import VAE_FlipBook from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/VAE_FlipBook.mdx";

import GAN_QUIZ from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/GAN_QUIZ.mdx";
import Embeddings from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Embeddings.mdx";

import KL_Divergence from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/KL_Divergence.mdx";

import Gradient from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Gradient.mdx";
import LogisticRegression from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/LogisticRegression.mdx";
import PartialDerivatives from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/PartialDerivatives.mdx";
import Perceptron from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Perceptron.mdx";
import SingleLayerPerceptron from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/SingleLayerPerceptron.mdx";
import ActivationFunctions from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/ActivationFunctions.mdx";
import DActivationFunctions from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/DActivationFunctions.mdx";

import LogisticRegressionLearnPath from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/LogisticRegressionLearnPath.mdx";
import ImgMediaCard from "./components/ImgMediaCard";
import VideoMediaCard from "./components/VideoMediaCard";

import Transformers from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Transformers.mdx";
import VAE from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/VAE.mdx";
import GAN from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/GAN.mdx";
import LLM from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/LLM.mdx";
import RAG from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/RAG.mdx";

import Quantization from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Quantization.mdx";
import Granville from "!babel-loader!@mdx-js/loader!./pages_posts/posts/ML/Granville.mdx";

import AskBot from "!babel-loader!@mdx-js/loader!./pages_posts/posts/AskBotMDX.mdx";

const darkTheme = createTheme({
  palette: {
    type: "dark",
  },
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
    },
  },
});

export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24,
  },
};

export const Box1 = () => (
  <div style={{ padding: 20, width: "100%" }}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} />
    <br />
    <Link
      href="https://options.21ifm.com/sofr"
      underline="none"
      target="_blank"
      rel="noopener noreferrer"
    >
      {" "}
      Open Interactive
    </Link>
  </div>
);

const componentsMap = {
  h1: Heading.h3, // mapping h3 to h1 so we're having a smaller font-size
  h2: Heading.h4,
};

function SubHeading(props) {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            // mt: 250,
            bgcolor: "#1e1e1e",
            width: 100,
            p: 1,
            // m: 1,
            borderRadius: 1,
            textAlign: "center",
          }}
        >
          <Typography variant="h3" gutterBottom component="div" color="#90caf9">
            {props.title}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export const Intro_Videos = (props) => (
  <div
    style={{
      padding: 0,
      backgroundColor: "#1e1e1e",
      width: "100%",
      marginTop: "50px",
      margin: "auto",
      zIndex: 9999,
    }}
  >
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} />
    <br />
    <Link
      style={{ zIndex: 1000000000 }}
      href={props.link}
      underline="none"
      target="_blank"
      rel="noopener noreferrer"
    >
      Open Interactive
    </Link>
  </div>
);

const appStyles = {
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
};

const items_intro = [
  {
    title: "Davinci Dynamics",
    subtitle:
      "Curiosity is a superpower, sparking the flame of knowledge and innovation. Immerse yourself in interactive mathematics explorations where art and engineering unite",
    backgroundImage:
      "https://storage.googleapis.com/chapters-ifm/testfolder/PERCEPTRON/PAVAN_COVER.png",
    link: "int_math",
    video:
      "https://storage.googleapis.com/chapters-ifm/ML/2023/DAVINCI_DYNAMICS_INTRO.mp4",
  },
];

const items_davinco = [
  {
    title: "Interactive Learning Platform",
    subtitle:
      "Specialized in mathematics, analytical topics, and machine learning topics",
    backgroundImage:
      "https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/perceptron_page_dim.png",
    link: "davinci-dynamics.com/int_math",
    video: "https://www.youtube.com/watch?v=XxcbjSQFQwI",
  },
  {
    title: "Interactive Options book ",
    subtitle:
      "Learn advanced options strategies with interactive explainers.Simulate price, volatility and time. Advanced calculus and statistics essential for option explained with interactive visualizations",
    backgroundImage:
      "https://storage.googleapis.com/chapters-ifm/ML/2023/Rick4.png",
    link: "options.21ifm.com",
    video:
      "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/video_linkedin_3.mp4",
  },
];

const items_ai = [
  {
    title: "AI and Data Science Consulting",
    subtitle:
      "Empowering Businesses with AI Innovation. Why choose AI for your Business?",
    backgroundImage:
      "https://storage.googleapis.com/chapters-ifm/testfolder/PERCEPTRON/PAVAN_COVER.png",
    link: "davinci-dynamics.com/ai_consulting",
    video: "",
  },
  // {
  //   title:
  //     "Intelligent Tennis Assistant",
  //   subtitle: " Elevate your game, hone your skills",
  //   backgroundImage:
  //     "https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/Federer_DVD.png",
  //   link: "",
  //   video:'https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/People_TennisAI.mp4'
  // },
];

const products = [
  {
    name: "Tennis Intelligent Assistant",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    link: "/products/tennis-intelligent-assistant",
  },
  {
    name: "AI for Finance",
    description:
      "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: "/products/ai-for-finance",
  },
  {
    name: "Interactive Learning Platform",
    description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
    link: "/products/interactive-learning-platform",
  },
];

const nodes = [
  { name: "Product A", link: "/product-a", top: 30, left: 30 },
  { name: "Product B", link: "/product-b", top: 30, left: 70 },
  { name: "Product C", link: "/product-c", top: 70, left: 70 },
  { name: "Product D", link: "/product-d", top: 70, left: 30 },
];

const items_options = [
  {
    title: "Interactive Options Book",
    subtitle:
      "Learn advanced options strategies with interactive explainers.Simulate price, volatility and time. Advanced calculus and statistics essential for option explained with interactive visualizations",
    backgroundImage:
      "https://storage.googleapis.com/chapters-ifm/ML/2023/Rick4.png",
    link: "options.21ifm.com",
    video:
      "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/video_linkedin_3.mp4",
  },
];

const cardsContent = [
  {
    title: "Curiosity and Questioning",
    content:
      "Leonardo da Vinci was famously curious, continually asking questions about the world around him. Similarly, our platform encourages users to stay curious, fostering an environment that inspires questions and fuels discovery.",
  },
  {
    title: "Mental Models and Foundation in the Basics",
    content:
      "Da Vinci firmly believed in having a strong foundation in the basics before branching into more complex concepts. Our platform mirrors this approach, building a strong foundation for users before diving into more complex topics.",
  },
  {
    title: "Learning from Experience",
    content:
      "Leonardo was a strong advocate for experiential learning. He believed that our senses are our windows to the world, and that the more closely we observe our world, the more we can learn. Our platform provides interactive experiences to enhance learning and make it more engaging.",
  },
  {
    title: "Visual Thinking",
    content:
      "Being an artist, Leonardo used visual thinking as a major part of his learning and understanding. We incorporate visual elements extensively in our platform, helping users to visualize complex concepts, hence enhancing understanding.",
  },
  {
    title: "Detail Oriented",
    content:
      "Leonardo's attention to detail was legendary. He believed that every detail tells a story. We adopt the same philosophy in our platform, creating detailed and comprehensive content for our users.",
  },
  {
    title: "Interdisciplinary Learning",
    content:
      "Leonardo saw the world as an interconnected whole and believed that understanding the connections between things was crucial to understanding the world. In our platform, we connect different concepts and disciplines, fostering an interconnected learning experience.",
  },
];

const cardsContentServices = [
  {
    title: "Custom Interactive Explainers",
    content:
      "We create bespoke interactive explainers for institutions, providing an engaging and memorable learning experience for students. These explainers can be seamlessly integrated with any Learning Management System (LMS).",
  },
  {
    title: "White Label Solutions",
    content:
      "We offer white-label solutions for business schools and professors. This enables them to provide their students with our advanced learning tools, branded under their own name. It enhances their course delivery and adds a unique educational value.",
  },
  {
    title: "Curriculum Transformation",
    content:
      "Our services can completely transform a school curriculum, including International Baccalaureate programmes, into interactive learning modules. This provides students with a uniform and structured learning experience.",
  },
  {
    title: "Consistent and Structured Learning Experience",
    content:
      "Our tool aids teachers by offering a consistent format for teaching. This helps maintain the quality of education, makes lesson planning easier, and ensures that every student gets the same comprehensive learning experience.",
  },
  {
    title: "Interactive Learning Platform",
    content:
      "Learning becomes exciting when it is interactive. Our tools are designed to make the learning process more engaging, encouraging students to explore, ask questions, and understand concepts in depth.",
  },
];

let blogs_chain;
export default function MathApp() {
  const [isDrawerOpen, SetIsDrawerOpen] = useState(false);

  let mathTopicsJSON = [];

  try {
    mathTopicsJSON = require(`./pages_posts`).default();
    blogs_chain = _.chain(mathTopicsJSON)
      // Group the elements of Array based on `color` property
      .groupBy("category")
      // `key` is group's name (color), `value` is the array of objects
      .map((value, key) => ({ category: key, blogs: value }))
      .value();
    console.log(blogs_chain);
  } catch (err) {}

  const handleDrawerClick = () => {
    // SetIsDrawerOpen(false);
    SetIsDrawerOpen((prevCheck) => !prevCheck);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Switch>
          {mathTopicsJSON.map(({ id, Component }) => (
            <Route key={id} path={"/" + id}>
              {Component}
            </Route>
          ))}
          <Route exact path="/">
            {/* <AppHeader handleDrawerToggle={handleDrawerClick}/> */}
            {/* <div style={{marginTop: "100px"}}></div> */}
            {blogs_chain.length > 0 && (
              <div>
                {/* <YoutubeLikeCard/> */}

                {/* <Hero/> */}
                {/* <VideoChapterIntros/> */}
                {/* <Stack direction={"row"} >
            <SlidesStepper/>
            <SlidesStepper/>
           </Stack> */}

                {/* <BookCardsOptions/> */}
                {/* <Intro/> */}

                <br />
                <br />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <ModalSignup/> */}
                </Box>
                {/* <Reviews/> */}

                {/* <BookCardsStatistics/> */}
                <AppHeader />

                <br />
                <br />

                <br />

                <AIBanner
                  titleLine1="We develop AI Applications"
                  titleLine2="for Small to Medium Enterprises"
                  description="Our key AI offerings include:"
                />

                <br />

                <br />

                <VideoMediaCard
                  videoUrl="https://storage.googleapis.com/chapters-ifm/LinearAlgebra/LEGACYAI.mp4"
                  title="Transforming Legacy Systems"
                  description="Revolutionize Legacy Operations. Transition from traditional command line operations to intuitive natural language prompts. Elevate user experiences and optimize system interactions with AI."
                  link=""
                />

                <br />

                <br />

                <VideoMediaCard
                  videoUrl="https://storage.googleapis.com/chapters-ifm/LinearAlgebra/PrivateGPT2.mp4"
                  title="Creation of Fine-Tuned Models (Private GPT)"
                  description="Craft a personalized AI model tailored to your unique needs. Ensure accurate, on-point responses with your very own GPT, optimized for precision and context."
                  link=""
                />

                <br />

                <br />

                <VideoMediaCard
                  videoUrl="https://storage.googleapis.com/chapters-ifm/LinearAlgebra/ForecastingAI.mp4"
                  title="Advanced Forecasting and Context aware Recommendations"
                  description="Harness the power of text-driven insights for actionable forecasting and analytics. Make informed decisions with our advanced predictive capabilities."
                  link=""
                />

                <br />

                <br />

                <br />

                <br />

                <br />

                <br />

                <AIBanner
                  titleLine1="We Develop Interactive Digital Books"
                  titleLine2="On Financial Mathematics & ML"
                  description="Explore our published works:"
                />
                <br />

                <br />

                <br />

                <ImgMediaCard
                  image="https://storage.googleapis.com/chapters-ifm/LinearAlgebra/GENAI.png"
                  title="Digital Book on covering Transformers, VAEs and GANs "
                  description="Discover the world of Generative AI through an interactive and engaging experience unlike any other."
                  link="/int_ml"
                />

                <br />

                <br />

                <br />

                <VideoMediaCard
                  videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/video_linkedin_3.mp4"
                  title="Mastering Financial Options: An Advanced Guide"
                  description="Learn advanced options strategies with interactive explainers.Simulate price, volatility and time. Advanced calculus and statistics essential for option explained with interactive visualizations."
                  link="http://options.21ifm.com"
                />

                {/* <About_Us /> */}

                {/* <div style={appStyles}>
                  <HeroCopy items={items_ai} />
                </div>
              <br/>
              <br/> */}

                {/* <br/>
              <br/>
              <br/>
              <div style={appStyles}>
                  <HeroCopy items={items_davinco} />
                </div>
              <br/>
              <br/>
              <br/>
              <br/> */}

                {/* <TitleSection title="Guiding Principles Inspired by Da Vinci" />
                <br/>
                <FlipCardGrid cardsContent={cardsContent} />; */}
                <AIBanner titleLine1="About us" titleLine2="" description="" />

                <br />

                <br />

                <br />

                <ImgMediaCard
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/Aboutus_4.png"
                  title="Our approach isn't one-size-fits-all."
                  description="We empathize deeply with the challenges faced by CTOs, data scientists, and other tech leaders.We meticulously design solutions based on your specific needs, ensuring they're truly customized. "
                  link=""
                />

                <ContactUs />

                <Box
                  sx={{
                    width: "100%",
                    position: "fixed",
                    top: "2%",
                    left: "56%",
                    zIndex: 100000,
                  }}
                >
                  <ButtonMailto
                    label="Email "
                    mailto="mailto:office@21ifm.com?subject=Regarding Mathematics explainers&body=Hello%2C%0A%0AI heard that you create customized, interactive mathematics explainers.%0A%0APlease send me more information about your product.%0A%0AI want to  ----- %0A%0AI live in  -----%0A%0AI heard about you from  -----%0A%0A Thank you.%0A%0ARegards%2C%0AYour Name"
                  />
                </Box>

                {/* <Contact/> */}
                {/* <CardsForBlogs in_json={blogs_chain[1].blogs} /> */}
              </div>
            )}
          </Route>

          <Route path="/taxes">
            <h2>Taxes</h2>
          </Route>

          <Route path="/loans">
            <h2>Loans</h2>
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>

          <Route path="/signinLink">
            <SignInMagicLink />
          </Route>

          <Route path="/wait">
            <MagicLinkWait />
          </Route>

          <Route path="/forgotPassword">
            <ForgotPassword />
          </Route>

          <Route path="/int_math">
            <InteractiveMathematics />
          </Route>

          <Route path="/int_ml">
            <InteractiveML />
          </Route>

          <Route path="/ai_consulting">
            <AIConsulting />
          </Route>

          <Route path="/tennis_ai">
            <TennisAI />
          </Route>

          <Route path="/Activations">
            <Activations />
          </Route>

          <Route path="/lrpath">
            <LogisticRegressionLearnPath />
          </Route>

          <Route path="/BackPropagation_Buckets">
            <BackPropagation_Buckets />
          </Route>
          <Route path="/BackPropagation">
            <BackPropagation />
          </Route>
          <Route path="/vector_arithmetic">
            <DotProduct />
          </Route>
          <Route path="/vae_quiz">
            <VAE_QUIZ />
          </Route>

          <Route path="/optionsd3">
            <OptionsD3 />
          </Route>

          <Route path="/vae_flip">
            <VAE_FlipBook />
          </Route>

          <Route path="/gan_quiz">
            <GAN_QUIZ />
          </Route>

          <Route path="/rag">
            <RAG />
          </Route>

          <Route path="/quantization">
            <Quantization />
          </Route>

          <Route path="/granville">
            <Granville />
          </Route>

          <Route path="/askbot">
            <AskBot />
          </Route>

          <Route path="/embeddings">
            <Embeddings />
          </Route>

          <Route path="/kl_divergence">
            <KL_Divergence />
          </Route>
          <Route path="/multi_gaussian">
            <MultivariateGaussian />
          </Route>
          <Route path="/Gradient">
            <Gradient />
          </Route>
          <Route path="/LogisticRegression">
            <LogisticRegression />
          </Route>
          <Route path="/PartialDerivatives">
            <PartialDerivatives />
          </Route>
          <Route path="/Perceptron">
            <Perceptron />
          </Route>
          <Route path="/single_layer_perceptron">
            <SingleLayerPerceptron />
          </Route>
          <Route path="/activation_functions">
            <ActivationFunctions />
          </Route>
          <Route path="/d_activation_functions">
            <DActivationFunctions />
          </Route>
          <Route path="/gan">
            <GAN />
          </Route>
          <Route path="/llm">
            <LLM />
          </Route>
          <Route path="/transformers">
            <Transformers />
          </Route>
          <Route path="/vae">
            <VAE />
          </Route>

          <Route path="/privacy_policy">
            <PrivacyPolicy />
          </Route>

          <Route path="/signin">
            <SignIn />
          </Route>

          <Route path="/__/auth/">
            <Admin />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

function Home() {
  return (
    <div>
      {/* <AppHeader handleDrawerToggle={handleDrawerClick}/> */}
      <main></main>
    </div>
  );
}
