import React, { useState, useEffect } from 'react';

const MySvgComponent = () => {
  const [offset, setOffset] = useState(0);

  // Position for Box 1
  const box1X = 50, box1Y = 50;
  const box1CenterX = box1X + 25, box1CenterY = box1Y + 25;
  
  // Position for Box 2
  const box2X = 300, box2Y = 300;
  const box2CenterX = box2X + 25, box2CenterY = box2Y + 25;

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset((prevOffset) => prevOffset + 1);
    }, 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ backgroundColor: 'black', width: '400px', height: '400px', position: 'relative' }}>
      
      {/* HTML Box 1 */}
      <div style={{ backgroundColor: 'blue', width: '50px', height: '50px', position: 'absolute', left: `${box1X}px`, top: `${box1Y}px` }}></div>
      
      {/* HTML Box 2 */}
      <div style={{ backgroundColor: 'red', width: '50px', height: '50px', position: 'absolute', left: `${box2X}px`, top: `${box2Y}px` }}></div>

      {/* SVG for the curved line */}
      <svg width="400" height="400" style={{ position: 'absolute', top: '0', left: '0' }}>
        <path
          strokeLinecap="round"
          strokeLinejoin="miter"
          fillOpacity="0"
          strokeMiterlimit="5"
          strokeDasharray="10, 10"
          strokeDashoffset={offset}
          stroke="white"
          strokeOpacity="1"
          strokeWidth="7"
          d={`M ${box1CenterX},${box1CenterY} C ${(box1CenterX + box2CenterX)/2},${box1CenterY} ${(box1CenterX + box2CenterX)/2},${box2CenterY} ${box2CenterX},${box2CenterY}`}
        />
      </svg>
    </div>
  );
};

export default MySvgComponent;
