import React, { useState, useEffect, useRef } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import './AskBotGeneric.css';  // Import the updated CSS



const App = ({ menuItems }) => {
  const [blocks, setBlocks] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    addBlock('Hi there! Type "start" to begin.', 'system');
  }, []);

  const addBlock = (content, type, options = null, component = null) => {
    const newBlock = { content, type, options, component, id: blocks.length };
    setBlocks([...blocks, newBlock]);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuItemClick = (menuItem) => {
    menuItem.action(addBlock);
  };

  return (
    <div className="App">
      <div className="sidebar">
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
        <Drawer variant={drawerOpen ? 'permanent' : 'temporary'} open={drawerOpen}>
          <List>
            {menuItems.map((item, index) => (
              <ListItem button key={index} onClick={() => handleMenuItemClick(item)}>
                {item.label}
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>
      <div className="content-area">
        <div className="chat-container">
          {blocks.map((block, index) => (
            <div key={index} className={`block ${block.type}`}>
              {block.content}
              {block.options && (
                <div>
                  {block.options.map((option, i) => (
                    <button key={i} onClick={() => addBlock(`You selected ${option}`, 'system')}>
                      {option}
                    </button>
                  ))}
                </div>
              )}
              {block.component && React.cloneElement(block.component, { addBlock })}
            </div>
          ))}
        </div>
        <div className="input-container">
          <input type="text" placeholder="Your message..." />
          <button onClick={() => addBlock('You said something', 'user')}>Send</button>
        </div>
      </div>
    </div>
  );
};


export default App;