import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CardMedia from '@mui/material/CardMedia';

const Quiz = ({ question }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleClick = (answer) => {
    setSelectedAnswer(answer);
  };

  return (
    <Box p={2} display="flex" flexDirection="column" alignItems={"center"}>
      <Box height="40%" width="75%" marginBottom={2}>
        {question.image && (
          <CardMedia
            component="img"
            image={question.image}
            alt={question.text}
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        )}
        {question.video && (
          <CardMedia
            component="video"
            src={question.video}
            controls
            style={{ height: "100%", width: "100%" }}
          />
        )}
      </Box>
      <Box
        style={{
          width: "75%",
          height: "300px",
          overflow: "auto",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
        }}
      >
        {question.text && <p>{question.text}</p>}
        <List>
          {question.answers.map((answer, index) => (
            <ListItem
              key={index}
              button={true}
              selected={selectedAnswer === answer}
              onClick={() => handleClick(answer)}
              style={{
                backgroundColor:
                  selectedAnswer === answer &&
                  (answer === question.correctAnswer ? "lightgreen" : "red"),
              }}
            >
              <ListItemText primary={answer} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Quiz;
