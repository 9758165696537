import styled from 'styled-components'

const Header3 = styled.h3`
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
font-family: 'Roboto', sans-serif;
    color: #90caf9;
    // text-transform: uppercase;
    text-align: left;
    margin-left: 60px
`

export default Header3;