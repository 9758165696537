
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import LearningGoals from "../../components/LearningGoals";
import FlipCardGrid from "../../components/FlipCardGrid";
import TitleSection from "../../components/TitleSection";
import SubtitleSection from "../../components/SubtitleSection";
import ImgMediaCard from "../../components/ImgMediaCard";
import VideoMediaCard from "../../components/VideoMediaCard";
import "../../markdown-styles/styles.css";
export const Intro_Videos = props => <div style={{
  padding: 0,
  backgroundColor: "#1e1e1e",
  width: "100%",
  marginTop: '50px',
  margin: "auto",
  zIndex: 9999
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} mdxType="ReactPlayer" />
    <br />
  </div>;
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  // material_id: "",
  width: 300,
  height: 450,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "510",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: "green"
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: "yellow"
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const cardsContentServices = [{
  title: 'Custom Interactive Explainers',
  content: 'We create bespoke interactive explainers for institutions, providing an engaging and memorable learning experience for students. These explainers can be seamlessly integrated with any Learning Management System (LMS).'
}, {
  title: 'White Label Solutions',
  content: 'We offer white-label solutions for business schools and professors. This enables them to provide their students with our advanced learning tools, branded under their own name. It enhances their course delivery and adds a unique educational value.'
}, {
  title: 'Curriculum Transformation',
  content: 'Our services can completely transform a school curriculum, including International Baccalaureate programmes, into interactive learning modules. This provides students with a uniform and structured learning experience.'
}, {
  title: 'Consistent and Structured Learning Experience',
  content: 'Our tool aids teachers by offering a consistent format for teaching. This helps maintain the quality of education, makes lesson planning easier, and ensures that every student gets the same comprehensive learning experience.'
}, {
  title: 'Interactive Learning Platform',
  content: 'Learning becomes exciting when it is interactive. Our tools are designed to make the learning process more engaging, encouraging students to explore, ask questions, and understand concepts in depth.'
}];
export const buttons = [{
  label: "Agenda",
  time: 0
}, {
  label: "What is optimization?",
  time: .09
}, {
  label: "cardboard experiment",
  time: .36
}, {
  label: "Volume as a function of height",
  time: 1.14
}, {
  label: "First derivative",
  time: 1.41
}, {
  label: "What is critical point?",
  time: 1.59
}, {
  label: "What is slope of tangent line?",
  time: 2.09
}, {
  label: "Solving for height h to optimize volume",
  time: 2.15
}, {
  label: "Summary",
  time: 2.51
}];
export const goals = [{
  id: 'LG01',
  description: "Understand the concept of optimization in the context of maximizing volume."
}, {
  id: 'LG02',
  description: "Identify the optimization function and its role in optimization problems."
}, {
  id: 'LG03',
  description: "Recognize the constraints in an optimization problem and understand their significance."
}, {
  id: 'LG04',
  description: "Grasp the concept of a derivative and how it can be applied in optimization."
}, {
  id: 'LG05',
  description: "Comprehend the idea of a tangent and its relationship with derivatives."
}, {
  id: 'LG06',
  description: "Master the formula for derivatives and its application in solving optimization problems."
}, {
  id: 'LG07',
  description: "Understand what an inflection point is and how to identify it."
}, {
  id: 'LG08',
  description: "Define a critical point and explain its relevance in optimization."
}];
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" playing controls config={{
      file: {
        attributes: {
          id: "audio-element"
        }
      },
      playerVars: {}
    }} width="320px" height="180px" mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>
        What is optimization
      </button>
      <br />
      <button onClick={() => ref.current.seekTo(20)}>
        What is critical point?
      </button>
    </div>;
};
export const ClipVideo = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" startTime={10} endTime={20} playing controls mdxType="ReactPlayer" />
  </div>;
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const ReactPlayer = makeShortcode("ReactPlayer");
const layoutProps = {
  Intro_Videos,
meta,
ggbParameters,
ggbParameters2,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
cardsContentServices,
buttons,
goals,
Player,
ClipVideo,
Box11,
Box22
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">















    <br />
    <br />
    <br />
    <br />

    <Header mdxType="Header">Interactive Math Learning Platfrom</Header>
    <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/youtube_intro_video.mp4" title="Interactive Learning Platform" description="Specialized in mathematics, analytical topics, and machine learning topics." link="/tennis_ai" mdxType="VideoMediaCard" />
    <>
      <TitleSection title="Seeing is Believing" mdxType="TitleSection" />
      <SubtitleSection subtitle="Experience the power of interactive learning through these three dynamic examples, where every variable is under your control.
" mdxType="SubtitleSection" />
    </>
    <Header mdxType="Header">1.Experience Optimization Interactively</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/optimization.png" title="Optimization Module" description="Dive into our Optimization module, where learning goals are clearly defined and tagged for easy reference. Explore interactive videos that allow for quick and effective revision. The clear infographics and dynamic interactive elements empower you to manipulate variables, uncovering hidden connections that static formulas can't reveal." link="optimization" mdxType="ImgMediaCard" />
    <Header mdxType="Header">2.Unfold the Mystery of Quadratic Functions</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/quadratic_function.png" title="Optimization Module" description="Real-world images, such as an orange and a bridge, invite you to manipulate parameters, matching their curvature. Their different orientations challenge you to understand the concept of slope and the second degree polynomial in a tangible way." link="quadraticfunction" mdxType="ImgMediaCard" />
    <Header mdxType="Header">3. reak apart bayes therom</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/bayes_theorm.png" title="Bayes Theorm" description="Dive into the intricacies of Bayes Theorem through a real-world example that everyone can relate to - why is the grass wet? Is it due to rain, or could there be another reason? The tree structure of our interactive learning model allows you to piece together this puzzle, helping you connect each part of the formula to corresponding elements in the tree. Navigate and understand the Bayes Theorem like never before!" link="bayestheorm" mdxType="ImgMediaCard" />
    <>
      <TitleSection title="Our Services" mdxType="TitleSection" />
      <FlipCardGrid cardsContent={cardsContentServices} mdxType="FlipCardGrid" />;
    </>
    <>
      <TitleSection title="What Next?" mdxType="TitleSection" />
      <SubtitleSection subtitle="Whether you're a student striving to unravel complex math concepts, an educator seeking standardized,
 customizable lessons for a diverse audience, or an institution aiming to
 transition from traditional static content to dynamic, interactive explainers, drop me a message.
" mdxType="SubtitleSection" />
    </>




    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;