import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  title: {
    textTransform: 'none',
    width: '100%',
    textAlign: 'center',
    overflowWrap: 'break-word',
  },
  titleSmallScreen: {
    fontSize: '2rem', // Adjust for small screens
  },
  titleLargeScreen: {
    width: '100%', // Adjust to 100% width
    fontSize: '6rem',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '100vh',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center', // Centering the background
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1,
  },
  textContainer: {
    zIndex: 2,
    textAlign: 'center', // Centering the text
    width: '100%',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 3,
  },
});

export default function TitleComponent({ text, color = 'white', highlight = '', backgroundImage }) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const titleClass = isSmallScreen ? classes.titleSmallScreen : classes.titleLargeScreen;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = backgroundImage;
    img.onload = () => setLoading(false);
  }, [backgroundImage]);

  const parts = text.split(highlight);
  const highlightedText = parts.map((part, index) => (
    <span key={index}>
      {part}
      {index < parts.length - 1 && (
        <span style={{ fontWeight: 'bold', color: 'yellow' }}>{highlight}</span>
      )}
    </span>
  ));

  return (
    <div className={classes.container} style={{ backgroundImage: `url(${backgroundImage})` }}>
      {loading && (
        <div className={classes.loadingContainer}>
          <Typography variant="h4" color="textPrimary">
            Loading...
          </Typography>
        </div>
      )}
      <div className={classes.overlay}></div>
      <div className={classes.textContainer}>
        <Typography variant="h1" style={{ color }} className={`${classes.title} ${titleClass}`}>
          {highlightedText}
        </Typography>
      </div>
    </div>
  );
}
