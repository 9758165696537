import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Define your React components
const Step1Component = () => <div>Step 1 Details</div>;
const Step2Component = () => <div>Step 2 Details</div>;
const Quiz1Component = () => <div>Quiz 1</div>;

// Create a mapping between step titles and React components
const componentMapping = {
  'Step 1': Step1Component,
  'Step 2': Step2Component,
  'Quiz 1': Quiz1Component,
  // Add more mappings as needed
};

// Define your styles
const useStyles = makeStyles({
  paper: {
    backgroundColor: '#282c34', // Change this to your preferred color
    color: 'white',
  },
});

// Main component
const LearningPath = ({ learningPath }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [Component, setComponent] = useState(null);
  const [title, setTitle] = useState('');

  const handleClick = (step) => {
    setComponent(componentMapping[step.title]);
    setTitle(step.title);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* Render your learning path */}
      {learningPath.steps.map((step, index) => (
        <button key={index} onClick={() => handleClick(step)}>
          {step.title}
        </button>
      ))}

      {/* Render the modal with the selected component */}
      {Component && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen
          PaperComponent={Paper}
          PaperProps={{ className: classes.paper }}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Component />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default LearningPath;
