
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
export const goals = [{
  id: 'CL01',
  title: "Foundations of Calculus",
  description: "Recognize the fundamentals of calculus and its relevance in the context of deep learning."
}, {
  id: 'CL02',
  title: "Concept of Derivatives",
  description: "Understand the concept of derivatives, how they represent the rate of change, and their role in optimization problems."
}, {
  id: 'CL03',
  title: "Partial Derivatives",
  description: "Comprehend the idea of partial derivatives and their role in multi-variable functions within deep learning."
}, {
  id: 'CL04',
  title: "Concept of Tangent Planes",
  description: "Understand the concept of tangent planes and how it extends the idea of the tangent line to higher dimensions."
}, {
  id: 'CL05',
  title: "Real-world Applications",
  description: "Identify how calculus concepts are used in real-world applications and deep learning models."
}, {
  id: 'CL06',
  title: "Visualization of Calculus Concepts",
  description: "Visualize calculus concepts in 3D to gain a better understanding of how they function in the context of deep learning."
}, {
  id: 'CL07',
  title: "Hessian Matrix and Second Order Derivatives",
  description: "Learn the concept of Hessian matrix, its significance in representing second order derivatives, and its role in understanding the curvature of functions."
}];
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const topics = [{
  title: 'What is IFT: Instruction Fine-Tuning?',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <br />
        <br />
        <Header mdxType="Header">BRIEF INTRODUCTION</Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/Transformers_intro_video.mp4" title="Exploring Transformers: The Building Blocks of Modern NLP" description="Transformer models are the core of state-of-the-art NLP architectures like GPT and BERT. Discover their unique characteristics, advantages over traditional models, and their remarkable impact on machine translation, text summarization, and question-answering systems" link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: "What is SFT: Supervised Fine-Tuning?",
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
             <Header mdxType="Header">Word to context rich embedding vector</Header>
        <br />
        <h2> Notable SFT works</h2>
          <br />
        <a href="https://arxiv.org/pdf/2305.14201.pdf">  GOAT: Finetuned LLAMA to solve arithmentic </a>
          <br />
        <a href="https://github.com/xionghonglin/DoctorGLM"> DOCTOR GML </a>
          <br />
        <a href="https://github.com/pengxiao-song/LaWGPT"> LAW GPT </a>
          <br />
        <a href="https://shermwong.com/2023/02/10/llm-studies-part-2/"> Source </a>
          <br />
        <a href="https://docs.argilla.io/en/latest/index.html">What is Argilla? A data curation platfrom fro LLMs </a>
        <br />
        <a href="https://optimalscale.github.io/LMFlow/index.html"> What is LMFlow? Gow to fine tune LLM? </a>
        <br />
        <a href="https://laion.ai/notes/cpretrain/">What is conditional pre training? </a>
  </div>
}, {
  title: "What is Few Shot prompting?",
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
             <Header mdxType="Header">Bird's Eye View in to Q and A model with Transformers</Header>
            <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/transformer_arch.png" title="Transformer Architecture" description="A visual tour of the intricate steps and layers involved in the Transformer model's data processing journey." link="" mdxType="ImgMediaCard" />
        <a href="https://shermwong.com/2023/02/10/llm-studies-part-2/"> LLM blog </a>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/TRANSFOMER_KEYNOTE_1.m4v" title="Training loop for Transformer Q and A Models" description="This video presents a high-level overview, capturing the fundamental elements of the training loop in a Transformer AQ model. An ideal starting point for those keen on grasping the basics of this innovative machine learning architecture." link="" mdxType="VideoMediaCard" />
         <Header mdxType="Header">little more detail.architecture of the code structure. </Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/TRANSFOMER_KEYNOTE_2.m4v" title="Exploring Transformers: The Building Blocks of Modern NLP" description="This follow-up video offers a comprehensive deep dive into the working mechanisms of a Transformer AQ model." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'What is Low Rank Approximath Method (LoRA)?',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
           <Header mdxType="Header">First Steps with Transformers: Understanding the Code </Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/transformer_code.m4v" title="Walking Through the Transformer: Code Explained" description="Take a deep dive into the code behind the Transformer Model. Discover the key components and understand how they interact in this advanced machine learning algorithm." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'What are lIMA/Doly/Alpaca?',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <Header mdxType="Header">Understanding the Encoder Layer operation  </Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/encoder_layer_code.m4v" title="A Walkthrough of the Encoder Layer Code" description="Transformer's Encoder Layer and understand its crucial role in processing and understanding the input sequence." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'What are prompt engineering progrmaming patterns?',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
              <Header mdxType="Header">ultiHead Attention Mechanism Code  </Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/multihead_video.m4v" title="A Walkthrough of MultiHead Attention Mechanism" description="Workings of the multihead attention layer in the Transformer model's architecture" link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'What is Nano GPT?',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
              <Header mdxType="Header">ultiHead Attention Mechanism Code  </Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/multihead_video.m4v" title="A Walkthrough of MultiHead Attention Mechanism" description="Workings of the multihead attention layer in the Transformer model's architecture" link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'What is Reinforcement learning from human feedback (RLHF)?',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
              <Header mdxType="Header">ultiHead Attention Mechanism Code  </Header>
        <br />
       <a href="https://www.jonstokes.com/p/catechizing-the-bots-part-2-reinforcement">RHLF </a>
       <br />
       <a href="https://www.invisible.co/ai-training/"> Which company offers LLM trainig service?s </a>
  </div>
}, {
  title: 'What is Instruct GPT?',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
              <Header mdxType="Header">ultiHead Attention Mechanism Code  </Header>
        <br />
       <a href="https://www.jonstokes.com/p/catechizing-the-bots-part-2-reinforcement">RHLF </a>
       <br />
       <a href="https://www.invisible.co/ai-training/"> Which company offers LLM trainig service?s </a>
  </div>
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const SomeComponent = makeShortcode("SomeComponent");
const ImageCard = makeShortcode("ImageCard");
const Marker = makeShortcode("Marker");
const Howler = makeShortcode("Howler");
const MyCarousel = makeShortcode("MyCarousel");
const YouTube = makeShortcode("YouTube");
const VideoMediaCard = makeShortcode("VideoMediaCard");
const ImgMediaCard = makeShortcode("ImgMediaCard");
const layoutProps = {
  goals,
Box2,
ggbParameters2,
opts,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Calculus for Deep learning`}</h1>
    <Header mdxType="Header"> Learning goals</Header>
    <SomeComponent mdxType="SomeComponent" />

    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />
    <div>
    <div style={{
        position: "relative"
      }}>
                    <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/6.png" mdxType="ImageCard" />
                    <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
                    <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
      </div>
      <div style={{
        position: "relative"
      }}>
                    <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/7.png" mdxType="ImageCard" />
                    <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
                    <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                            <div>
                            <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                            </div>
                    </Marker>
      </div>
    </div>




    <br />
    <MyCarousel topics={topics} mdxType="MyCarousel" />
    <p>{`<br/`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;