
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import LearningGoals from "../../../components/LearningGoals";
import DotProduct from "!babel-loader!@mdx-js/loader!./DotProduct.mdx";
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const goals = [{
  id: 'LG01',
  title: 'Introduction to Logistic Regression',
  description: "Understand the concept and usage of logistic regression in binary classification problems."
}, {
  id: 'LG02',
  title: 'Understanding Cross Entropy Loss',
  description: "Learn about cross entropy loss, its role in logistic regression, and why it is preferred over other loss functions."
}, {
  id: 'LG03',
  title: 'Gradient Propagation',
  description: "Understand how gradients are calculated and propagated in logistic regression for model optimization."
}, {
  id: 'LG04',
  title: 'Probability-based Learning',
  description: "Recognize how logistic regression acts as a probability learning model by outputting probabilities instead of absolute classes."
}, {
  id: 'LG05',
  title: 'Weight Updates',
  description: "Learn how weight updates occur in logistic regression using gradient descent and its variants."
}, {
  id: 'LG06',
  title: 'Visualizing Losses',
  description: "Understand the importance of visualizing the losses during the training process and how it can be helpful in model tuning and early stopping."
}, {
  id: 'LG07',
  title: '3D Visualization',
  description: "Learn how 3D visualization of logistic regression can give better intuition about the model's decision boundary."
}];
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const SomeComponent = makeShortcode("SomeComponent");
const ImageCard = makeShortcode("ImageCard");
const Marker = makeShortcode("Marker");
const Howler = makeShortcode("Howler");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const layoutProps = {
  ggbParameters2,
goals,
Box2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Logistic Regression with neural Networks`}</h1>
    <Header mdxType="Header"> Learning goals</Header>

    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />
    <SomeComponent mdxType="SomeComponent" />
    <h1>{`Introduction`}</h1>


    <h1>{`logistic Regerssion`}</h1>
    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/2023/LOG_REG/logistic_regression_1.png" mdxType="ImageCard" />
                  <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
    </div>
    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/2023/LOG_REG/log_reg_2.png" mdxType="ImageCard" />
                  <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
    </div>
    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/2023/LOG_REG/logistic_regression_3.png" mdxType="ImageCard" />
                  <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
    </div>
    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/2023/LOG_REG/logistic_regression_4.png" mdxType="ImageCard" />
                  <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
    </div>
    <h1>{`Interactive exploration`}</h1>
    <div>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/2023/logistic_regression_try_ecologist_2.json" ggbParameters={ggbParameters2} appId="Log_regression" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
    </div>
    <div>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/2023/LOG_REG/logistic_regression_3_gradient.json" ggbParameters={ggbParameters2} appId="id_gradient_1" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;