import React, { useRef, useEffect, useImperativeHandle } from 'react';
import Blockly from 'blockly';
import DarkTheme from '@blockly/theme-dark';


Blockly.Blocks['document_loader'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Load Document from URL")
        .appendField(new Blockly.FieldTextInput("URL"), "url");
    this.setOutput(true, 'Document');
    this.setColour(120);
    this.setTooltip("Loads a document from the specified URL.");
  }
};

Blockly.Blocks['text_splitter'] = {
  init: function() {
    this.appendValueInput("DOCUMENT")
        .setCheck("Document")
        .appendField("Split Text");
    this.appendDummyInput()
        .appendField("Chunk Size")
        .appendField(new Blockly.FieldNumber(500), "chunkSize")
        .appendField("Chunk Overlap")
        .appendField(new Blockly.FieldNumber(0), "chunkOverlap");
    this.setOutput(true, 'SplitDocument');
    this.setColour(160);
    this.setTooltip("Splits the text into smaller chunks.");
  }
};

Blockly.Blocks['vector_store'] = {
  init: function() {
    this.appendValueInput("SPLIT_DOCUMENT")
        .setCheck("SplitDocument")
        .appendField("Create Vector Store");
    this.setOutput(true, 'VectorStore');
    this.setColour(200);
    this.setTooltip("Stores vector data from split documents.");
  }
};

Blockly.Blocks['similarity_search'] = {
  init: function() {
    this.appendValueInput("VECTOR_STORE")
        .setCheck("VectorStore")
        .appendField("Similarity Search");
    this.appendDummyInput()
        .appendField("Query")
        .appendField(new Blockly.FieldTextInput("Query text"), "query");
    this.setOutput(true, 'RetrievedDocs');
    this.setColour(240);
    this.setTooltip("Performs a similarity search on the given query.");
  }
};





Blockly.Blocks['network_type_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Model Complexity")
        .appendField(new Blockly.FieldDropdown([["Simple", "MLP"], ["Medium", "RNN"], ["Complex", "CNN"]]), "network_type");
    this.appendDummyInput()
        .appendField("Signal Type")
        .appendField(new Blockly.FieldDropdown([["Bumpy", "ReLU"], ["Smooth", "Sigmoid"], ["Fast", "Tanh"], ["Slow", "Softmax"]]), "activation_function");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(20); 
    this.setTooltip("This block lets you choose the complexity of your model and the type of signal it uses to make decisions.");
  }
};

Blockly.Blocks['input_data_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Original Data");
    this.setNextStatement(true, null);
    this.setPreviousStatement(true, null);
    this.setColour(20);
    this.setTooltip("This block represents the original data to the model.");
  } 
};
Blockly.Blocks['prediction_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Generated Data");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip("This block represents the data generated by the model.");
  }
};

Blockly.Blocks['ground_truth_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Expected Data");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(10);
    this.setTooltip("This block represents the expected data that the model should generate.");
  }
};

Blockly.Blocks['encoder_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Data Compressor");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(60);
    this.setTooltip("This block compresses the original data into a simpler form.");
  }
};

Blockly.Blocks['decoder_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Data Decompressor");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(260);
    this.setTooltip("This block decompresses the simplified data back into its original form.");
  }
};

Blockly.Blocks['latent_space_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Simplified Data");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(60);
    this.setTooltip("This block represents the simplified data form after being compressed.");
  }
};

// Blockly.Blocks['training_loop_b'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("Learning Process");
//     this.appendDummyInput()
//         .appendField("Measure of Error")
//         .appendField(new Blockly.FieldDropdown([["Small difference", "MSE"], ["Large difference", "CrossEntropy"], ["Average difference", "LogLoss"]]), "loss_type");
//     this.appendDummyInput()
//         .appendField("Learning Speed")
//         .appendField(new Blockly.FieldDropdown([["Slow", "SGD"], ["Moderate", "Adam"], ["Fast", "RMSprop"]]), "optimizer");
//     this.appendDummyInput()
//         .appendField("Learning Duration")
//         .appendField(new Blockly.FieldNumber(10), "epochs");
//     this.appendStatementInput("PREDICTION")
//         .appendField("Generated Data")
//         .setCheck(['Prediction']);
//     this.appendStatementInput("GROUND_TRUTH")
//         .appendField("Expected Data")
//         .setCheck(['GroundTruth']);
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//     this.setTooltip("This block configures the learning process of the model.");
//   }
// };


Blockly.Blocks['training_loop_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Learning Process");
    this.appendDummyInput()
        .appendField("Measure of Error")
        .appendField(new Blockly.FieldDropdown([["Small difference", "MSE"], ["Large difference", "CrossEntropy"], ["Average difference", "LogLoss"]]), "loss_type");
    this.appendDummyInput()
        .appendField("Learning Speed")
        .appendField(new Blockly.FieldDropdown([["Slow", "SGD"], ["Moderate", "Adam"], ["Fast", "RMSprop"]]), "optimizer");
    this.appendDummyInput()
        .appendField("Learning Duration")
        .appendField(new Blockly.FieldNumber(10), "epochs");

    // Create a new dummy input for the "Loss" label
    this.appendDummyInput()
        .appendField("Loss");

    this.appendStatementInput("PREDICTION")
        .appendField("Generated Data")
        .setCheck(['Prediction']);
    this.appendStatementInput("GROUND_TRUTH")
        .appendField("Expected Data")
        .setCheck(['GroundTruth']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This block configures the learning process of the model.");
  }
};


Blockly.Blocks['horizontal_input'] = {
  init: function() {
    this.appendValueInput('INPUT1');
    this.appendValueInput('INPUT2');
    this.appendValueInput('INPUT3');
    this.appendValueInput('INPUT4');
    this.setInputsInline(true);
    this.setColour(230);
    this.setTooltip("This is a block with four horizontal inputs.");
  }
};



Blockly.Blocks['empty_input_b'] = {
  init: function() {
    this.appendDummyInput();
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This is an empty block that can be used as a connection point.");
  }
};


Blockly.Blocks['model_save_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Remember Model");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(80);
    this.setTooltip("This block saves the trained model so you can use it again later.");
  }
};

Blockly.Blocks['model_load_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Recall Model");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(80);
    this.setTooltip("This block loads a previously saved model.");
  }
};

Blockly.Blocks['train_step_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Training Step");
    this.setPreviousStatement(true, "TrainStep");
    this.setNextStatement(true, "TrainStep");
    this.setColour(60);
    this.setTooltip("This block represents a single step in the training process.");
  }
};


Blockly.Blocks['model_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Model");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This block represents the model. You can specify the structure of the model here.");
  }
};

Blockly.Blocks['large_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setInputsInline(true);
    this.setColour(230);
    this.setTooltip("This is a large block.");
  }
};

Blockly.Blocks['list_length'] = {
  init: function() {
    this.appendValueInput("LIST")
        .setCheck("Array")
        .appendField("length of list");
    this.setOutput(true, "Number");
    this.setColour(230);
    this.setTooltip("This block returns the length of the given list.");
  }
};



// Parent block
Blockly.Blocks['horizontal_list'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Horizontal List");
    this.appendStatementInput("LIST")
        .setCheck('ListItem');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This is a horizontal list of blocks.");
  }
};

// Child block
Blockly.Blocks['list_item'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("List Item");
    this.setPreviousStatement(true, 'ListItem');
    this.setNextStatement(true, 'ListItem');
    this.setColour(60);
    this.setTooltip("This is an item in the list.");
  }
};









// Blockly.Blocks['large_block'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("                  "); // Fill up with space
//     this.appendDummyInput()
//         .appendField("                  "); // Fill up with space
//     this.appendDummyInput()
//         .appendField("                  "); // Fill up with space
//     this.appendDummyInput()
//         .appendField("                  "); // Fill up with space
//     this.setOutput(true, null);
//     this.setColour(230);
//     this.setTooltip("This is a large block.");
//   }
// };


Blockly.Blocks['chain_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Chain Block");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This is a block in a chain.");
  }
};


// Child block
Blockly.Blocks['list_item'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("List Item");
    this.setPreviousStatement(true, 'ListItem');
    this.setNextStatement(true, 'ListItem');
    this.setColour(60);
    this.setTooltip("This is an item in the list.");
  }
};

const VAEComponentBeginner = React.forwardRef(({ workspaceXML, onChange }, ref) => {
  const workspaceRef = useRef(null);
  const blocklyDivRef = useRef(null);

  const toolboxXML = `
  <xml xmlns="http://www.w3.org/1999/xhtml" id="toolbox" style="display: none;">
  <block type="document_loader"></block>
  <block type="text_splitter"></block>
  <block type="vector_store"></block>
  <block type="similarity_search"></block>
  
  </xml>
`;

  useEffect(() => {

    const workspace = Blockly.inject(blocklyDivRef.current, {
   toolbox: toolboxXML,
      scrollbars: false,
      theme: DarkTheme,  // Use the imported dark theme
      toolboxPosition: 'left'
    });


    const handleChange = (event) => {
      if (event.type === Blockly.Events.UI) {
        return;
      }

      const newWorkspaceXML = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
      onChange(newWorkspaceXML);
    };

    workspace.addChangeListener(handleChange);

    workspaceRef.current = workspace;

    // Clean up
    return () => {
      workspace.removeChangeListener(handleChange);
      workspace.dispose();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    get workspace() {
      return workspaceRef.current;
    }
  }));

  return (
    <div ref={blocklyDivRef} style={{ height: '100vh', width: '100%' }} />
  );
});

export default VAEComponentBeginner;
