import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import './style.css';



const Arrow = () => (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20L5 10h10z"/>
    </svg>
  );


  const MyCarousel = ({ topics }) => {
    const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  
    return (
      <div>
        <div className="topic-buttons">
          {topics.map((topic, index) => (
            <div key={topic.title} style={{ display: "flex", alignItems: "center" }}>
              <button
                onClick={() => setSelectedTopicIndex(index)}
                className="topic-button"
                style={{
                  color: index === selectedTopicIndex ? "black" : "white", backgroundColor: index === selectedTopicIndex ? "#FECF02" : "#373737",
                }}
              >
                {index + 1}. {topic.title}
              </button>
        
            </div>
          ))}
        </div>
    <Carousel
          showArrows={false}
          showThumbs={false}
          showIndicators={false}
          autoPlay={false}
          selectedItem={selectedTopicIndex}
          swipeable={false} // add this line
          className="carousel"
        >
          {topics.map(({ component: Component }, index) => (
            <div key={index}>
              <Component />
            </div>
          ))}
        </Carousel>
      </div>
    );
  };
  
export default MyCarousel;