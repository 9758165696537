
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import ImgMediaCard from "../../components/ImgMediaCard";
import VideoMediaCard from "../../components/VideoMediaCard";
import InteractiveMathematics from "!babel-loader!@mdx-js/loader!./InteractiveMathematics.mdx";
import AIConsulting from "!babel-loader!@mdx-js/loader!./AIConsulting.mdx";
import TennisAI from "!babel-loader!@mdx-js/loader!./TennisAI.mdx";
import InteractiveFinMathematics from "!babel-loader!@mdx-js/loader!./InteractiveFinMathematics.mdx";
import FullScreenImage from "../../components/FullScreenImage";

export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1350,
  height: 950
};
export const ggbParameters3 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1950,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const topics = [{
  title: 'Who are we?',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <br />
        <br />
        <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/Aboutus_4.png" title="Our AI Expertise Across Industries" description="We have a proven track record of delivering cutting-edge machine learning solutions across diverse industries. Our clients range from hedge funds, where we've extracted insights from unstructured data to build AI-driven forecasting models, creating multi-million-dollar investment portfolios, to businesses in healthcare, insurance, retail, and e-commerce sectors" link="" mdxType="ImgMediaCard" />
  </div>
}, {
  title: 'AI Consulting',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <AIConsulting mdxType="AIConsulting" />
  </div>
}, {
  title: "Interactive Math Learning Platform",
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
          <Container mdxType="Container">
            <InteractiveMathematics mdxType="InteractiveMathematics" />
          </Container>
  </div>
}, {
  title: 'Interactive Financial Mathematics',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
          <Container mdxType="Container">
            <InteractiveFinMathematics mdxType="InteractiveFinMathematics" />
          </Container>
  </div>
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const MyCarousel = makeShortcode("MyCarousel");
const Container = makeShortcode("Container");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <br />
    <Header mdxType="Header">About us</Header>
    <br />
    <MyCarousel topics={topics} mdxType="MyCarousel" />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;