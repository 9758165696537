
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1400,
  height: 950
};
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const SomeComponent = makeShortcode("SomeComponent");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const layoutProps = {
  ggbParameters2,
Box2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Activation Functions`}</h1>
    <SomeComponent mdxType="SomeComponent" />


    <h1>{`Sigmoid Function Interactive`}</h1>
    <div>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/partial_derivatives/Activations.json" ggbParameters={ggbParameters2} appId="activations_id" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;