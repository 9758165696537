
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1950,
  height: 970
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const SomeComponent = makeShortcode("SomeComponent");
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  Box2,
ggbParameters2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <>
      <Header mdxType="Header">Visualizing Kullback-Leibler Divergence in Action</Header>
    </>
 <div>
      <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/KL_Divergence.json" ggbParameters={ggbParameters2} appId="id_kl_divergence" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
  </div>

    <SomeComponent mdxType="SomeComponent" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;