
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import  { useEffect, useState, useReducer, useContext } from "react";
import TourComponent from "../../components/TourComponent"
import Tour from 'reactour'
import SvgTest from "../../images/SvgDeficits.js";
import MemoSvgComponent from "../../images/TestClickZone.js";
import Canva from "../../images/CanvaEx.js"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import TouchAppIcon from '@mui/icons-material/TouchApp';
export const StyledBox = styled('div')(({
  theme,
  width
}) => ({
  color: 'darkslategray',
  backgroundColor: '#FFCE00',
  padding: 8,
  borderRadius: 4,
  margin: "auto",
  textAlign: "center",
  width: width,
  fontFamily: 'Monospace'
}));
export const stepStyle = {
  color: '#5ae'
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#FFFFFF",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const MoveHereD = () => <div id="geogebra_element" style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="HHKwnUa3txo" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};

const layoutProps = {
  StyledBox,
stepStyle,
meta,
ggbParameters,
opts,
Box1,
MoveHereD,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">















    <h1>{`Taylor Series`}</h1>
    <p>{`First, we must discuss the Taylor series in order to understand the concept of sensitivity.`}</p>
    <p>{`Polynomials can approximate any function. In this case, the coefficients are based on the derivatives at a single point.`}</p>
    <p>{`The first order approximation is a straight line approximation. A parabolic shape is used for second order approximation.`}</p>
    <p>{` As we add more terms, the approximation generally gets better.`}</p>
    <GeogebraDraw jsonUrl="https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/testfolder/taylor_delta_gamma.json" ggbParameters={ggbParameters} appId="appId1" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    <h1>{`Gamma`}</h1>
    <GeogebraDraw jsonUrl="https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/testfolder/d3gamma/calls_price_average_delta_constant_gamma.json" ggbParameters={ggbParameters} appId="DefinfitionVector1" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" />
    <SvgTest mdxType="SvgTest" />
    <div style={{
      height: 1000
    }}>
       <SvgTest width={"70%"} mdxType="SvgTest" />
    </div>
    <MemoSvgComponent mdxType="MemoSvgComponent" />
    <Canva mdxType="Canva" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;