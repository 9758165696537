import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

const BlinkingButton = ({ buttonPosition, text, url }) => {
  const handleOpen = (url) => {
    if(url!="#")
     window.open(url, '_blank');
  };

  return (
    <Box>
      <div
        className="marker"
        style={{
          position: "absolute",
          left: `${buttonPosition.left}`,
          top: `${buttonPosition.top}`,
          borderRadius: "50%",
          // width: "40px",
          // height: "40px",
          animation: "blink 1.5s linear infinite",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: "30%",
          zIndex: 100,
          cursor: "pointer", // add this to show the element is clickable
        }}
        title="Click"
        onClick={() => handleOpen(url)}
      >
        {/* <BsFillRecordCircleFill style={{ fontSize: "28px", color: "red" }} /> */}
        <span
          className="text-element"
          style={{
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            position: "relative",
          }}
        >
          {text}
        </span>
      </div>
    </Box>
  );
};

export default BlinkingButton;
