import React, { useState } from 'react';
import './style.css'
import { FaArrowsAlt } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import { Portal } from "react-portal";
import Draggable from 'react-draggable';

const handleDragStop = (e) => {
    e.stopPropagation();
    // drag logic
  };

const MyDraggableComponent = (props) => {
  const { setIsModalOpen } = props;
  return (
    <>
        <div onClick={handleDragStop} style={{overflowY: 'auto', maxHeight: '55v%'}}>
          <Portal onClick={handleDragStop}>
            <Draggable onStop={handleDragStop} >
              <div
                className="modal"
                style={{
                  position: 'fixed',
                  top: 25,
                  left: 10,
                  zIndex: 100000000000,
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '200px', // Set the minimum width here
                  minHeight: '100px', // Set the minimum height here
                  padding: '20px', // Add padding here
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <Tooltip title="This window can be dragged">
                    <FaArrowsAlt style={{ cursor: 'move', marginRight: '10px', color: 'white', fontSize: '24px' }} />
                  </Tooltip>
                  <span style={{ color: 'white', zIndex: 100000 }}>
                    This window can be dragged
                  </span>
                </div>
                {props.children}
                <button
                  style={{
                    padding: '5px 10px',
                    border: 'none',
                    borderRadius: '5px',
                    position: 'absolute', // Changed position to absolute
                    top: 5,
                    right: 5
                }}
                  onClick={() => setIsModalOpen(false)}
                >
                   Close
                </button>
              </div>
            </Draggable>
          </Portal>
        </div>
    </>
  );
};

export default MyDraggableComponent;
