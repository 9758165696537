
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: 950,
  borderColor: "#FFFFFF",
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Show all Greeks for Long Call`}</h1>
    <GeogebraDraw jsonUrl="https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/testfolder/greeks/Greeks_Call2.json" ggbParameters={ggbParameters} appId="appId1" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;