
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const goals = [{
  id: 'AF01',
  title: 'Understanding Activation Functions',
  description: "Understand the concept of activation functions and their role in a neural network."
}, {
  id: 'AF02',
  title: 'Sigmoid Function',
  description: "Explore the sigmoid function, its properties, and its applications in binary classification problems."
}, {
  id: 'AF03',
  title: 'Tanh Function',
  description: "Learn about the tanh function, its properties, and its advantages over the sigmoid function."
}, {
  id: 'AF04',
  title: 'ReLU Function',
  description: "Understand the Rectified Linear Unit (ReLU) function, its advantages, and where it is used in deep learning."
}, {
  id: 'AF05',
  title: 'Softmax Function',
  description: "Discover the softmax function, its role in multi-class classification problems, and how it is used in the output layer of a neural network."
}, {
  id: 'AF06',
  title: 'Choosing Activation Functions',
  description: "Learn how to choose the right activation function for a particular neural network architecture or dataset."
}];
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const SomeComponent = makeShortcode("SomeComponent");
const LearningGoals = makeShortcode("LearningGoals");
const VideoPlayer = makeShortcode("VideoPlayer");
const ImageCard = makeShortcode("ImageCard");
const Marker = makeShortcode("Marker");
const Howler = makeShortcode("Howler");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Box2 = makeShortcode("Box2");
const layoutProps = {
  ggbParameters2,
goals,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Activation functions`}</h1>
    <Header mdxType="Header"> Learning goals</Header>
    <SomeComponent mdxType="SomeComponent" />

    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />

    <br />
    <br />
    <VideoPlayer url="https://storage.googleapis.com/chapters-ifm/ML/2023/LOG_REG/SIGMOID_1.mp4" mdxType="VideoPlayer" />
    <br />
    <br />
    <br />
    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/Sigmoid.png" mdxType="ImageCard" />
                  <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
                 <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
          
    </div>
    <br />
    <br />
    <h1>{`Sigmoid Function Interactive`}</h1>
    <div>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/xor_sigmoid_5.json" ggbParameters={ggbParameters2} appId="xor-sigmoid" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "XYVIEW"
      }]} mdxType="GeogebraDraw" /> 
    </div>
    <div style={{
      position: "relative"
    }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/ML/sigmoid-activity.png" mdxType="ImageCard" />
                  <Marker left="25%" top="10%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
                 <Marker left="40%" top="30%" mleft="42%" mtop="25%" text="2" type="video" mdxType="Marker">
                        <div>
                        <Howler key={1} src={"https://storage.googleapis.com/chapters-ifm/ML/three_The_sigmoidfunction.mp3"} playing={true} mdxType="Howler" />
                        </div>
                </Marker>
          
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;