import React, { useState } from 'react';
import { FaVolumeUp } from 'react-icons/fa';

const LandmarkButton = (props) => {
  const { x, y, text } = props;
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    const synth = window.speechSynthesis;

    if (!isPlaying) {
      const voices = synth.getVoices();
      const maleVoice = voices.find(voice => voice.gender === 'male' && voice.lang === 'en');
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.voice = maleVoice;
      synth.speak(utterance);
      setIsPlaying(true);
    } else {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
    }


  };

  return (
    <button
      style={{
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
      }}
      onClick={handleClick}
    >
      <FaVolumeUp style={{ marginRight: '8px' }} />
      {isPlaying ? 'Stop' : 'Play'}
    </button>
  );
};

export default LandmarkButton;