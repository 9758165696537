import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';

const StyledContainer = styled(Container)`
  background-color: #333;
  border-radius: 8px;
  padding: 2rem;
  color: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
`;

const TitleSpan = styled('span')`
  color: #03A9F4; // Blue accent
`;

const ResponsiveTypography = styled(Typography)`
  @media (max-width: 600px) {
    font-size: 1.5rem; // adjust as needed
  }
`;

const SleekTypography = styled(Typography)`
  font-weight: 300; // This will make the font thinner and sleeker
`;

const AIBanner = ({ titleLine1, titleLine2, description }) => {
  return (
    <StyledContainer>
      <Box display="flex" flexDirection="column" alignItems="center" py={5}>
        <ResponsiveTypography variant="h1" gutterBottom align="center">
          <TitleSpan>{titleLine1}</TitleSpan><br />
          {titleLine2}
        </ResponsiveTypography>
        <SleekTypography variant="h6" align="center" color="inherit">
          {description}
        </SleekTypography>
      </Box>
    </StyledContainer>
  );
};

AIBanner.propTypes = {
  titleLine1: PropTypes.string.isRequired,
  titleLine2: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default AIBanner;
