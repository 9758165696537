import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useTheme } from '@mui/material/styles';
import ReactPlayer from 'react-player'
import heroVideo from './intro_video.mp4'
import CardMedia from '@mui/material/CardMedia';
import ModalSignup from './ModalSignup'
const mock = [
  {
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    name: 'Clara',
    title: 'I am a Mathematics student',
    avatar: 'https://assets.maccarianagency.com/avatars/img4.jpg',
  },
  {
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    name: 'Ana',
    title: 'I am a teacher',
    avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
  },
  {
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    name: 'Chary Smith',
    title: 'I just want to learn Financial Mathematics',
    avatar: 'https://assets.maccarianagency.com/avatars/img6.jpg',
  },

];

const Reviews = () => {
const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
            color: '#90caf9',
          }}
        >
           Visual exploration stimulates imagination and creativity <br/>
           Here are a few examples
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          data-aos={'fade-up'}
          sx={{ color: 'common.white' }}
        >
               The IFM  library has plenty of apps to  
                <br /> explain mathematical and statistical concepts.
                
                <br /><ModalSignup/> for free access to 3 apps
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
              component={Card}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              boxShadow={0}
              variant={'outlined'}
              borderRadius={2}
              bgcolor={ '#303030'}
              color={ '#fff'}
            >
               <CardMedia
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              effect="blur"
              sx={{
                objectFit: 'cover',
              }}
            >
                 <ReactPlayer
              url = {heroVideo}
              playing
              loop
              controls={true}
              width="100%"
              height="100%"
              />
              </Box>
           
          </CardMedia>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ paddingBottom: 2 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                    {/* <ListItemAvatar sx={{ marginRight: 3 }}>
                      <Avatar
                        src={item.avatar}
                        variant={'rounded'}
                        sx={{ width: 200, height: 200, borderRadius: 2 }}
                      />
                    </ListItemAvatar> */}
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.title}
                      secondary={item.name}
                    />
                  </ListItem>
                </Box>
                <Typography color={'gray'}>{item.feedback}</Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Reviews;
