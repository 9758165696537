
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import { useState } from 'react';
import AlphabetCards from "../../../components/AlphabetCards";
import MyBook from "../../../components/Book.js";
import DrawingCanvas from  "../../../components/DrawingCanvas.js";
import MatchingGame from  "../../../components/MatchingGame.js";
import MyFlowChart from  "../../../components/MyFlowChartEditor.js";
import FlumeFlow from  "../../../components/FlumeFlow.js";
import AIBanner from  "../../../components/AIBanner.js";
import FineTuningService from  "../../../components/FineTuningService.js";
import BlocklyComponent from  "../../../components/BlocklyComponent.js";
import VAEComponent from  "../../../blocks/VAEComponentBeginner.js";
import OneBlocklyFile from  "../../../blocks/OneBlocklyFile.js";
import LangChainBlocksComponent from  "../../../blocks/LangChainBlocksComponent.js";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
export const data = {
  images: [{
    id: '1',
    name: 'one. Low wetland that supports grass or trees',
    imageUrl: 'https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/bg.png'
  }, {
    id: '2',
    name: 'two.A huge mass of ice',
    imageUrl: 'https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/llama2.png'
  }, {
    id: '3',
    name: 'three',
    imageUrl: 'https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/bg.png'
  }],
  items: [{
    id: '3',
    name: 'three.A row of mountains',
    type: 'name'
  }, {
    id: '1',
    name: 'One. Name 1',
    type: 'name'
  }, {
    id: '2',
    name: 'Two.Low wetland that supports grass or trees',
    type: 'name'
  }
  // more names...
  ]
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const layoutProps = {
  data
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Variational Auto Encoders`}</h1>
  <AIBanner titleLine1="We develop AI Applications" titleLine2="for Small to Medium Enterprises" description="Let's build customized AI assisted solutions to boost employee productivity, elevate customer satisfaction with rapid responses, and automate repetitive tasks." mdxType="AIBanner" />
    <pre><code parentName="pre" {...{}}>{`<br/>
<br/>
<br/>
`}</code></pre>
    <Header mdxType="Header">ABOUT VARIATIONAL AUTO ENCODERS</Header>
    <FineTuningService mdxType="FineTuningService" />
    <div>
      <MyBook mdxType="MyBook" />
    </div>
    <DrawingCanvas mdxType="DrawingCanvas" />

    <DndProvider backend={HTML5Backend} mdxType="DndProvider">
        <MatchingGame data={data} mdxType="MatchingGame" />
    </DndProvider>
    <iframe src="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/litegraph-example/index.html" width="100%" height="600"></iframe>
    <MyFlowChart mdxType="MyFlowChart" />
    <VAEComponent mdxType="VAEComponent" />
    <LangChainBlocksComponent mdxType="LangChainBlocksComponent" />
    <OneBlocklyFile mdxType="OneBlocklyFile" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;