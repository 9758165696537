import firebase from "./firebaseIndex";

export const authMethods = {
  signinWithGoogle: (setErrors, setUser) => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        setUser(user);
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        setErrors((prev) => [...prev, {errorCode, errorMessage}]);
      });
  },

  signOut: (setErrors, setUser) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Clear user from state
        setUser(null);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });
  }
};
