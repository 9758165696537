
// README:
// https://observablehq.com/@jerdak/litegraph-example
// https://github.com/jagenjo/litegraph.js/blob/master/guides/README.md


// import React, { useEffect, useRef } from "react";
// import { LGraph, LGraphCanvas, LiteGraph } from "litegraph.js";
// import "litegraph.js/css/litegraph.css";

// const MyLiteGraph = () => {
//   const canvasRef = useRef(null);
//   const graphRef = useRef(null);  // Create a new ref to hold the graph object

//   useEffect(() => {
//     if (canvasRef.current) {
//       const graph = new LGraph();


//       const canvas = new LGraphCanvas(canvasRef.current, graph);
//       canvas.allow_dragnodes = true;  // Disable adding new nodes
//       canvas.allow_contextmenu = false;  // Disable context menu
//       canvas.render_canvas_border = false; // Disables the menu to create new nodes


//       const node_const = LiteGraph.createNode("basic/const");
//       node_const.pos = [200, 200];
//       graph.add(node_const);
//       node_const.setValue(4.5);

//       const node_watch = LiteGraph.createNode("basic/watch");
//       node_watch.pos = [700, 200];
//       graph.add(node_watch);

//       node_const.connect(0, node_watch, 0);

//       graph.start();

//       graphRef.current = graph;  // Store the graph object in the ref

//       // Cleanup on unmount
//       return () => {
//         graph.clear();
//       };
//     }
//   }, []);

//   // Function to save the graph to a JSON file
//   const saveGraph = () => {
//     if (graphRef.current) {
//       const graphJSON = JSON.stringify(graphRef.current.serialize());
//       const blob = new Blob([graphJSON], {type: "application/json"});
//       const url = URL.createObjectURL(blob);
//       const link = document.createElement('a');
//       link.download = 'graph.json';
//       link.href = url;
//       link.click();
//     }
//   };

//   // Function to load the graph from a JSON file
//   const loadGraph = (event) => {
//     if (graphRef.current) {
//       const file = event.target.files[0];
//       const reader = new FileReader();
//       reader.onload = (event) => {
//         const graphJSON = JSON.parse(event.target.result);
//         graphRef.current.configure(graphJSON);
//       };
//       reader.readAsText(file);
//     }
//   };

//   return (
//     <div>
//       <canvas ref={canvasRef} width="1024" height="720" style={{ border: "1px solid" }} />
//       <button onClick={saveGraph}>Save Graph</button>
//       <input type="file" onChange={loadGraph} />
//     </div>
//   );
// };

// export default MyLiteGraph;


// GET getValueFormatter. KEEP IT 

import React, { useEffect, useRef, useState } from "react";
import { LGraph, LGraphCanvas, LiteGraph } from "litegraph.js";
import "litegraph.js/css/litegraph.css";

const MyLiteGraph = () => {
  const canvasRef = useRef(null);
  const graphRef = useRef(null);  // Create a new ref to hold the graph object
  const [nodeValue, setNodeValue] = useState(null);  // State to hold the node value

  useEffect(() => {
    if (canvasRef.current) {
      const graph = new LGraph();

      const canvas = new LGraphCanvas(canvasRef.current, graph);
      canvas.allow_contextmenu = false;  // Disable context menu
      canvas.render_canvas_border = false; // Disables the menu to create new nodes
      canvas.allow_searchbox = false;   

      const node_const = LiteGraph.createNode("basic/const");
      node_const.pos = [200, 200];
      graph.add(node_const);
      node_const.setValue(4.5);

      const node_watch = LiteGraph.createNode("basic/watch");
      node_watch.pos = [700, 200];
      graph.add(node_watch);

      node_const.connect(0, node_watch, 0);

      graph.start();

      graphRef.current = graph;  // Store the graph object in the ref

      // Cleanup on unmount
      return () => {
        graph.clear();
      };
    }
  }, []);

  // Function to get the node value
  const handleButtonClick = () => {
    // Find the 'basic/const' node in the graph
    const constNode = graphRef.current.findNodesByType("basic/const")[0];
    if (constNode) {
      setNodeValue(constNode.properties.value);
    }
  };

  // Function to save the graph to a JSON file
  const saveGraph = () => {
    if (graphRef.current) {
      const graphJSON = JSON.stringify(graphRef.current.serialize());
      const blob = new Blob([graphJSON], {type: "application/json"});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'graph.json';
      link.href = url;
      link.click();
    }
  };

  // Function to load the graph from a JSON file
  const loadGraph = (event) => {
    if (graphRef.current) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const graphJSON = JSON.parse(event.target.result);
        graphRef.current.configure(graphJSON);
      };
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <canvas ref={canvasRef} width="1024" height="720" style={{ border: "1px solid" }} />
      <button onClick={handleButtonClick}>Get Node Value</button>
      <div>Node Value: {nodeValue}</div>
      <button onClick={saveGraph}>Save Graph</button>
      <input type="file" onChange={loadGraph} />
    </div>
  );
};

export default MyLiteGraph;



// #READER MODE
// import React, { useEffect, useRef, useState } from 'react';
// import { LGraph, LGraphCanvas, LiteGraph } from 'litegraph.js';

// function MyFlowChart() {
//   const ref = useRef();
//   const [nodeValue, setNodeValue] = useState(null);

//   useEffect(() => {
//     var graph = new LGraph();
//     var canvas = new LGraphCanvas(ref.current, graph);

//     var node_const = LiteGraph.createNode("basic/const");
//     node_const.pos = [200, 200];
//     graph.add(node_const);
//     node_const.setValue(4.5);

//     var node_watch = LiteGraph.createNode("basic/watch");
//     node_watch.pos = [700, 200];
//     graph.add(node_watch);

//     node_const.connect(0, node_watch, 0);

//     graph.start();

//     return () => {
//       graph.clear();
//     };
//   }, []);

//   const handleButtonClick = () => {
//     // Find the 'basic/const' node in the graph
//     const constNode = graphRef.current.findNodesByType("basic/const")[0];
//     if (constNode) {
//       setNodeValue(constNode.properties.value);
//     }
// };


//   return (
//     <div>
//       <canvas ref={ref} width="1024" height="720" style={{ border: '1px solid' }} />
//       <button onClick={handleButtonClick}>Get node value</button>
//       {nodeValue && <p>The node value is: {nodeValue}</p>}
//     </div>
//   );
// }

// export default MyFlowChart;

