
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import LearningGoals from "../../components/LearningGoals";
import FlipCardGrid from "../../components/FlipCardGrid";
import "../../markdown-styles/styles.css";
export const Intro_Videos = props => <div style={{
  padding: 0,
  backgroundColor: "#1e1e1e",
  width: "100%",
  marginTop: '50px',
  margin: "auto",
  zIndex: 9999
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} mdxType="ReactPlayer" />
    <br />
  </div>;
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  // material_id: "",
  width: 300,
  height: 450,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "510",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: "green"
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: "yellow"
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const buttons = [{
  label: "Start",
  time: 0
}, {
  label: "What is bayes Theorm?",
  time: .05
}, {
  label: "Applications",
  time: .25
}, {
  label: "Origin of Name",
  time: .49
}, {
  label: "Prior, Posterior, Likelihood",
  time: 1.12
}, {
  label: "Frequentist",
  time: 1.34
}, {
  label: "Continuous Variables",
  time: 1.59
}, {
  label: "Conditional Probability",
  time: 2.22
}, {
  label: "Wet grass Example",
  time: 2.45
}, {
  label: "Total Probability",
  time: 4.57
}, {
  label: "How to Read Formula",
  time: 5.32
}];
export const goals = [{
  id: 'LG01',
  description: "Understand the principles of Bayes' theorem, including the concepts of prior and posterior probabilities."
}, {
  id: 'LG02',
  description: "Gain the ability to translate abstract probabilistic concepts into real-world contexts using examples."
}, {
  id: 'LG03',
  description: "Master the calculation of posterior probabilities given prior probabilities and likelihoods."
}, {
  id: 'LG04',
  description: "Acquire a strong understanding of conditional probabilities, and how to calculate the probability of an event given another event has occurred."
}, {
  id: 'LG05',
  description: "Develop the ability to visualize probabilities and the workings of Bayes' theorem using tree diagrams."
}, {
  id: 'LG06',
  description: "Gain experience in manipulating and experimenting with probabilities in the interactive tool, observing how changes affect outcomes."
}, {
  id: 'LG07',
  description: "Learn and apply a mnemonic or acronym to easily remember the formula of Bayes' theorem."
}, {
  id: 'LG08',
  description: "Address and resolve common questions and misconceptions about Bayes' theorem."
}, {
  id: 'LG09',
  description: "Understand the versatility of Bayes' theorem through applications in various fields such as medicine, sports, and finance."
}, {
  id: 'LG10',
  description: "Reflect on understanding and application of Bayes' theorem, and consolidate the learning experience."
}];
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url="https://storage.googleapis.com/chapters-ifm/ML/2023/BAYES/bayes_theorm.mp4" playing controls config={{
      file: {
        attributes: {
          id: "audio-element"
        }
      },
      playerVars: {}
    }} width="320px" height="180px" mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>
        What is optimization
      </button>
      <br />
      <button onClick={() => ref.current.seekTo(20)}>
        What is critical point?
      </button>
    </div>;
};
export const ClipVideo = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" startTime={10} endTime={20} playing controls mdxType="ReactPlayer" />
  </div>;
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VideoPlayer = makeShortcode("VideoPlayer");
const AppHeader = makeShortcode("AppHeader");
const ReactPlayer = makeShortcode("ReactPlayer");
const layoutProps = {
  Intro_Videos,
meta,
ggbParameters,
ggbParameters2,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
buttons,
goals,
Player,
ClipVideo,
Box11,
Box22
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    <h1>{`Example 1: Bayes Therom in Action`}</h1>
    <h1>{`Learning Goals`}</h1>
    <LearningGoals learningGoals={goals} mdxType="LearningGoals" />

    <br />
    <br />
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
  <VideoPlayer buttons={buttons} width="70%" height="70%" url="https://storage.googleapis.com/chapters-ifm/ML/2023/BAYES/bayes_theorm.mp4" mdxType="VideoPlayer" />
    </div>
    <br />
    <br />
    <AppHeader mdxType="AppHeader" />

    <h1>{`Experience Bayes Therom Interactively`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/ML/2023/BAYES/BayesTherom_6.json" ggbParameters={ggbParameters} appId="id1" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box2 mdxType="Box2" />,
      button: "text14"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text4"
    }]} mdxType="GeogebraDraw" />




    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;