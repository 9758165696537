
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import useFetchData from "../../components/UseFetchData";
import { ResponsiveSwarmPlot, SwarmPlotAnnotations } from "@nivo/swarmplot";
const _ = require("lodash");
export const NivoSwarmPlot = ({
  data,
  xAxisVariable,
  groupVariable,
  sizeVariable,
  xAllMin,
  xAllMax
}) => {
  const newData = data.map(el => ({
    ...el,
    group: el['Sector']
  }));
  // const [currentIndex, setCurrentIndex] = useState(13);
  const all_groups = ['Healthcare', 'Industrials', 'Consumer Cyclical', 'Technology', 'Consumer Defensive', 'Utilities', 'Financial', 'Basic Materials', 'Real Estate', 'Energy', 'Communication Services'];
  const groups_in = ['Healthcare', 'Industrials'];
  const size_extent = [xAllMin, xAllMax];
  const value_min = xAllMin;
  const value_max = xAllMax;
  let size_array;
  size_array = [30, 55];
  debugger;
  return (
    //height attribute is must
    <div style={{
      maxWidth: "100%",
      margin: "0 auto",
      height: "2050px"
    }}>
      <ResponsiveSwarmPlot data={data} id={"Ticker"} groups={all_groups} groupBy={"Sector"} value={xAxisVariable} valueScale={{
        type: "linear",
        min: value_min,
        max: value_max,
        reverse: false
      }} size={{
        key: xAxisVariable,
        values: [value_min, value_max],
        sizes: [6, 20]
      }} forcesStrength={4} simulationIterations={100} spacing={7} colorBy={"Sector"} layout="horizontal" borderColor={{
        from: 'color',
        modifiers: [['darker', 0.6], ['opacity', 0.5]]
      }} enableGridX={true} margin={{
        top: 100,
        right: 150,
        bottom: 80,
        left: 150
      }} motionStiffness={50} motionDamping={10} mdxType="ResponsiveSwarmPlot" />
    </div>
  );
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: "100%",
  borderColor: "#FFFFFF",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const App = () => {
  const [data, setData] = React.useState([]);
  const calculation = React.useMemo(() => expensiveCalculation(data), [data]);
  const cors_header = {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Origin': 'https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/chapters-ifm/'
    }
  };
  const getData = () => {
    fetch('https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/ggb_resources/finviz_colab_sp500_2_2021/12/29.json', cors_header).then(function (response) {
      console.log('Response');
      console.log(response);
      return response.json();
    }).then(function (myJson) {
      console.log('myJSON');
      console.log(myJson);
      console.log('entries');
      console.log(Object.entries(myJson));
      console.log('arrays');
      console.log(_.toArray(myJson));
      debugger;
      setData(myJson);
    });
  };
  React.useEffect(() => {
    getData();
  }, []);
  return <div className="App">
     {<>
      {calculation}
      </>}
    </div>;
};
export const expensiveCalculation = data => {
  var allkeys = Object.keys(data);
  const xAxisVariable = "PerfWeek";
  const sizeVariable = "PerfWeek";
  const colorVariable = "PerfWeek";
  const outlierVariable = "PerfWeek";
  return <div> 
    {/* {data.map(function(object, i){
        return <p>{object.Ticker}</p>;
     })} */}
     <NivoSwarmPlot data={data} xAxisVariable={'Price_x'} sizeVariable={'Price_x'} groupVariable={"Sector"} xAllMin={6.09} xAllMax={5869.47} mdxType="NivoSwarmPlot" />
   
  </div>;
};
export const MultiFecthApp = () => {
  const [data, setData] = React.useState([]);
  const calculation = React.useMemo(() => expensiveCalculation(data), [data]);
  const fetchExternalData = () => {
    return Promise.all([fetch("https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/ggb_resources/finviz_colab_sp500_1_2021/12/29.json"), fetch("https://secret-ocean-49799.herokuapp.com/https://storage.googleapis.com/ggb_resources/finviz_colab_sp500_2_2021/12/29.json")]).then(results => Promise.all(results.map(result => result.json())));
  };
  React.useEffect(() => {
    fetchExternalData().then(response => {
      const combined_array = response[0].concat(response[1]);
      setData(combined_array);
    }).catch(console.error);
  }, []);
  return <div className="App">
     {<>
      {calculation}
      </>}
    </div>;
};

const layoutProps = {
  NivoSwarmPlot,
meta,
ggbParameters,
opts,
Box1,
Intro,
DefaultBox,
Text4,
componetArray,
indexArray,
App,
expensiveCalculation,
MultiFecthApp
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">











    <h1>{`Testing Data Fetch`}</h1>



    <MultiFecthApp mdxType="MultiFecthApp" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;