import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Paper,
} from '@material-ui/core';

// Define the JSON structure
const audienceData = [
  {
    title: 'Governments',
    dataProblems: 'Complex documentation, privacy concerns in public engagement.',
    howLLMHelps: 'Customized document summarization, Encrypted data handling.'
  },
  {
    title: 'Academic Institutions',
    dataProblems: 'Vast research materials, secure grading system.',
    howLLMHelps: 'Domain-specific article summarization, Encrypted automated grading.'
  },
  {
    title: 'Healthcare',
    dataProblems: 'Sensitive medical records, accurate diagnosis.',
    howLLMHelps: 'Customized medical record conversion, Encrypted diagnostic suggestions.'
  },
  {
    title: 'Financial Institutions',
    dataProblems: 'Regulatory compliance, secure customer service.',
    howLLMHelps: 'Customized auditing, Encrypted customer data handling.'
  },
  {
    title: 'Media and Publishing',
    dataProblems: 'High content volume, sensitive audience data.',
    howLLMHelps: 'Automated editing tailored to specific genres, Encrypted audience data handling.'
  },
  {
    title: 'E-commerce',
    dataProblems: 'Secure product descriptions, data-sensitive customer support.',
    howLLMHelps: 'Domain-specific automated product descriptions, Encrypted customer data support.'
  }
];


function App() {
    const [selectedAudience, setSelectedAudience] = useState(null);
  
    return (
      <Grid container spacing={3}>
        {/* Audience Cards Container */}
        <Grid item xs={12} sm={4}>
          <Box
            style={{
              overflowY: 'auto',
              height: '60vh',
              padding: '1rem',
            }}
          >
            {audienceData.map((audience, index) => (
              <Card
                key={index}
                onClick={() => setSelectedAudience(audience)}
                onMouseEnter={() => setSelectedAudience(audience)}
                style={{
                  marginBottom: '1rem',
                  cursor: 'pointer',
                }}
              >
                <CardContent>
                  <Typography variant="h5">{audience.title}</Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
  
        {/* Information Box */}
        <Grid item xs={12} sm={8}>
          <Paper elevation={3} style={{ padding: '2rem', height: '60vh' }}>
            {selectedAudience ? (
              <div>
                <Typography variant="h4" gutterBottom>
                  {selectedAudience.title}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Data Problems:
                </Typography>
                <Typography variant="body1" paragraph>
                  {selectedAudience.dataProblems}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  How Fine-Tuned Models Can Help:
                </Typography>
                <Typography variant="body1" paragraph>
                  {selectedAudience.howLLMHelps}
                </Typography>
              </div>
            ) : (
              <Typography>Select an audience to see details</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
  
  export default App;
