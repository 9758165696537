// add useContext
import React, { useContext } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import ResetPasswordFireBase from "./ResetPasswordFireBase.js"
import { initializeApp } from "firebase/app";

import {
  useHistory,
} from "react-router-dom";


import firebase from "firebase";

var to_render = <></>

function handleResetPassword(auth, actionCode, continueUrl, lang) {
  // Localize the UI to the selected language as determined by the lang
  // parameter.

  // Verify the password reset code is valid.
  auth.verifyPasswordResetCode(auth, actionCode).then((email) => {
    const accountEmail = email;

    // TODO: Show the reset screen with the user's email and ask the user for
    // the new password.
    const newPassword = "...";

    // Save the new password.
    auth.confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
      // Password reset has been confirmed and new password updated.

      // TODO: Display a link back to the app, or sign-in the user directly
      // if the page belongs to the same domain as the app:
      // auth.signInWithEmailAndPassword(accountEmail, newPassword);

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch((error) => {
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.
    });
  }).catch((error) => {
    // Invalid or expired action code. Ask user to try to reset the password
    // again.
  });
}


function handleRecoverEmail(auth, actionCode, lang) {
  // Localize the UI to the selected language as determined by the lang
  // parameter.
  let restoredEmail = null;
  // Confirm the action code is valid.
  auth.checkActionCode(auth, actionCode).then((info) => {
    // Get the restored email address.
    restoredEmail = info['data']['email'];

    // Revert to the old email.
    return applyActionCode(auth, actionCode);
  }).then(() => {
    // Account email reverted to restoredEmail

    // TODO: Display a confirmation message to the user.

    // You might also want to give the user the option to reset their password
    // in case the account was compromised:
    auth.sendPasswordResetEmail(auth, restoredEmail).then(() => {
      // Password reset confirmation sent. Ask user to check their email.
    }).catch((error) => {
      // Error encountered while sending password reset code.
    });
  }).catch((error) => {
    // Invalid code.
  });
}


function handleVerifyEmail(auth, actionCode, continueUrl, lang) {
  // Localize the UI to the selected language as determined by the lang
  // parameter.
  // Try to apply the email verification code.
  auth.applyActionCode(auth, actionCode).then((resp) => {
    // Email address has been verified.

    // TODO: Display a confirmation message to the user.
    // You could also provide the user with a link back to the app.

    // TODO: If a continue URL is available, display a button which on
    // click redirects the user back to the app via continueUrl with
    // additional state determined from that URL's parameters.
  }).catch((error) => {
    // Code is invalid or expired. Ask the user to verify their email address
    // again.
  });
}



const Admin = () => {
  // const classes = useStyles();
  const history = useHistory()
  const {
    handleSignin,
    handleSigninEmailLink,
    inputs,
    setInputs,
    errors
  } = useContext(firebaseAuth);

  const query = new URLSearchParams(window.location.search);
    // Get the action to complete.
    const mode = query.get('mode');
    // Get the one-time code from the query parameter.
    const actionCode = query.get('oobCode');
    // (Optional) Get the continue URL from the query parameter if available.
    const continueUrl = query.get('continueUrl');
    // (Optional) Get the language code if available.
    const lang = query.get('lang') || 'en';


    var firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTHDOMAIN,
      databaseURL: process.env.REACT_APP_BASEURL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGEBUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID
    };
    // Initialize Firebase
    if (typeof window !== "undefined") {
      // firebase.initializeApp(firebaseConfig);
    }
    const new_auth  =  firebase.auth()

    // Handle the user management action.
  switch (mode) {
    case 'resetPassword':
      // Display reset password handler and UI.
      // handleResetPassword(new_auth, actionCode, continueUrl, lang);
       to_render = <div>
      <h2>'resetPassword</h2>
      <h2> {mode}</h2>
      <ResetPasswordFireBase auth={new_auth} actionCode={actionCode}/>
      <h2> {actionCode}</h2>
      </div>
      break;
    case 'recoverEmail':
      // Display email recovery handler and UI.
      // handleRecoverEmail(new_auth, actionCode, lang);
       to_render = <div>
      <h2>'recoverEmail</h2>
      <h2> {mode}</h2>
      <h2> {actionCode}</h2>
      </div>
      break;
    case 'verifyEmail':
      // Display email verification handler and UI.
      // handleVerifyEmail(new_auth, actionCode, continueUrl, lang);
       to_render = <div>
      <h2>'verifyEmail</h2>
      <h2> {mode}</h2>
      <h2> {actionCode}</h2>
      </div>
      break;
      default: 
         to_render = <div>
        <h2> {mode}</h2>
        <h2> {actionCode}</h2>
        </div>
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignin();
    if(window.localStorage.getItem('token') !== null)
     history.push("/")
     else
     history.push("/forgotPassword")
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(inputs);
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitEmailLink = (e) => {
    e.preventDefault();
    console.log("handleSubmitEmailLink");
    handleSigninEmailLink();
  };


  return (
<>
Admin panel
{to_render}
</>
  );
};

export default Admin;
