import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { TextField, Button, makeStyles, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        margin: 'auto',
        marginTop: theme.spacing(5),
    },
    field: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    },
    link: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
}));

const ContactUs = () => {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const templateId = 'template_8mqwp3c';
        const serviceID = 'service_ne4ipkw';

        let templateParams = {
            from_name: name,
            reply_to: email,
            message: message,
        };

        emailjs.send(serviceID, templateId, templateParams, 'lgC0Srzi9zC-oNFqD')
            .then(response => {
                console.log('Email successfully sent!', response);
                setSuccessMessage('Message sent successfully!');
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch(err => {
                console.error('Email sending failed.', err);
                setSuccessMessage('Oops, there was an error. Please try again later.');
            });
    }

    return (
        <div>
            <br/>
            <br/>
            <Typography variant="h4" align="center" gutterBottom style={{color: 'gray'}}>
                Contact Form
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
                <TextField className={classes.field} label="Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} required/>
                <TextField className={classes.field} label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                <TextField className={classes.field} label="Message" variant="outlined" multiline rows={4} value={message} onChange={(e) => setMessage(e.target.value)} required/>
                <Button className={classes.button} variant="contained" color="primary" type="submit">
                    Send
                </Button>
                {successMessage && <Typography color="secondary">{successMessage}</Typography>}
            </form>
            <br/>
            <br/>
            <Typography variant="body1" color="textSecondary" align="center">
                <Link href="mailto:office@davinci-dynamics.com" color="inherit">
                    office@davinci-dynamics.com
                </Link>
            </Typography>
            <Typography className={classes.link}>
                <Link href="https://www.linkedin.com/in/pavanmirla/" target="_blank" rel="noopener noreferrer">LinkedIn Profile</Link>
            </Typography>
        </div>
    )
}

export default ContactUs;
