import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Modal from 'react-modal';

// Dark Mode Global Style
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #333;
    color: #fff;
  }
`;

// Custom styled-components
const TourContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.image});
  background-size: cover;
`;

// Custom styled-components
const Marker = styled.div`
  position: absolute;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  width: 30px;
  height: 30px;
  background-color: #ff0000;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
`;


const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  video, img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

// Custom modal styles
// Custom modal styles
const customStyles = {
    overlay: { 
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: { 
      color: '#fff', 
      backgroundColor: '#333', 
      top: '50%', 
      left: '50%', 
      right: 'auto', 
      bottom: 'auto', 
      marginRight: '-50%', 
      transform: 'translate(-50%, -50%)', 
      width: '70%', 
      height: '70%', 
      overflow: 'auto' 
    },
  };
  

// Main Component
const InteractiveTour = ({ image, markers }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [activeMarker, setActiveMarker] = useState({title: '', contentType: '', content: ''});
    
    function openModal() {
      setIsOpen(true);
    }
    
    function closeModal() {
      setIsOpen(false);
    }
  
    function handleMarkerClick(markerData) {
      setActiveMarker(markerData);
      openModal();
    }
  
    return (
      <>
        <GlobalStyle />
        <TourContainer image={image}>
          {markers.map((marker, index) => 
            <Marker 
              key={index}
              top={marker.position.top}
              left={marker.position.left}
              onClick={() => handleMarkerClick(marker)}
            >
              {index + 1}
            </Marker>
          )}
        </TourContainer>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Tour Modal"
        >
          <button onClick={closeModal}>Close</button>
          {/* <h2>{activeMarker.title}</h2> */}
          <ModalContent>
            {activeMarker.contentType === 'video' ? (
              <video src={activeMarker.content} controls autoPlay />
            ) : (
              <img src={activeMarker.content} alt={activeMarker.title} />
            )}
          </ModalContent>
        </Modal>
      </>
    );
  };

export default InteractiveTour;
