import React, { useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import QuizIcon from '@material-ui/icons/HelpOutline';
import WarningIcon from '@material-ui/icons/Warning';
import ReminderIcon from '@material-ui/icons/EventNote';
import { blue, pink } from '@material-ui/core/colors';

// Create a dark theme
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: blue,
    secondary: pink,
  },
});

// Step component
const Step = ({ step, onClick }) => (
  <Card onClick={onClick}>
    <CardContent>
      <Typography variant="h5">{step.title}</Typography>
      <Typography variant="body2">{step.caption}</Typography>
      {step.reminder && <ReminderIcon />}
      {step.warning && <WarningIcon />}
    </CardContent>
  </Card>
);

// Subtopic component
const Subtopic = ({ subtopic }) => (
  <Card>
    <CardContent>
      <Typography variant="h6">{subtopic.title}</Typography>
      <Typography variant="body2">{subtopic.caption}</Typography>
    </CardContent>
  </Card>
);

// Quiz component
const Quiz = ({ quiz }) => (
  <Card>
    <CardContent>
      <Typography variant="h6">{quiz.question}</Typography>
      {quiz.choices.map((choice, index) => (
        <Button key={index}>{choice}</Button>
      ))}
      <QuizIcon />
    </CardContent>
  </Card>
);

// Main component
const LearningPath = ({ learningPath }) => {
  const [selectedStep, setSelectedStep] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h4">{learningPath.title}</Typography>
      <Typography variant="body1">{learningPath.description}</Typography>
      {learningPath.steps.map((step, index) => (
        <div key={index}>
          <Step step={step} onClick={() => setSelectedStep(step)} />
          {selectedStep === step && (
            <div>
              {step.subtopics.map((subtopic, index) => (
                <Subtopic key={index} subtopic={subtopic} />
              ))}
              <Quiz quiz={step.quiz} />
            </div>
          )}
        </div>
      ))}
    </ThemeProvider>
  );
};

export default LearningPath;


