import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import { GiClick } from "react-icons/gi";
import { BsFillRecordCircleFill } from "react-icons/bs";
import "./style.css";

import firebase from "firebase/app";
import "firebase/auth";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    zIndex: 200000,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
  children, // This is most important prop. Anything that FullScreenDialog wraps will be a child
  title,
  buttonPosition,
  text,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const classes = useStyles();

  const handleOpen = (title) => {
    setIsOpen(true);
  
    // Create a new Firestore document with the title and current date
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;
    if (user) {
      // const docRef = db.collection("21ifm_users").doc(user.uid);
      // docRef.set({
      //   title: title,
      //   date: new Date(),
      // });
      const userId = firebase.auth().currentUser.uid;
      const email = firebase.auth().currentUser.email;
      
      const userRef = db.collection("21ifm_users").doc(userId);
      userRef.set(
        {
          uid: userId,
          email: email,
        },
        { merge: false }
      )
      .then(() => {
        console.log("User document created successfully!");
      })
      .catch((error) => {
        console.error("Error creating user document: ", error);
      });

      const collectionRef = db.collection("21ifm_users").doc(userId).collection("data");
      const newDocRef = collectionRef.doc(); // generate a new document ID
      newDocRef.set({
        title: title,
        date: new Date(),
      })
      .then(() => {
        console.log("New document added to data subcollection successfully!");
      })
      .catch((error) => {
        console.error("Error adding new document to data subcollection: ", error);
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // Check if the user is already authenticated
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    // Unsubscribe when the component is unmounted
    return unsubscribe;
  }, []);

  const handleSignInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then(() => {
      setIsAuthenticated(true);
    });
  };

  return (
    <Box>
      <div
        className="marker"
        style={{
          position: "absolute",
          left: `${buttonPosition.left}`,
          top: `${buttonPosition.top}`,
          borderRadius: "50%",
          // width: "40px",
          // height: "40px",
          animation: "blink 1.5s linear infinite",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: "30%",
          zIndex: 100,
          cursor: "pointer", // add this to show the element is clickable
        }}
        title="Click"
        onClick={() => handleOpen(title)}
      >
        {/* <BsFillRecordCircleFill style={{ fontSize: "28px", color: "red" }} /> */}
        <span
          class="text-element"
          style={{
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            position: "relative",
          }}
        >
          {text}
        </span>
      </div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          m={0}
          pt={6}
          sx={{ backgroundColor: "#1E1e1E", width: "100%", height: "100%" }}
        >
          {/* Render the children only if the user is authenticated */}
          {isAuthenticated ? children : (
            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Typography variant="h6" className={classes.title}>
              Welcome to free access. Log in to discover this content
            </Typography>
            <Button
                  onClick={handleSignInWithGoogle}
                  sx={{
                    backgroundColor: "mustard", // Mustard background color
                    '&:hover': {
                      backgroundColor: "darkgoldenrod" // Darker mustard color on hover (optional)
                    }
                  }}
                >
                  Sign in with Google
                </Button>
          </div>
          )}
        </Box>
      </Dialog>
    </Box>
  );
          }  