import React, { useEffect } from "react";
import mailgo, { MailgoConfig } from "mailgo";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
const mailgoConfig = {
  dark: true

};

function MailGoApp() {
  useEffect(() => {
    mailgo(mailgoConfig);
  }, []);

  return (
    <div className="App">
        
      {/* <span>
      <Button target="_blank" href="mailto:contactus@21ifm.com?subject=ciao" data-body="This email is for me with me also in cc and in bcc">Send me Free preview Apps</Button>
      </span> */}
<Typography variant="h6" component="div" >
<a
      style={{color: "white", display: "block", height: "20px", width: "auto", appearance: "button" }}
      href="#mailgo"
      data-address="contactus"
      data-domain="21ifm.com"
      data-subject="I heard about Free preview"
      data-body="Could you send me the links?"
      >Send message. One click</a
    >
</Typography>
 
    
    </div>
  );
}

export default MailGoApp;

